import { getItemStock, getUrlVars, isBrandPage, isCartPage, isCategoryPage, isSearchPage } from "../common/utils";
import { graphQLServices } from "../services/graphQLServices";

export const renderSoldOutItemsLoader = () => {
    $(`[data-no-products-notification-custom]`).remove();
    $(`li.product .card-img-container`).addClass(`card-loading-overlay`);
    $(`li.product .card-body`).addClass(`card-loading-overlay`);

}

export const renderSoldOutItems = () => {
    $(`li.product .card-img-container`).removeClass(`card-loading-overlay`);
    $(`li.product .card-body`).removeClass(`card-loading-overlay`);
    $(`[data-sort-by="product"]`).fadeIn();
    $(`[data-no-products-notification-custom]`).remove();

    window.PLPMetaData?.products?.map(item => {
        if (window.location.search?.toLowerCase()?.includes("soldouttags=true")) {
            if (item?.isOutOfStock)
                $(`[ data-test="card-${item.parentId}"]`).find(`.card-body`).append(`<span class="sold-out-tag">Out of Stock</span>`);
        } else {
            if (item?.isOutOfStock)
                $(`[ data-test="card-${item.parentId}"]`).closest(`li.product`).fadeOut();
        }
    })

    if (!window?.PLPMetaData?.products?.find(item => !item.isOutOfStock) && !window.location.search?.toLowerCase()?.includes("soldouttags=true")) {
        if (isSearchPage()) {
            $(`#search-results-heading`).html(`<h1 class="page-heading">
            0 results for 'ofs'</h1>`);
        }
        else {
            let noProductsHTML = `
            <p data-no-products-notification-custom data-no-products-notification="" role="alert" aria-live="assertive" tabindex="-1">
                There are no products available listed under this category.
            </p>
        `;
            $(`.page .page-content#product-listing-container`).prepend(noProductsHTML);

            $(`[data-sort-by="product"]`).fadeOut();
        }

    } else {
        $(`[data-no-products-notification-custom]`).remove();
    }

}

export const renderProductAvailability = () => {
    if (!isCategoryPage() && !isBrandPage() && !isSearchPage()) return;
    return new Promise((resolve, reject) => {
        window.productSKUS.forEach(sku => {
            let { stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup } = getItemStock(sku, selectedStoreId);
            let canCheckout = (stockCountInSelectedStore + stockCountInWarehouse) > 0;
            if($('#addToCartButton[data-sku="'+sku+'"]').hasClass('is-loading')){
                $('#addToCartButton[data-sku="'+sku+'"]').removeClass('is-loading');
                $('#addToCartButton[data-sku="'+sku+'"]').parents('article').find('.card-body .current-stock').remove();
                $('#addToCartButton[data-sku="'+sku+'"]').parents('article').find('.card-body').append(`
                    <p class="current-stock hide">Current Stock: ${stockCountInSelectedStore + stockCountInWarehouse}, ${stockCountInWarehouse}, ${stockCountInSelectedStore},${stockForPickup}</p>
                    <p class="current-stock">${stockCountInWarehouse == 0 ? `${stockForPickup > 0 ? `Collection Only`:`Out of Stock`}`:`In Stock`}</p>
                `);

                let thisProductId = $('#addToCartButton[data-sku="'+sku+'"]').attr('product-id');

                let isClickCollectOnlyProduct = PLPMetaData.products.find(product=>product.entityId == thisProductId && product.customFields.find(field=>field.name.toLowerCase().indexOf(strictPISlabelName) >=0 && field.value.toLowerCase() == strictPISlabelValue)) ? true: false;

                if(isClickCollectOnlyProduct == true){
                    $('#addToCartButton[data-sku="'+sku+'"]').parents('article').find('.card-body .card-title').append(`<p class="cnc__click-collect-only">Click & Collect Only</p>`);
                }

                $('#addToCartButton[data-sku="'+sku+'"]').attr('max', stockCountInSelectedStore + stockCountInWarehouse);
                if(canCheckout == false){
                    $('#addToCartButton[data-sku="'+sku+'"]').addClass('disabled');
                    $('#addToCartButton[data-sku="'+sku+'"]').html('Out of Stock');
                }else{
                    $('#addToCartButton[data-sku="'+sku+'"]').removeClass('disabled');
                    $('#addToCartButton[data-sku="'+sku+'"]').html('Add to Cart');

                }

                if(stockCountInSelectedStore + stockCountInWarehouse == 0){
                    if(stockCountInSelectedStore == 0){
                        $('#addToCartButton[data-sku="'+sku+'"]').parents('article').find('.form-increment').hide();
                        if(selectedStoreId && selectedStoreId.length > 0) $('#addToCartButton[data-sku="'+sku+'"]').html('No Stock at Selected Store');
                        else $('#addToCartButton[data-sku="'+sku+'"]').html('No Store Selected');
                    }
                    
                    if(stockCount == 0){
                        $('#addToCartButton[data-sku="'+sku+'"]').html('Out of Stock');
                    }
                }else{
                    $('#addToCartButton[data-sku="'+sku+'"]').parents('article').find('.form-increment').show();
                }

                $('.item-quantity a:contains("Out of Stock")').parent().find('.form-increment').hide();

            }
        });
        $('.productGrid li').filter(function(i, e){return $(e).find('#addToCartButton').length == 0}).each(function(i,e){
            $(e).find('.card-body .current-stock').remove()
            $(e).find('.card-body').append(`<p class="current-stock">Out of Stock</p>`);
        })
        resolve();
    });
}

export const renderProductAvailabilityFilters = async () => {
    return;
    if(category_id.length == 0) return;
    let categories = await graphQLServices.getProductCategory([parseInt(category_id)]);
    var thisFilter = graphQLServices.removeNodeAndEdges(categories.data.site.search.searchProducts.filters).filter(function (filter) {
        return filter.name == "Category";
    })[0]?.categories?.find(category => category.entityId == parentProductAvailabilityCatID);
    if(thisFilter == undefined) return;
    $('.custom-product-availability-filters').remove();

    let clickAndCollectFilter = thisFilter && thisFilter.subCategories.find(cat=>cat.name.toLowerCase().indexOf('click')>=0);
    let homeDeliveryFilter = thisFilter && thisFilter.subCategories.find(cat=>cat.name.toLowerCase().indexOf('in stock')>=0);

    $('#facetedSearch-navList .accordion.accordion--navList').append(`
    <div class="accordion-block custom-product-availability-filters">
        <div class="accordion-nav-clear-holder">
            <button type="button" class="accordion-navigation" data-collapsible="#facetedSearch-content--product-availability" aria-label="Product Availability" aria-controls="facetedSearch-content--product-availability" aria-expanded="true">
                <span class="accordion-title">Product Availability</span>
            </button>
        </div>

        <div id="facetedSearch-content--product-availability" class="accordion-content" aria-hidden="false">
            <ul id="facetedSearch-navList--bool" data-facet="bool" class="navList" data-has-more-results="">
                <li class="navList-item">
                    <a href="${thisFilter && homeDeliveryFilter ? `${window.location.search.length == 0 ? `${window.location.pathname}?category=${homeDeliveryFilter.entityId}`:`${window.location.search.indexOf(`category=${homeDeliveryFilter.entityId}`) >= 0 ? `${window.location.href.replace(`?category=${homeDeliveryFilter.entityId}`, '?').replace(`&category=${homeDeliveryFilter.entityId}`, '').replace('?&', '?')}`:`${window.location.href}&category=${homeDeliveryFilter.entityId}`}`}`: '#'}" class="navList-action navList-action--checkbox ${thisFilter && homeDeliveryFilter.entityId && window.location.search.indexOf(`category=${homeDeliveryFilter.entityId}`) >=0 ? `is-selected`:``}" data-faceted-search-facet="">
                        In Stock for Home Delivery <span>(${thisFilter ? homeDeliveryFilter.productCount || 0:'0'})</span>

                        <span class="navList-action-close" aria-hidden="true">
                            <svg class="icon">
                                <use xlink:href="#icon-close"></use>
                            </svg>
                        </span>
                    </a>
                </li>
                <li class="navList-item">
                    <a href="${thisFilter && clickAndCollectFilter ? `${window.location.search.length == 0 ? `${window.location.pathname}?category=${clickAndCollectFilter.entityId}`:`${window.location.search.indexOf(`category=${clickAndCollectFilter.entityId}`) >= 0 ? `${window.location.href.replace(`?category=${clickAndCollectFilter.entityId}`, '?').replace(`&category=${clickAndCollectFilter.entityId}`, '').replace('?&', '?')}`:`${window.location.href}&category=${clickAndCollectFilter.entityId}`}`}`: '#'}" class="navList-action navList-action--checkbox ${thisFilter && clickAndCollectFilter.entityId && (window.location.search.indexOf(`category=${clickAndCollectFilter.entityId}`) >=0) ? `is-selected`:``}" data-faceted-search-facet="">
                        Click and Collect <span>(${thisFilter && clickAndCollectFilter ? clickAndCollectFilter.productCount || 0:'0'})</span>

                        <span class="navList-action-close" aria-hidden="true">
                            <svg class="icon">
                                <use xlink:href="#icon-close"></use>
                            </svg>
                        </span>
                    </a>
                    <ul class="category-children" style="padding-left: 2rem;">
                        ${bopis.allStores.filter(store=>store.pickupAllowed).map(store=>{
                            let thisSubCategory = clickAndCollectFilter.subCategories.find(subcat=>subcat.name.toLowerCase().indexOf(store.storeName.toLowerCase()) >= 0);
                            return `
                                <li>
                                    <a href="${thisSubCategory ? `${window.location.search.length == 0 ? `${window.location.pathname}?category=${thisSubCategory.entityId}`:`${window.location.search.indexOf(`category=${thisSubCategory.entityId}`) >= 0 ? `${window.location.href.replace(`?category=${thisSubCategory.entityId}`, '?').replace(`&category=${thisSubCategory.entityId}`, '').replace('?&', '?')}`:`${window.location.href}&category=${thisSubCategory.entityId}`}`}`: '#'}" class="navList-action navList-action--checkbox ${thisSubCategory && thisSubCategory.entityId && window.location.search.indexOf(`category=${thisSubCategory.entityId}`) >=0 ? `is-selected`:``} ${thisSubCategory ? ``: `disabled`}" data-faceted-search-facet="">
                                    ${store.storeName} <span>(${ thisSubCategory ? thisSubCategory.productCount:`0`})</span>
        
                                        <span class="navList-action-close" aria-hidden="true">
                                            <svg class="icon">
                                                <use xlink:href="#icon-close"></use>
                                            </svg>
                                        </span>
                                    </a>
                                                    
                                </li>                   
                            `
                        }).join("")}
                    </ul>
                </li>
            </ul>

        </div>
    </div>
    `)
}
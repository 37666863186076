import { isCartPage, isCheckoutPage, isPDPPage } from "../common/utils";

export const graphQLServices = {
    getProductDataByIds: async function (productIds = null) {
        if(productIds == null){
            if (isPDPPage()) {
                productIds = $('[name="product_id"]').val();
            }
    
            if(isCartPage() || isCheckoutPage()){
                productIds = cartMetaData.cartItemsProdIDs;
            }
        }


        let postData = JSON.stringify({
            query: `
                query MultipleProduct {
                    site {
                        products (entityIds: [${productIds}]) {
                        edges {
                            node {
                            id
                            entityId
                            sku
                            name
                            customFields { 
                                edges { 
                                    node { 
                                        entityId 
                                        name 
                                        value 
                                    } 
                                } 
                            }
                            brand {
                                id
                                entityId
                                name 
                            }
                            variants(first: 100) {
                                edges {
                                node {
                                    sku
                                        productOptions {
                                            edges {
                                            node {
                                                entityId
                                                displayName
                                                isRequired
                                                ... on CheckboxOption {
                                                checkedByDefault
                                                }
                                                ... on MultipleChoiceOption {
                                                values(first: 10) {
                                                    edges {
                                                    node {
                                                        entityId
                                                        label
                                                        isDefault
                                                        ... on SwatchOptionValue {
                                                        hexColors
                                                        imageUrl(width: 200)
                                                        }
                                                    }
                                                    }
                                                }
                                                }
                                            }
                                            }
                                        }
                                            
                                }
                                }
                            }
                            options{
                                edges {
                                    node {
                                        entityId
                                        isRequired
                                        values {
                                            edges {
                                                node {
                                                    entityId
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            }
                        }
                        }
                    }
                }`,
        })

        return Promise.resolve(
            $.ajax({
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${api_token}`
              },
              url: `/graphql`,
              method: "POST",
              data: postData
            }).catch(function () { return false; }), 
            "json"
        );
    },
    getProductCategory: async function(categoryID){
        let postData = JSON.stringify({
            query :`
            query { site { search { searchProducts( filters: { categoryEntityIds: [${categoryID}] } sort: A_TO_Z ) { products { edges { node { entityId name prices { price { value } } } } } filters { edges { node { __typename name isCollapsedByDefault ... on CategorySearchFilter { name displayProductCount isCollapsedByDefault categories { edges { node { entityId name isSelected productCount subCategories { edges { node { entityId name productCount subCategories { edges { node { entityId name productCount } } } } } } } } } } } } } } } } }`
            ,
        })

        return Promise.resolve(
            $.ajax({
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${api_token}`
              },
              url: `/graphql`,
              method: "POST",
              data: postData
            }).catch(function () { return false; }), 
            "json"
        );
    },
    graphQLToJavascript: function (graphQLResult) {
        var root;
        if (graphQLResult.data.site.route) {
            root = graphQLResult.data.site.route.node
        } else if (graphQLResult.data.site.products) {
            root = graphQLResult.data.site.products;
        }
        var filteredResult = graphQLServices.removeNodeAndEdges(root, null);

        if (graphQLResult.data.site.products.pageInfo) {
            var final = {};
            final["products"] = filteredResult;
            final["pageInfo"] = graphQLResult.data.site.products.pageInfo;
            return final;
        }

        return filteredResult;
    },
    removeNodeAndEdges: function (root, parent) {
        Object.keys(root).forEach(function (key) {
            if (typeof root[key] == "object" && root[key]) {
                if (Array.isArray(root[key])) {
                    root = root[key];
                    root = root.map(function (entry) {
                        return entry.node;
                    });
                    root = graphQLServices.removeNodeAndEdges(root, null);
                } else {
                    root[key] = graphQLServices.removeNodeAndEdges(root[key], root);
                }
            }
        });
        return root;
    }
}
import { isCartPage } from "../common/utils"
import { renderDrawerStores } from "../globalPage/globalPageRenderer";
import { setAvailableStoresCheckout, setStore } from "../globalPage/globalpageData";
import { setCartMetaData } from "./cartData";
import { bindCartOnFormChange } from "./cartListeners";
import { renderCartItemAvailability } from "./cartRenderer";

export const initCart = {
    preCart: async function () {
        if (!isCartPage())
            return;

        // await setCartMetaData();
        renderCartItemAvailability();
        bindCartOnFormChange();
    },
    loadCart: async function () {
        if (!isCartPage())
            return;
        if (selectedStoreId) {
            await setStore(selectedStoreId, true, true);
        }

        await setAvailableStoresCheckout();
        renderCartItemAvailability();
        renderDrawerStores();
    }

}
import { CheckoutObserver } from "./observer";

export function isCheckoutPage() {
    if (location.pathname.includes("/checkout")) return true;
    else return false;
}

export function isPDPPage() {
    if (BCData.product_attributes) return true;
    else return false;
}

export function isCartPage() {
    if (location.pathname.includes("/cart.php")) {
        return true;
    } else {
        return false;
    }
}

export function isCategoryPage() {
    if (window.BC_page_type == "category") {
        return true;
    } else {
        return false
    }
}

export function isBrandPage() {
    if (window.BC_page_type == "brand") {
        return true;
    } else {
        return false
    }
}

export function isSearchPage() {
    if (window.BC_page_type == "search") {
        return true;
    } else {
        return false
    }
}

export function isOrderConfirmationPage() {
    if (location.pathname.includes("/checout/order-confirmation")) {
        return true;
    } else {
        return false;
    }

}

export function getItemAvailability(itemSKU, quantity, storeId = selectedStoreId, price = 0) {
    let availableForShipping, availableForPickup, availableInWarehouse;

    availableForShipping = bopis?.perSKU?.filter(stock => stock.sku == itemSKU).map(item => { return item.quantity }).reduce((partialSum, a) => partialSum + a, 0) >= quantity;

    availableInWarehouse = bopis?.perSKU?.filter(stock => stock.sku == itemSKU && (stock.storeId == storeWarehouseID || stock.storeId == subStoreWarehouseID))?.map(item => { return item.quantity }).reduce((partialSum, a) => partialSum + a, 0) >= quantity || false;

    if (isPDPPage() || isCheckoutPage() || isCartPage()) {
        availableForPickup = !!bopis?.perSKU?.find(perSKUStore =>
            (perSKUStore.storeId === storeId || !storeId) &&
            itemSKU === perSKUStore.sku &&
            perSKUStore.productAllowPickup &&
            bopis?.allStores?.find(s => s.locationId == storeId && s.pickupAllowed) &&
            quantity <= perSKUStore.quantity
        );

        if(storeId == subStoreWarehouseID && availableForPickup == false){
            availableForPickup = availableInWarehouse;
        }
    }

    return { availableForShipping, availableForPickup, availableInWarehouse };
}

export function getItemStock(itemSKU, storeId = '') {
    let stockCount = 0, stockCountInSelectedStore = 0, stockCountInWarehouse = 0, stockForPickup = 0;

    stockCount = bopis?.perSKU?.filter(stock => stock.sku == itemSKU && stock.locationAllowShipping).map(item => { return item.quantity }).reduce((partialSum, a) => partialSum + a, 0);
    
    stockCountInWarehouse = bopis?.perSKU?.filter(stock => stock.sku == itemSKU && (stock.storeId == storeWarehouseID || stock.storeId == subStoreWarehouseID)).map(item => { return item.quantityNoThreshold }).reduce((partialSum, a) => partialSum + a, 0);

    stockCountInWarehouse = stockCountInWarehouse <=0 ? 0:stockCountInWarehouse - productThreshold;
    
    stockForPickup = bopis?.perSKU?.filter(stock => stock.sku == itemSKU && stock.locationAllowPickup).length > 0 ? Math.max(...bopis?.perSKU?.filter(stock => stock.sku == itemSKU && stock.locationAllowPickup).map(item => { return item.quantity })) : 0;
    
    if(storeId && storeId.length > 0) {
        stockCountInSelectedStore = bopis?.perSKU?.filter(stock => stock.sku == itemSKU && stock.storeId == storeId)?.map(item => { return item.quantity })?.reduce((partialSum, a) => partialSum + a, 0) || 0;
        if(storeId == subStoreWarehouseID && stockCountInSelectedStore == 0){
            stockCountInSelectedStore = stockCountInWarehouse;
        }

    }

    return { stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup };
}

export function render(placeholderSelector, content) {
    $(placeholderSelector).html(content);
}

export function closeDrawer() {
    if ($(".cnc__drawer").hasClass("opened")) {
        $(".cnc__drawer").removeClass("opened");
        $(".cnc__drawer-underlay").fadeOut(250);
        $("body").css("overflow", "auto");
        $("body").removeClass("overflow-hidden");
    }
}

export function openDrawerOLD() {
    $(".cnc__drawer-underlay").fadeIn(250);
    $(".cnc__drawer").addClass("opened");
    $("body").addClass("overflow-hidden");
}

export function openDrawer(noSelected = false, $storeSelection = null) {
    if (noSelected == true) {
        renderDrawerStores(bopis?.allStores, true, $storeSelection);
    }
    $(".cnc__drawer-underlay").fadeIn(250);
    $(".cnc__drawer").addClass("opened");
    $("body").addClass("overflow-hidden");
}

export function transformObject(obj) {
    if (!obj || ["string", "boolean", "number"].includes(typeof obj)) {
        return obj;
    }

    if (Array.isArray(obj)) {
        return obj.map(transformObject);
    } else if (obj.hasOwnProperty('edges')) {
        return obj.edges.map((edge) => transformObject(edge.node));
    }

    return Object.keys(obj).reduce((result, key) => {
        result[key] = transformObject(obj[key]);
        return result;
    }, {});
}

export function setCookie(cookieName, value, expireyDays = 7) {
    localStorage.setItem(cookieName, value);
    // var d = new Date();
    // d.setTime(d.getTime() + (expireyDays * 24 * 60 * 60 * 1000));
    // var expires = "expires=" + d.toUTCString();
    // document.cookie = cookieName + "=" + value + ";" + expires + ";path=/";
}

export function getCookie(cookieName) {
    return localStorage.getItem(cookieName) || null;
}

export function deleteCookie(name) {
    if (getCookie(name)) {
        localStorage.removeItem(name);
    }
}

export function removeComma(str, searchstr = ',') {
    var index = str.indexOf(searchstr);
    if (index === -1) {
        return str;
    }
    return str.slice(0, index) + str.slice(index + searchstr.length);
}

export function getGroupedItems(item, props) {
    let returnArray = [];
    let i;
    for (i = 0; i < props.length; i++) {
        returnArray.push(item[props[i]]);
    }
    return returnArray;
}

export function groupThisArrayBy ({ Group: array, By: props }) {
    let groups = {};
    let i;

    for (i = 0; i < array.length; i++) {
        const arrayRecord = array[i];
        const group = JSON.stringify(getGroupedItems(arrayRecord, props));
        groups[group] = groups[group] || [];
        groups[group].push(arrayRecord);
    }
    return Object.keys(groups).map((group) => {
        return groups[group];
    });
};

export function observeSections(selector) {
    return new Promise(function (resolve) {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        let observer = new MutationObserver(function (mutations) {
            if (document.querySelector(selector)) {
                resolve(document.querySelector(selector));
                //observer.disconnect();

            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

export function redirectToCartPage() {
    window.location = window.location.origin + "/cart.php";
}

export const objectsEqual = (o1, o2) => {
    return typeof o1 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length
        && Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
        : o1 === o2;
}

export function getUrlVars()
{
    var vars = [], hash;
    var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return vars;
}

export function getDistance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km (convert this constant to get miles)
    var dLat = (lat2 - lat1) * Math.PI / 180;
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round(d);
}

export function showReplicaLoadingSkeleton(appendStyle=""){
    $('.checkout-step--shipping .checkout-form').addClass('hide');
    hideReplicaLoadingSkeleton();
    $(`<div class="checkout-form-method" id="replica-loading-skeleton" ${appendStyle}>
            <div class="address-form-skeleton">
                <div class="name-fields-skeleton">
                    <div class="skeleton-container">
                        <div class="input-skeleton"></div>
                    </div>
                    <div class="skeleton-container">
                        <div class="input-skeleton"></div>
                    </div>
                </div>
                <div class="skeleton-container">
                    <div class="input-skeleton"></div>
                </div>
            </div>
        </div>
    `).insertAfter($('.checkout-step--shipping .checkout-view-content .cnc__shipping-types'));
    if($('.checkout-step--shipping .checkout-view-content .cnc__shipping-types').length == 0){
        $('.checkout-step--shipping .checkout-view-content').prepend(`
            <div class="checkout-form-method" id="replica-loading-skeleton" ${appendStyle}>
                <div class="address-form-skeleton">
                    <div class="name-fields-skeleton">
                        <div class="skeleton-container">
                            <div class="input-skeleton"></div>
                        </div>
                        <div class="skeleton-container">
                            <div class="input-skeleton"></div>
                        </div>
                    </div>
                    <div class="skeleton-container">
                        <div class="input-skeleton"></div>
                    </div>
                </div>
            </div>
        `);
    }
}

export function hideReplicaLoadingSkeleton(){
    $('#replica-loading-skeleton').remove();
}

export function getStreetAddress(service, address){
    return new Promise(function(resolve){
        let streetNumberFromPredictions;
        if(address.length > 0){
            service.getPlacePredictions({ input: address }, function(predictions, status){
                console.log(predictions,"predictions");
                if(predictions.length){
                    streetNumberFromPredictions = predictions[0]?.terms[0]?.value || "";
                }
                resolve(streetNumberFromPredictions);
            });
        }else{
            resolve(streetNumberFromPredictions);
        }
        
    });
}

let checkoutObserver = new CheckoutObserver();

export function initAddress(inputElementID, location) {
    if(document.getElementById(inputElementID) == null) return;
    
    let getAddressSession = JSON.parse(localStorage.getItem(inputElementID));
    if(getAddressSession && document.getElementById(inputElementID).value.length == 0) {
        document.getElementById(inputElementID).value = (getAddressSession?.street_number == getAddressSession?.address1) ? `${getAddressSession?.address1}`:`${getAddressSession?.street_number} ${getAddressSession?.address1}`;
    }
    var addressNameFormat = {
      'street_number': 'long_name',
      'route': 'long_name',
      'postal_code': 'short_name',
      'locality': 'long_name',
      'postal_town': 'long_name',
      'sublocality': 'long_name',
      'administrative_area_level_1': 'short_name',
      'sublocality_level_1': 'short_name',
      'country': 'short_name'
    };
    var options = {
      types: ['address'],
      componentRestrictions: { country: ["ie", "gb"] }
    }

    var autocompleteInput = document.getElementById(inputElementID);
    var serviceAutoComplete = new google.maps.places.AutocompleteService();
    var autocomplete = new google.maps.places.Autocomplete(autocompleteInput, options);
    autocomplete.addListener('place_changed', async function () {

        let streetNumberFromPredictions = await getStreetAddress(serviceAutoComplete, autocompleteInput.value);
        console.log(streetNumberFromPredictions, "streetNumberFromPredictions");

        getAddressSession = JSON.parse(localStorage.getItem(inputElementID));
        var place = autocomplete.getPlace();  
        var getAddressComp = function (type) {
          for (var component of place.address_components) {
            if (component.types.indexOf(type) >= 0) {
              return component[addressNameFormat[type]];
            }
          }
          return '';
        };
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          window.alert('No details available for input: \'' + place.name + '\'');
          return;
        }

        console.log(place, "place");

        console.log(getAddressComp('street_number'), getAddressComp('street_number').length > 0 ? getAddressComp('street_number') : getAddressSession?.street_number || streetNumberFromPredictions || "", `getAddressComp('street_number')`);

        console.log(getAddressComp('route'), "getAddressComp('route')");

        var addressComponents =
        {
            street_number: getAddressComp('street_number') || streetNumberFromPredictions || getAddressSession?.street_number || "",
            address1: getAddressComp('route') || "",
            city: getAddressComp('locality') || getAddressComp('sublocality_level_1') || getAddressComp('sublocality') || getAddressComp('postal_town'),
            country: getAddressComp('country'),
            state: getAddressComp('administrative_area_level_1').replace('County ', ''),
            postalCode: getAddressComp('postal_code')
        };

        console.log(addressComponents, "addressComponents");

        localStorage.setItem(inputElementID, JSON.stringify(addressComponents));

        document.getElementById(inputElementID).value = (addressComponents?.street_number == addressComponents?.address1) ? `${addressComponents?.address1}`:`${addressComponents?.street_number} ${addressComponents?.address1}`;

        for(let key in addressComponents){
            let inputType = $(`#${inputElementID}`).parents('form').find(`[name*=${key}]`).attr('type') || null;
            let isSelectType = $(`#${inputElementID}`).parents('form').find(`[name*=${key}]`).is("select");

            if($(`#${inputElementID}`).parents('form').find(`[name*=${key}]`)[0] !== undefined){
                if(addressComponents[key] && inputType == 'text') checkoutObserver.changeValue($(`#${inputElementID}`).parents('form').find(`[name*=${key}]`)[0], addressComponents[key]);
                if(key == 'state' && isSelectType == true) checkoutObserver.changeSelectValue($(`#${inputElementID}`).parents('form').find(`[name*=${key}]`)[0], addressComponents[key]);
                if(key == 'country' && isSelectType == true) checkoutObserver.changeSelectValue($(`#${inputElementID}`).parents('form').find(`[name*=${key}]`)[0], addressComponents[key]);
            }
        }

        if(inputElementID == 'customAutoComplete-addressLine1Input' || inputElementID == 'customAutoComplete-addressLine1Input-forBilling'){
            let newAddress = (addressComponents?.street_number == addressComponents?.address1) ? `${addressComponents?.address1}`:`${addressComponents?.street_number} ${addressComponents?.address1}`;

            checkoutObserver.changeValue($('#addressLine1Input').get(0), newAddress);
        }
    });

    if(location == 'checkout'){ 
        let elementsAutoCompleteOff = setInterval(()=>{
            if($('.checkout-step.optimizedCheckout-checkoutStep.checkout-step--shipping .checkout-form input').filter(function () { return $(this).attr('autocomplete') !== "none"; }).length){
                $('.checkout-step.optimizedCheckout-checkoutStep.checkout-step--shipping .checkout-form input').attr('autocomplete', 'none');
            }else{
                clearInterval(elementsAutoCompleteOff);
            }
        }, 200)
    }
}
const { ICONS } = require("../common/icons");
const { getCookie, getItemAvailability, isPDPPage, removeComma, render, isCheckoutPage, isCartPage, getDistance, setCookie, getItemStock } = require("../common/utils");
const { setAvailableStoresCheckout, setStoreHours } = require("./globalpageData");
const { BIGCOMMERCE_PLACEHOLDERS, GENERATED_PLACEHOLDERS, googleMapAPIKey } = require("../common/globalConstants");

export const renderHeader = () => {
    let todayName = new Date().toLocaleString("en-US", {
        weekday: "long",
    });

    let storeName, storeHours;
    let headerContent;

    if (!bopis?.allStores?.length) {
        headerContent = `
        <div class="placeholder_content">
            <div class="placeholder_item"></div>
            <div class="placeholder_item"></div>
        </div>

        <div class="arrow-right-icon">
            ${ICONS.rippleLoader}
        </div>
        `;
    } else if (!selectedStore.storeName) {
        headerContent = `
        <div class="selector-content">
            <span class="word_set-store">${bopis?.Customizations.PickupButtontext}</span>
        </div>

        <div class="arrow-right-icon">
            ${ICONS.arrowRight}
        </div>
        `;
    } else {
        storeName = selectedStore.storeName;
        storeHours = "Closed";
        var todayHours = selectedStore.operatingHours ? selectedStore.operatingHours.filter(function (el) { return todayName == el.day }) : [];

        if (todayHours?.length > 0 && todayHours?.[0].operatingHours.length > 0) {
            todayHours?.[0]?.operatingHours?.sort((a, b) => new Date('1970-01-01T' + b.toTime) - new Date('1970-01-01T' + a.toTime));
            var toTime = new Date('1970-01-01T' + todayHours?.[0]?.operatingHours?.[0]?.toTime)?.toLocaleTimeString();
            storeHours = `Open Until: ${toTime?.split(toTime?.slice(-6, -2)).join('')}`;
        }

        headerContent = `
        <div class="selector-content">
            <span class="word_set-store">${storeName}</span>
            <span class="word_av-near-you">${storeHours}</span>
        </div>

        <div class="arrow-right-icon">
            ${ICONS.arrowRight}
        </div>
        `;
    }

    const HtmlToRender = `
    <div class="cnc__header-store-selector">
        <div class="pin-icon">
            ${ICONS.pinOutlinePink}
        </div>

        ${headerContent}
    </div>
    `;

    if (!$(GENERATED_PLACEHOLDERS.headerDesktop).length) {
        $(BIGCOMMERCE_PLACEHOLDERS.headerDesktop).append($(`<div id='${GENERATED_PLACEHOLDERS.headerDesktop.substring(1)}' class="navUser-item"></div>`));
    }

    if (!$(GENERATED_PLACEHOLDERS.headerMobile).length) {
        $(BIGCOMMERCE_PLACEHOLDERS.headerMobile).append($(`<div id='${GENERATED_PLACEHOLDERS.headerMobile.substring(1)}' ></div>`));
    }

    render(GENERATED_PLACEHOLDERS.headerDesktop, HtmlToRender);
    render(GENERATED_PLACEHOLDERS.headerMobile, HtmlToRender);
}

export const renderStoreDrawer = () => {
    let currentStock = bopis?.perSKU?.find(per => per.storeId == selectedStoreId && per.sku === PDPMetaData?.selectedProduct?.SKU)?.quantity;
    let currentStockHTML = `${(isPDPPage() && currentStock && selectedStore?.storeName?.length) ? `${currentStock} Available in ${selectedStore.storeName}` : ``}`;
    const HtmlToRender = `
    <div class="cnc__drawer-underlay"></div>
    <div class="cnc__drawer">
        <div class="cnc__store-search-bar">
            <div class="search-store-and-close-btn">
                <h5 class="store-header">Select A Store</h5>
              
                <button id="cnc__drawer-close-btn">
                    ${ICONS.close}
                </button>
            </div>

            <div class="cnc__search-by-store-info">
                <div class="cnc__use-location">
                    <a>
                        ${ICONS.pinOutlinePink}
                        Use my current location
                    </a>
                </div>

                <div class="cnc__search-input-wrapper">
                    <input placeholder="Enter zipcode or state" type="text" id="cnc__store-search-input-from-list"/>
                    ${ICONS.search}
                    <div class="cnc__close-icon-wrapper">
                        ${ICONS.close}
                    </div>
                </div>

            </div>

            <div class="cnc__search-by-location-group">
                <div class="search-title">
                    <h5>Find the nearest store for you</h5>
                    <span id="search-stores">Search from stores list</span>
                </div>

                <div class="cnc__search-input-wrapper">
                    <input placeholder="Enter zipcode or state" type="text" id="cnc__store-search-input-closest"/>
                    ${ICONS.search}
                    ${ICONS.rippleLoader}
                    <div class="cnc__close-icon-wrapper">
                        ${ICONS.close}
                    </div>
                </div>
                <button id="cnc__use-current-location">${ICONS.pin} Use my current location</button>
            </div>
        </div>

        <ul class="stores">
        </ul>
    </div>
    `;

    if (!$(".cnc__drawer").length) {
        $("body").append(HtmlToRender);
    } else {
        $(`#selected-store-name`).html(`${selectedStore?.storeName?.length ? selectedStore.storeName + " Store" : ``}`);
        $(`#store-stock-message`).html(currentStockHTML);
    }

    $(".cnc__search-input-wrapper .cnc__ripple-loader").hide();
    $(".cnc__search-input-wrapper .cnc__close-icon").hide();

    renderDrawerStores();
}
function getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
        var myLat = position.coords.latitude;
        var myLon = position.coords.longitude;
        $('body').attr('mylat', myLat);
        $('body').attr('mylon', myLon);
        setCookie('myLat', myLat, 7);
        setCookie('myLon', myLon, 7);


    },function (error) {});
}
function sortStore(stores){
    var myLat = getCookie("myLat");
    var myLon = getCookie("myLon");

    if(myLat == undefined || myLon == undefined){
        getCurrentLocation();
    }

    if(myLat && myLon && myLat.length > 0 && myLon.length > 0){
        var allDistances = [];
        var allIDs = [];
        var allIDNames = [];
        var nearestStoreID;
        var nearestStoreName;

        stores.forEach(function (el, index) {
            el.distance = getDistance(myLat, myLon, el.latitude, el.longitude);
            var D = parseInt(el.distance);
            if (allDistances.indexOf(D) < 0) {
                allDistances.push(D);
                allIDNames.push(el.storeName);
                allIDs.push(el.locationId);
            }
        });

        if (allDistances.length > 0) {
            let nearestDistance = allDistances[0];
            for (var i = 0; i < allDistances.length; ++i) {
                if (allDistances[i] < nearestDistance) {
                    nearestDistance = allDistances[i];
                }
            }
    
            for (var i = 0; i < allDistances.length; i++) {
                if (allDistances[i] == nearestDistance) {
                    nearestStoreID = allIDs[i];
                    nearestStoreName = allIDNames[i];
                }
            }
    
        }

        stores.sort(function (a, b) {
            return parseFloat(a.distance) - parseFloat(b.distance);
        });
    }

    return stores;

    
}

export const renderDrawerStores = async (stores = bopis?.allStores, noSelected = false, $storeSelection = null) => {
    let HtmlToRender = `<p class="search-result-message">No stores were found</p>`;
    if(!stores) return; 
    stores = sortStore(stores);

    const filteredStoresByPickup = stores?.filter(store => store?.pickupAllowed);
    const hasDistanceText = stores?.find(store => store.hasOwnProperty('distanceText'));

    if (filteredStoresByPickup?.length) {
        let myLat = getCookie("myLat");

        if (myLat?.length == 0 && !!!hasDistanceText) {
            filteredStoresByPickup?.sort(function (a, b) {
                var x = a.storeName.toLowerCase();
                var y = b.storeName.toLowerCase();
                return x < y ? -1 : x > y ? 1 : 0;
            });
        }

        HtmlToRender = filteredStoresByPickup.map((store, indexStore) => {
            let productAvailabilityHTML = "";

            let { availableForShipping, availableForPickup } = getItemAvailability(PDPMetaData.selectedProduct.SKU, PDPMetaData.selectedProduct.quantity, store.locationId, PDPMetaData.selectedProduct.price);
            let { stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup } = getItemStock(PDPMetaData.selectedProduct.SKU, store.locationId);
            let currentStock = stockCountInSelectedStore;

            if (
                (
                    (!PDPMetaData.productHasVariants && !PDPMetaData.areAllVariantsOptionsPicked) ||
                    (PDPMetaData.productHasVariants && PDPMetaData.areAllVariantsOptionsPicked)
                ) && bopis?.perSKU && isPDPPage()) {
                productAvailabilityHTML += (availableForPickup)
                    ? `<p class="cnc__pickup-availability cnc__color-available drawer">${isPDPPage() ? currentStock : ``} Available`
                    : `<p class="cnc__pickup-availability cnc__color-unavailable drawer">Not Available</p>`
            }

            if ((isCheckoutPage() || isCartPage()) && cartMetaData.cartProducts) {
                const availabilityPIS = filteredStoresByAvailability?.find(filterStore => filterStore?.locationId == store?.locationId);
                productAvailabilityHTML = `<p class="checkout-availability ${!availabilityPIS ? 'cnc__color-unavailable' : 'cnc__color-available'}">
                ${!availabilityPIS ? 'Not Available for Pickup' : 'Available for Pickup'}</p>`;
            }

            let mapIframeSrc = `
                    https://www.google.com/maps/embed/v1/place/?key=${googleMapAPIKey}
                    &q=${encodeURIComponent(store.storeName)}
                    +${store.street}
                    +${store.city}
                    +${store.state}
                    +${store.country}
                    &center=${store.latitude},${store.longitude}&zoom=18`
            var combinedStoreHours = setStoreHours(store.operatingHours);
            let operatingHoursHTML = `
                <div class="cnc__store-availability-hours">
                ${store.operatingHours && combinedStoreHours ?
                    `<h6>Store Hours:</h6>
                    <div class="store-days-hldr">
                    ${combinedStoreHours?.map(day => {
                        return `<div class="store-days">
                            <div>
                                <span>${day.day}:</span>
                            </div>
                            <div>
                                ${day?.operatingHours?.length > 0 ? day?.operatingHours?.map(hour => {
                            var fromTime = new Date('1970-01-01T' + hour.fromTime).toLocaleTimeString();
                            var toTime = new Date('1970-01-01T' + hour.toTime)?.toLocaleTimeString();

                            return `<p>${fromTime.split(fromTime.slice(-6, -2)).join('')} - ${toTime.split(toTime.slice(-6, -2)).join('')}</p>`
                        }).join("") :
                                `<p>Closed</p>`
                            }
                            </div>
                        </div>`
                    }).join("")}
                    </div>`
                    : ``}
                </div>`;

            let contactNumberHTML = `
                <div class="cnc__store-availability-hours">
                ${store.phone ?
                    `<h6>Contact:</h6>
                    <div class="store-days-hldr">
                        <a href="tel:${store.phone}">${store.phone}</a>
                    </div>
                    `
                    : ``}
                </div>`;

            let emailHTML = `
                <div class="cnc__store-availability-hours">
                ${store.emailAddress ?
                    `<h6>Email Address:</h6>
                    <div class="store-days-hldr">
                        <a href="mailto:${store.emailAddress}">${store.emailAddress}</a>
                    </div>
                    `
                    : ``}
                </div>`;

            return `
            <li class="store ${selectedStore?.locationId === store.locationId ? "selected-store" : ""}">
                ${productAvailabilityHTML}    
                <h5>${store.storeName} ${store.distanceText ? `<span class="store-distance">${store.distanceText}</span>` : !isNaN(store.distance) && myLat.length > 0 ? `<span class="store-distance ${store.distance > 50 ? 'outside-approx-store' : ''}">${store.distance + " mi"}</span>` : ""}</h5>

                <p class="store-address">
                    ${removeComma(store?.street)} <br/>
                    ${store.city}, ${store.state} ${store.zipCode}
                </p>

                <div class="cnc__store-actions">
                    ${selectedStore?.locationId === store.locationId ? `
                    <div class="my-current-store">${ICONS.pinOutlinePink} <span>My Current Store</span></div>
                    ` : `
                    <button class="cnc__set-store-btn" store-id="${store.locationId}">Set as My Store</button>
                    `}
                    <div class="cnc__view-more-store-info-hldr">
                        <a class="cnc__view-more-store-info">View Store Details</a>
                        ${ICONS.arrowDown}
                    </div>
                </div>

                <div class="cnc__store-more-info" style="display:none">
                    ${operatingHoursHTML}
                    ${contactNumberHTML}
                    ${emailHTML}
                    <div class="storeMap" storeid="" style="width: 100%;">
                        <iframe 
                            src="${mapIframeSrc}"  
                            width="100%"
                            height="200"
                            frameborder="0"
                            style="border:0"
                            referrerpolicy="no-referrer-when-downgrade" 
                            allowfullscreen="" loading="lazy" 
                            referrerpolicy="no-referrer-when-downgrade"
                            >
                        </iframe>
                    </div>
            </li>`
        }).join("");
    }

    // TODO remove the button later
    render(".cnc__drawer .stores", `<button id='deselect-store' style="display:none;">Deselect Store</button> ${HtmlToRender}`);
    render(".cnc__select-store-list-result-hldr ul.cnc__store-list", `<button id='deselect-store' style="display:none;">Deselect Store</button> ${HtmlToRender}`);
}

export function showCNC() {
    $(GENERATED_PLACEHOLDERS.headerDesktop).removeAttr('style');
    $(GENERATED_PLACEHOLDERS.headerMobile).removeAttr('style');
}
export const ICONS = {
    available: `
    <svg class="cnc__available-icon" version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 60.000000 60.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)"
        fill="#45a423" stroke="none">
            <path d="M247 589 c-90 -14 -179 -85 -219 -177 -27 -60 -27 -165 0 -225 68
            -154 251 -224 397 -153 105 52 167 150 167 266 0 185 -159 318 -345 289z m248
            -179 c19 -21 18 -22 -108 -148 l-127 -127 -70 70 c-38 38 -70 73 -70 78 0 4 9
            18 20 29 l20 22 53 -52 53 -52 99 100 c55 55 102 100 106 100 3 0 14 -9 24
            -20z"/>
        </g>
    </svg>
    `,

    unavailable: `
    <svg class="cnc__unavailable-icon" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle fill="#7e1616" cx="10" cy="10" r="10"/>
            <path d="M15 13.744c0-.333-.133-.652-.37-.886l-2.87-2.86 2.87-2.858a1.254 1.254 0 0 0-1.772-1.773L10 8.24 7.142 5.367A1.253 1.253 0 1 0 5.369 7.14L8.24 9.999l-2.87 2.859a1.249 1.249 0 0 0 0 1.773 1.248 1.248 0 0 0 1.772 0L10 11.759l2.858 2.872a1.248 1.248 0 0 0 1.773 0c.236-.235.369-.554.369-.887z" fill="#FFF" fill-rule="nonzero"/>
        </g>
    </svg>
    `,

    pin: `
    <svg class="cnc__location-icon" width="20" height="20" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5 c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021 C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333 s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"></path> </svg>
    `,

    pinOutlineWhite: `
    <svg class="cnc__location-icon" width="20" height="20" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5 c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021 C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333 s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"></path> </svg>
    `,

    pinOutlinePink: `
    <svg class="cnc__location-icon" width="14" height="19" xmlns="http://www.w3.org/2000/svg"> <path fill="#7f2854" d="M7 0C3.14 0 0 3.087 0 6.881c0 4.709 6.264 11.621 6.531 11.913.25.275.688.274.938 0C7.736 18.502 14 11.59 14 6.881 14 3.087 10.86 0 7 0zm0 10.343c-1.942 0-3.522-1.553-3.522-3.462C3.478 4.972 5.058 3.42 7 3.42c1.942 0 3.522 1.553 3.522 3.462 0 1.91-1.58 3.462-3.522 3.462z" fill="#223A45" fill-rule="nonzero"></path> </svg>
    `,

    pinOutlineBlack: `
    <svg width="17" height="23" viewBox="0 0 17 23" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <path id="52w4jj8y9a" d="M0 0h18v30H0z"/>
        </defs>
        <g transform="translate(-1 -2)" fill="none" fill-rule="evenodd">
            <mask id="7fac53r6sb" fill="#fff">
                <use xlink:href="#52w4jj8y9a"/>
            </mask>
            <path d="M9.5 3.835c-3.71 0-6.728 3.266-6.728 7.28-.009 4.736 5.432 11.217 6.727 12.692 1.293-1.48 6.729-7.984 6.729-12.693 0-4.013-3.018-7.279-6.728-7.279zM9.5 25l-.273-.296c-.295-.32-7.238-7.896-7.227-13.59C2 6.64 5.365 3 9.5 3S17 6.64 17 11.114c0 5.661-6.931 13.268-7.226 13.59L9.5 25z" stroke="#04143B" fill="#EC2639" mask="url(#7fac53r6sb)"/>
            <circle stroke="#04153C" cx="9.5" cy="10.5" r="3"/>
        </g>
    </svg>
    `,

    arrowRight: `
    <svg width="20" height="20" class="cnc__arrow-right-icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="451.846px" height="451.847px" viewBox="0 0 451.846 451.847" style="enable-background:new 0 0 451.846 451.847;" xml:space="preserve">
        <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744
            L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284
            c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z"></path>
    </svg>
    `,

    arrowDown: `
    <svg width="20" height="20" class="cnc__arrow-down-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
        <style type="text/css">
            .st0{display:none;}
            .st1{display:inline;fill:#333333;}
            .st2{fill:#333333;}
        </style>
        <g>
            <g class="st0">
                <rect class="st1" width="16" height="16"/>
            </g>
            <polygon class="st2" points="14.8,5 14,4.2 8,10.1 2,4.2 1.2,5 8,11.8  "/>
        </g>
    </svg>
    `,

    close: `
    <svg width="20" height="20" class="cnc__close-icon" viewBox="0 0 329.26933 329"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
    </svg>
    `,

    search: `
    <svg width="20" height="20" class="cnc__search-icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 511.999 511.999"
        style="enable-background:new 0 0 511.999 511.999;" xml:space="preserve">
        <path
            d="M508.874,478.708L360.142,329.976c28.21-34.827,45.191-79.103,45.191-127.309c0-111.75-90.917-202.667-202.667-202.667
                        S0,90.917,0,202.667s90.917,202.667,202.667,202.667c48.206,0,92.482-16.982,127.309-45.191l148.732,148.732
                        c4.167,4.165,10.919,4.165,15.086,0l15.081-15.082C513.04,489.627,513.04,482.873,508.874,478.708z M202.667,362.667
                        c-88.229,0-160-71.771-160-160s71.771-160,160-160s160,71.771,160,160S290.896,362.667,202.667,362.667z" />
    </svg>
    `,

    currentIndicator: `
    <svg class="cnc__location-icon" version="1.1" width="20" height="20" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve"> <path d="M256,0C161.896,0,85.333,76.563,85.333,170.667c0,28.25,7.063,56.26,20.49,81.104L246.667,506.5 c1.875,3.396,5.448,5.5,9.333,5.5s7.458-2.104,9.333-5.5l140.896-254.813c13.375-24.76,20.438-52.771,20.438-81.021 C426.667,76.563,350.104,0,256,0z M256,256c-47.052,0-85.333-38.281-85.333-85.333c0-47.052,38.281-85.333,85.333-85.333 s85.333,38.281,85.333,85.333C341.333,217.719,303.052,256,256,256z"></path> </svg>
    `,

    rippleLoader: `
        <div class="cnc__ripple-loader lds-ripple"><div></div><div></div></div>
    `,

    delivery: `
    <svg class="cnc__delivery-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
        <style type="text/css">
            .st0{display:none;}
            .st1{display:inline;fill:#333333;}
            .st2{fill:#333333;}
        </style>
        <g>
            <g class="st0">
                <rect class="st1" width="16" height="16"/>
            </g>
            <path class="st2" d="M10.5,2H0.4v10.2h1.8c0.3,1,1.2,1.7,2.3,1.7s2-0.7,2.3-1.7h1.8C9,13.3,9.9,14,11,14s2-0.7,2.3-1.7h2.2V7   L10.5,2z M11,12.8c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3S11.7,12.8,11,12.8z M4.6,10.3c0.7,0,1.3,0.6,1.3,1.3   s-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3S3.9,10.3,4.6,10.3z M1.6,3.2h8.5l4.3,4.3v3.6h-1.1C13.1,10,12.2,9.2,11,9.2S8.9,10,8.7,11.1   H6.9C6.7,10,5.7,9.2,4.6,9.2c-1.2,0-2.1,0.8-2.3,1.9H1.6V3.2z"/>
        </g>
    </svg>
    `,

    edit: `
    <svg class="cnc__edit-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 16 16" style="enable-background:new 0 0 16 16;" xml:space="preserve">
        <style type="text/css">
            .st0{display:none;}
            .st1{display:inline;fill:#333333;}
            .st2{fill:#333333;}
        </style>
        <g>
            <g class="st0">
                <rect class="st1" width="16" height="16"/>
            </g>
            <g>
                <g>
                    <path class="st2" d="M8.2,11l-3-3l7.2-7.2l3,3L8.2,11z M7,8l1.3,1.3l5.5-5.5l-1.3-1.3L7,8z"/>
                </g>
                <g>
                    <g>
                        <g>
                            <polygon class="st2" points="4.8,11.4 7.1,11.4 4.8,9.2      "/>
                        </g>
                    </g>
                </g>
                <g>
                    <polygon class="st2" points="9.2,2.4 8,3.6 2,3.6 2,14 12.4,14 12.4,8.5 13.6,7.3 13.6,15.2 0.8,15.2 0.8,2.4    "/>
                </g>
            </g>
        </g>
    </svg>
    `,

    completed: `
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path></svg>
    `,

    phone: `
    <svg class="phone_icon" width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.807 13.651c-.67-1.267-2.997-2.64-3.1-2.7a1.849 1.849 0 0 0-.902-.26c-.434 0-.79.198-1.005.56-.34.407-.762.883-.865.957-.793.538-1.413.477-2.1-.21L6.002 8.164c-.682-.682-.745-1.31-.21-2.1.074-.102.55-.525.957-.866a1.11 1.11 0 0 0 .516-.666c.104-.374.027-.815-.218-1.245C6.99 3.19 5.615.861 4.35.191a1.65 1.65 0 0 0-1.934.291l-.847.847C.23 2.669-.256 4.186.127 5.84.446 7.22 1.375 8.687 2.89 10.202l4.907 4.908C9.714 17.027 11.542 18 13.23 18c1.242 0 2.399-.528 3.44-1.568l.846-.847a1.638 1.638 0 0 0 .291-1.934z" fill="#03143B" fill-rule="nonzero"/>
    </svg>

    `,

    clock: `
    <svg class="clock_icon" width="22" height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
        <g fill="#03143B" fill-rule="nonzero">
            <path d="M18.444 11.526a.526.526 0 0 1 0-1.052H19a7.985 7.985 0 0 0-1.995-4.78l-.383.382a.525.525 0 0 1-.744 0 .526.526 0 0 1 0-.744l.38-.38A7.985 7.985 0 0 0 11.526 3v.556a.526.526 0 0 1-1.052 0V3a7.984 7.984 0 0 0-4.746 1.965l.413.413a.526.526 0 0 1-.744.744l-.415-.414A7.984 7.984 0 0 0 3 10.474h.621a.526.526 0 0 1 0 1.052H3a7.984 7.984 0 0 0 1.952 4.732l.445-.445a.526.526 0 0 1 .744.745l-.447.447A7.985 7.985 0 0 0 10.474 19v-.621a.526.526 0 0 1 1.052 0V19a7.984 7.984 0 0 0 4.766-1.982l-.414-.415a.526.526 0 0 1 .744-.744l.413.413A7.985 7.985 0 0 0 19 11.526h-.556zm-3.906 0H11a.526.526 0 0 1-.526-.526V5.908a.526.526 0 0 1 1.052 0v4.566h3.012a.526.526 0 0 1 0 1.052z"/>
            <path d="M11 0C4.935 0 0 4.935 0 11s4.935 11 11 11 11-4.935 11-11S17.065 0 11 0zm0 20.183c-5.063 0-9.183-4.12-9.183-9.183S5.937 1.817 11 1.817s9.183 4.12 9.183 9.183-4.12 9.183-9.183 9.183z"/>
        </g>
    </svg>
    `,
    email: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" style="enable-background:new 0 0 128 128" xml:space="preserve" width="22" height="22">
    <g fill="#03143B" fill-rule="nonzero">
    <path d="M48.3 64c0 8.7 7 15.7 15.7 15.7 1 0 1.8-.8 1.8-1.8s-.8-1.7-1.8-1.7c-6.7 0-12.2-5.5-12.2-12.2 0-3.3 1.3-6.5 3.7-8.8 2.4-2.3 5.6-3.5 8.9-3.4 6.6.4 11.8 5.9 11.7 12.5v5.1c0 1-.9 1.8-1.8 1.8-1 0-1.7-.8-1.8-1.8V64c0-4.8-3.8-8.6-8.6-8.6s-8.6 3.8-8.6 8.6 3.8 8.6 8.6 8.6c1.9 0 3.8-.6 5.3-1.8.7 2.8 3.6 4.5 6.5 3.8 2.3-.6 4-2.7 4-5.1v-5.1c0-8.5-6.6-15.6-15.1-16-8.7-.3-15.9 6.4-16.2 15.1-.1.1-.1.3-.1.5zM64 69.1c-2.8 0-5.1-2.3-5.1-5.1s2.3-5.1 5.1-5.1 5.1 2.3 5.1 5.1-2.3 5.1-5.1 5.1z"/><path d="M126.3 19.5H1.8c-1 0-1.8.8-1.8 1.8v85.5c0 1 .8 1.7 1.8 1.8h124.5c1 0 1.7-.8 1.8-1.8V21.3c-.1-1-.9-1.8-1.8-1.8zM85 64c0 11.6-9.4 21.1-21 21-11.6 0-21-9.4-21-21s9.4-21 21-21 21 9.4 21 21zm-46.3.8.8.4c.7 13.5 12.2 23.9 25.7 23.3 12.6-.6 22.6-10.7 23.3-23.3l.8-.4 33.2 40.2H5.5l33.2-40.2zM3.5 101.9V47.8l31.9 15.4-31.9 38.7zm89.1-38.7 31.9-15.4v54.1L92.6 63.2zM3.5 23h121v20.9L88.4 61.3c-1.5-13.5-13.5-23.2-27-21.7C50 40.8 40.9 49.9 39.7 61.3L3.5 43.9V23z"/>
    </g>
    </svg>`,

    sddAvailable: `
    <svg class="available-sdd" version="1.0" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 60.000000 60.000000" preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,60.000000) scale(0.100000,-0.100000)" fill="#5e8e3e" stroke="none">
            <path d="M247 589 c-90 -14 -179 -85 -219 -177 -27 -60 -27 -165 0 -225 68
                -154 251 -224 397 -153 105 52 167 150 167 266 0 185 -159 318 -345 289z m248
                -179 c19 -21 18 -22 -108 -148 l-127 -127 -70 70 c-38 38 -70 73 -70 78 0 4 9
                18 20 29 l20 22 53 -52 53 -52 99 100 c55 55 102 100 106 100 3 0 14 -9 24
                -20z"></path>
        </g>
    </svg>
    `,

    sddUnavailable: `
    <svg class="unavailable-sdd" width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fill-rule="evenodd">
            <circle fill="#E94235" cx="10" cy="10" r="10"></circle>
            <path d="M15 13.744c0-.333-.133-.652-.37-.886l-2.87-2.86 2.87-2.858a1.254 1.254 0 0 0-1.772-1.773L10 8.24 7.142 5.367A1.253 1.253 0 1 0 5.369 7.14L8.24 9.999l-2.87 2.859a1.249 1.249 0 0 0 0 1.773 1.248 1.248 0 0 0 1.772 0L10 11.759l2.858 2.872a1.248 1.248 0 0 0 1.773 0c.236-.235.369-.554.369-.887z" fill="#FFF" fill-rule="nonzero"></path>
        </g>
    </svg>
    `,

    uber: `
    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="48px" height="13px" viewBox="0 0 926.906 321.777" enable-background="new 0 0 926.906 321.777"
        xml:space="preserve">
        <g>
            <path fill="#010202" d="M53.328,229.809c3.917,10.395,9.34,19.283,16.27,26.664c6.93,7.382,15.14,13.031,24.63,16.948
                c9.491,3.917,19.81,5.875,30.958,5.875c10.847,0,21.015-2.034,30.506-6.102s17.776-9.792,24.856-17.173
                c7.08-7.382,12.579-16.194,16.496-26.438c3.917-10.244,5.875-21.692,5.875-34.347V0h47.453v316.354h-47.001v-29.376
                c-10.545,11.147-22.974,19.734-37.285,25.761c-14.312,6.025-29.752,9.038-46.323,9.038c-16.873,0-32.615-2.938-47.228-8.813
                c-14.612-5.875-27.267-14.235-37.962-25.082S15.441,264.006,9.265,248.79C3.088,233.575,0,216.628,0,197.947V0h47.453v195.236
                C47.453,207.891,49.411,219.414,53.328,229.809z"/>
            <path fill="#010202" d="M332.168,0v115.243c10.545-10.545,22.748-18.905,36.607-25.082s28.924-9.265,45.193-9.265
                c16.873,0,32.689,3.163,47.453,9.49c14.763,6.327,27.567,14.914,38.414,25.761s19.434,23.651,25.761,38.414
                c6.327,14.764,9.49,30.431,9.49,47.002c0,16.57-3.163,32.162-9.49,46.774c-6.327,14.613-14.914,27.343-25.761,38.188
                c-10.847,10.847-23.651,19.434-38.414,25.761c-14.764,6.327-30.581,9.49-47.453,9.49c-16.27,0-31.409-3.088-45.419-9.265
                c-14.01-6.176-26.288-14.537-36.833-25.082v28.924h-45.193V0H332.168z M337.365,232.746c4.067,9.642,9.717,18.078,16.948,25.309
                c7.231,7.231,15.667,12.956,25.308,17.174c9.642,4.218,20.036,6.327,31.184,6.327c10.847,0,21.09-2.109,30.731-6.327
                s18.001-9.942,25.083-17.174c7.08-7.23,12.729-15.667,16.947-25.309c4.218-9.641,6.327-20.035,6.327-31.183
                c0-11.148-2.109-21.618-6.327-31.41s-9.867-18.303-16.947-25.534c-7.081-7.23-15.441-12.88-25.083-16.947
                s-19.885-6.102-30.731-6.102c-10.846,0-21.09,2.034-30.731,6.102s-18.077,9.717-25.309,16.947
                c-7.23,7.231-12.955,15.742-17.173,25.534c-4.218,9.792-6.327,20.262-6.327,31.41C331.264,212.711,333.298,223.105,337.365,232.746
                z"/>
            <path fill="#010202" d="M560.842,155.014c6.025-14.462,14.312-27.191,24.856-38.188s23.049-19.659,37.511-25.986
                s30.129-9.49,47.001-9.49c16.571,0,31.937,3.013,46.098,9.038c14.16,6.026,26.362,14.387,36.606,25.083
                c10.244,10.695,18.229,23.35,23.952,37.962c5.725,14.613,8.587,30.506,8.587,47.68v14.914H597.901
                c1.507,9.34,4.52,18.002,9.039,25.985c4.52,7.984,10.168,14.914,16.947,20.789c6.779,5.876,14.462,10.471,23.049,13.784
                c8.587,3.314,17.7,4.972,27.342,4.972c27.418,0,49.563-11.299,66.435-33.896l32.991,24.404
                c-11.449,15.366-25.609,27.418-42.481,36.155c-16.873,8.737-35.854,13.106-56.944,13.106c-17.174,0-33.217-3.014-48.131-9.039
                s-27.869-14.462-38.866-25.309s-19.659-23.576-25.986-38.188s-9.491-30.506-9.491-47.679
                C551.803,184.842,554.817,169.476,560.842,155.014z M624.339,137.162c-12.805,10.696-21.316,24.932-25.534,42.708h140.552
                c-3.917-17.776-12.278-32.012-25.083-42.708c-12.805-10.695-27.794-16.043-44.967-16.043
                C652.133,121.119,637.144,126.467,624.339,137.162z"/>
            <path fill="#010202" d="M870.866,142.359c-9.641,10.545-14.462,24.856-14.462,42.934v131.062h-45.646V85.868h45.193v28.472
                c5.725-9.34,13.182-16.722,22.371-22.145c9.189-5.424,20.111-8.136,32.766-8.136h15.817v42.482h-18.981
                C892.86,126.542,880.507,131.814,870.866,142.359z"/>
        </g>
    </svg>`,

    warning: `<svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.0003 1.66663C5.41699 1.66663 1.66699 5.41663 1.66699 9.99996C1.66699 14.5833 5.41699 18.3333 10.0003 18.3333C14.5837 18.3333 18.3337 14.5833 18.3337 9.99996C18.3337 5.41663 14.5837 1.66663 10.0003 1.66663ZM10.0003 17.0833C6.08366 17.0833 2.91699 13.9166 2.91699 9.99996C2.91699 6.08329 6.08366 2.91663 10.0003 2.91663C13.917 2.91663 17.0837 6.08329 17.0837 9.99996C17.0837 13.9166 13.917 17.0833 10.0003 17.0833Z" fill="#bf1111"></path>
    <path d="M9.91634 6.74992C10.4997 6.74992 10.7497 6.49992 10.7497 5.91659C10.7497 5.33325 10.4163 5.08325 9.91634 5.08325C9.33301 5.08325 9.08301 5.41659 9.08301 5.91659C9.08301 6.41659 9.33301 6.74992 9.91634 6.74992Z" fill="#bf1111"></path>
    <path d="M11.5003 14.25L10.917 14.1666C10.7503 14.1666 10.667 14.0833 10.667 13.9166V9.16663C10.667 8.24996 10.3337 7.91663 9.50033 7.91663C8.83366 7.91663 8.41699 8.16663 8.41699 8.66663C8.41699 8.91663 8.41699 9.08329 8.50033 9.16663V9.24996H9.00033C9.25033 9.16663 9.33366 9.16663 9.33366 9.49996V14.1666C9.33366 14.9166 9.66699 15.25 10.417 15.25C11.167 15.25 11.5003 15 11.5003 14.5833C11.5837 14.5 11.5003 14.3333 11.5003 14.25Z" fill="#bf1111"></path>
    </svg>
    `


};
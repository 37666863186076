import { CheckoutObserver } from "./observer";
import { Popup } from "./popUp";
import { getCookie } from "./utils";

const { staticCustomizations, isLiveSite } = require("./globalConstants");

export const initWindowObj = () => {

    window.bopis = { allStores: [], Customizations: staticCustomizations, SDD: [] };

    window.filteredStoresByAvailability = [];

    window.selectedStoreId = getCookie("storeSelectedIDV3") || null;

    window.isInitialBOPISLoad = true;

    window.checkoutUnavailabilityPopup = new Popup();

    window.checkoutObserver = new CheckoutObserver();

    window.selectedStore = {
        city: null,
        country: null,
        emailAddress: null,
        embededMapUrl: null,
        installationId: null,
        isDefault: null,
        isEnabled: null,
        latitude: null,
        locationId: null,
        longitude: null,
        pickupAllowed: null,
        priority: null,
        shippingAllowed: null,
        state: null,
        storeExternalId: null,
        storeName: null,
        storeType: null,
        street: null,
        timezone: null,
        zipCode: null
    };

    window.productIds = [];

    window.PLPMetaData = {
        products: [
        ]
    }

    window.PDPMetaData = {
        productHasVariants: false,
        selectedProduct: {
            SKU: "",
            quantity: 1
        },
        areRequiredVariantsSelected: false,
        productVariants: [],
    }

    window.cartMetaData = {
        cartProducts: [],
        cart: null,
        shippingCountries: [],
    };

    window.checkoutMetaData = { products: window.checkoutItems || [] };

    window.pickupStoreFieldID = isLiveSite ? 'field_34' : "field_34";
    window.allowSplitShippingFieldID = isLiveSite ? 'field_36':'field_28';
    window.isDatePickerEnabled = false;
    window.pickupTimeslotFieldID = isLiveSite ? "field_34" : 'field_34';
    window.isDeliveryMethodEnabled = true;
    window.isPISMethodEnabled = true;
    window.isSDDMethodEnabled = false;
    window.SDDRadius = 15;
    window.selectedPickupStore = null;
    window.splitShippingMethod = false;
    window.SelectedSplitShippingMethods = JSON.parse(localStorage.getItem('SelectedSplitShippingMethods')) || [];
    window.physicalItems = JSON.parse(localStorage.getItem('physicalItems')) || [];
    window.physicalItemsIDS = JSON.parse(localStorage.getItem('physicalItemsIDS')) || [];
    window.storeWarehouseID = isLiveSite ? '7969d9ae-d01d-45bb-933a-54941add6cb6':'af6258d6-cc5b-408b-9f67-335d0d46d210'; // "Leeds Warehouse"
    window.subStoreWarehouseID = isLiveSite ? '4fb15a15-6d15-4121-8167-017b07306103':'b4db0342-d995-41b7-9580-436ce61b8904'; // LEEDS
    window.parentProductAvailabilityCatID = 2010;
    window.BC_page_type = page_type;
    window.productSKUS = [];
    window.productIDS = [];
    window.productThreshold = 3;
    window.strictPISlabelName = 'product availability';
    window.strictPISlabelValue = 'click and collect only';
    window.RRStock = null;
}
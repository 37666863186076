import { renderHeader, renderStoreDrawer } from "../globalPage/globalPageRenderer";
import { handlePDPAddToCart } from "./productDetailsPageListeners";

const { isPDPPage } = require("../common/utils");
const { getBOPISData } = require("../services/randemRetailServices");
const { initPDP } = require("./productDetailsPage");
const { renderPDPClickAndCollect } = require("./productDetailsPageRenderer");

export function setPDPMetaData() {
    if (!isPDPPage()) return;

    PDPMetaData.selectedProduct.price = BCData.product_attributes.price?.with_tax?.value;
    PDPMetaData.selectedProduct.SKU = $("[data-product-sku]").text();
    PDPMetaData.selectedProduct.quantity = $(".form-input--incrementTotal").val() || $(".select-item-quantity").val() || 1

    // If product has variants
    if ($(`[data-product-option-change] .form-field:not([data-product-attribute="swatch"])`).length) {
        PDPMetaData.productHasVariants = true;

        // Check if all required variants are selected
        const $requiredFields = $("[data-product-option-change] [required]");

        if ($requiredFields.length) {
            $requiredFields.each((index, item) => {
                if (!$(item).val()) {
                    PDPMetaData.areRequiredVariantsSelected = false;
                    return;
                } else if (index === $requiredFields.length - 1) {
                    PDPMetaData.areRequiredVariantsSelected = true;
                }
            })
        }

        PDPMetaData.areAllVariantsOptionsPicked = areAllVariantsOptionsPicked();
    }
}

export function areAllVariantsOptionsPicked() {
    if (!isPDPPage()) return;

    var $formFields = $("form[data-cart-item-add] .form-field");
    var areAllVariantsOptionsPicked = true;

    $formFields.each(function (index, item) {
        var formItem;
        if ($(item).attr("data-product-attribute") === "set-radio"
            || $(item).attr("data-product-attribute") === "set-rectangle"
            || $(item).attr("data-product-attribute") === "swatch") {
            formItem = $(item).find(".form-radio:checked");
            if (!formItem.length) {
                areAllVariantsOptionsPicked = false;
            }
        } else if ($(item).attr("data-product-attribute") === "set-select") {
            formItem = $(item).find("select");
            if (!$(formItem).val()) {
                if (formItem) areAllVariantsOptionsPicked = false;
            }
        }
    });

    return areAllVariantsOptionsPicked;
}

export const PDPOnSetStore = () => {
    if (!isPDPPage()) return;

    if (!isInitialBOPISLoad) {
        checkPDPVariantAvailabilityHandler();
    }
}

function checkPDPVariantAvailabilityHandler() {
    if (!isPDPPage()) return;

    renderPDPClickAndCollect();
    setPDPMetaData();
}
import { getCookie, observeSections, openDrawer, setCookie } from "../common/utils";
import { getBOPISData } from "../services/randemRetailServices";
import { setCartMetaData } from "./cartData";
import { renderCartItemAvailability, renderStoreList, renderCheckoutAddress, renderShippingCostForPIS } from "./cartRenderer";
import { renderDrawerStores } from "../globalPage/globalPageRenderer";
import { setAvailableStoresCheckout } from "../globalPage/globalpageData";
import { Popup } from "../common/popUp";
import { storefrontServices } from "../services/bigCommerceServices";
import { ICONS } from "../common/icons";

export function bindCartOnFormChange() {
    //TODO: also update theme files: assets/js/theme/cart.js
    var qtyChange;
    $('body').on('click', '.cart-remove.icon, button[data-cart-update]', function () {
        clearTimeout(qtyChange);
        qtyChange = setTimeout(async ()=>{
            bopis = null;
            
            getBOPISData().then(async () => {
                await setAvailableStoresCheckout();
                renderDrawerStores();
                renderCartItemAvailability();
            })
        }, 3000)
    });

    $('body').on('change', '.cnc__cart-page-checkout-selection', async function () {
        $(this).parents('.cnc__checkout-method').find('.shipping-option').removeClass('selected');
        $(this).parents('.shipping-option').addClass('selected');

        let thisSelected = $(this).val();
        $('.cnc__cart-availability').remove();
        $('.cart-actions').prepend(`
            <div class="cs-address-loader-hldr"> <div class="cs-address-loader"></div> </div>
        `);
        $(this).parents('.cnc__checkout-method').find('.shipping-option').addClass('disabled');

        let checkoutInfo = cartMetaData.checkout;
        let myStore = null;
        $('li.cart-total:nth-child(3)').show();
        $('li.cart-total:nth-child(3) .pis-cost').hide();
        $('li.cart-total:nth-child(3)').find(`[data-collapsible="add-shipping"],.subtotal.shipping-estimate-show`).show();
        $('.cart-total-grandTotal span.remove-shippingCost').remove();

        $('.cart-actions .cs-address-loader-hldr').remove();
        $(this).parents('.cnc__checkout-method').find('.shipping-option').removeClass('disabled');
        setCookie("checkoutMethod", thisSelected, 7);
        if(thisSelected == 'pickup'){
            renderShippingCostForPIS(checkoutInfo);
            myStore = selectedStoreId ? bopis.allStores.find(store=> store.locationId == selectedStoreId) :null;
            $('.cart-actions').prepend(`
                <div class="cnc__cart-availability ${cartMetaData.isNotAvailableForPIS == true ? `not-available`:`${!myStore?`not-available`:`available`}`}">
                    <div class="icon-availability ${cartMetaData.isNotAvailableForPIS == true ? `not-available`:`${!myStore?`not-available`:`available`}`}">
                        ${!myStore || cartMetaData.isNotAvailableForPIS == true ? 
                            ICONS.warning
                            :ICONS.available
                        }
                        ${cartMetaData.isNotAvailableForPIS == true ? `<p>Not Available Items</p>`:`${!myStore?`<p>No Store Selected</p>`:``}`}
                    </div> 
                    <p class="${!myStore ? `no-store-selected`:``}">
                    ${!myStore || cartMetaData.isNotAvailableForPIS == true ?
                        !myStore ? `Select Store`: cartMetaData.isNotAvailableForPIS == true ? `Some items are Not Available for Pickup in <b>${myStore.storeName}</b>. <a class="change-selected-store">Change Store</a>`:``
                    :`Collecting from <b>${myStore.storeName}</b> <a class="change-selected-store">Change Store</a>`}
                    
                    </p>
                </div>
            `);

        }else{
            $('.cart-total-grandTotal span').show();
            $('.cart-total-grandTotal span').html(`${cartMetaData.cart.currency.symbol}${checkoutInfo.grandTotal}`);

            $('.cart-actions').prepend(`
                <div class="cnc__cart-availability ${cartMetaData.isNotAvailableForDelivery == true ? `not-available`:`available-sfs`}">
                    <div class="icon-availability ${cartMetaData.isNotAvailableForDelivery == true ? `not-available`:``}">
                        ${cartMetaData.isNotAvailableForDelivery == true ? 
                            ICONS.warning
                            :ICONS.available
                        }
                        ${cartMetaData.isNotAvailableForDelivery == true ? `<p>Not Available Items</p>`:``}
                    </div> 
                    <p>${cartMetaData.isNotAvailableForDelivery == true ? 
                        `Please remove the items that are <b>Not Available for Home Delivery</b> before you check out`
                        :`Available for Home Delivery`
                    }</p>
                </div>
            `);
        }

        let preferredCheckoutMethod = getCookie("checkoutMethod");
        $('.cart-actions .button--primary').removeClass('disabled');
        if(preferredCheckoutMethod == 'pickup'){
            $('.cart-actions .button--primary').removeClass('disabled');
            if(!myStore || cartMetaData.isNotAvailableForPIS == true){
                $('.cart-actions .button--primary').addClass('disabled');
            }
        }else{
            $('.cart-actions .button--primary').removeClass('disabled');
            if(cartMetaData.isNotAvailableForDelivery == true){
                $('.cart-actions .button--primary').addClass('disabled');
            }
        }
    });

    $('body').on('change', '.cnc__cart-page-checkout-selection', async function () {
        if(splitShippingMethod == false) return;
        let thisitemid = $(this).parents('.cnc__cartpage-smethod').attr('data-itemid');
        let thisSelected = $(this).val();
        SelectedSplitShippingMethods = JSON.parse(localStorage.getItem('SelectedSplitShippingMethods')) || [];
        let thisSelectedMethodInCart = SelectedSplitShippingMethods.find((item) => item?.lineItem === thisitemid);
        if (thisSelectedMethodInCart == undefined) {
            thisSelectedMethodInCart = { lineItem: thisitemid, method: thisSelected };
        }
        thisSelectedMethodInCart.method = thisSelected;
        if(thisSelected == 'shipping'){
            thisSelectedMethodInCart.storeid = null;
        }else{
            thisSelectedMethodInCart.deliveryAddress = null;

        }
        SelectedSplitShippingMethods = SelectedSplitShippingMethods.filter((item) => item?.lineItem !== thisitemid);
        localStorage.setItem('SelectedSplitShippingMethods', JSON.stringify(SelectedSplitShippingMethods.concat([thisSelectedMethodInCart])));

        $(this).parents('.cnc__cartpage-smethod').find('.shipping-option').removeClass('selected');
        $(this).parents('.shipping-option').addClass('selected');
        
        let isExistingConsignment = cartMetaData?.checkout?.consignments?.find(con => con.lineItemIds.indexOf(thisitemid) >= 0);
        if (isExistingConsignment) cartMetaData.checkout = await storefrontServices.deleteConsignment(cartMetaData.checkout.id, isExistingConsignment.id);

        bopis = null;
        await setCartMetaData();

        getBOPISData().then(() => {
            renderCartItemAvailability();
        })
    });

    let changeStorePopUp = new Popup();
    $('body').on('click', '.set-address-cart-page', async function () {
        $(this).parents('.cnc__cartpage-smethod').find('.shipping-option').removeClass('selected');
        $(this).parents('.shipping-option').addClass('selected');

        $(this).parents('.cnc__cartpage-smethod').find('input[type=radio]').removeAttr('checked');
        $(this).parents('.shipping-option').find('input[type=radio]').attr('checked', 'checked');

        let thisLineID = $(this).parents('.cnc__cartpage-smethod').attr('data-line-id');
        let thisLineQTY = $(this).parents('.cnc__cartpage-smethod').attr('data-line-qty');

        let changeAddressPopup = new Popup();

        if($(this).attr('class') == 'set-address-cart-page'){
            let thisConsignment = JSON.parse($(this).attr('data-address'));
            let thisDeliveryAddress = thisConsignment || null;
            if(thisConsignment && thisConsignment.selectedShippingOption && thisConsignment.selectedShippingOption.description.toLowerCase().indexOf('pick') >=0) thisDeliveryAddress = null;
            changeAddressPopup.prepare({
                headerText: "Set Delivery Address",
                contentHTML: renderCheckoutAddress(thisDeliveryAddress),
                button1Text: "OKAY",
                button2Text: "CLOSE",
                onButton1Click: async () => {
                    let notComplete = false;
                    let addressFields = {};
                    $(`.notice-popup`).find('input[required]').removeAttr('style');
                    $(`.notice-popup`).find('select[required]').removeAttr('style');
                    $(`.notice-popup`).find('input').each(function(idx, element){
                        if($(element).attr('required') && $(element).val().length == 0){
                            notComplete = true;
                            $(element).attr('style', 'border-color:red;');
                        }else{
                            let fieldValue = $(element).val();
                            let field = $(element).attr('id').replace('Input', '');
                            addressFields[field.replace('Input', '')] = fieldValue;
                            field.indexOf('postCode') >= 0 ? addressFields['postalCode'] = fieldValue : '';
                            field.indexOf('address') >= 0 ? addressFields[field.replace('Input', '').replace('Line', '')] = fieldValue : '';
                            field.indexOf('province') >= 0 ? addressFields['stateOrProvinceCode'] = fieldValue : '';
                            field.indexOf('province') >= 0 ? addressFields['stateOrProvince'] = fieldValue : '';
                        }
                    })
                    $(`.notice-popup`).find('select[required]').each(function(idx, element){
                        if($(element).attr('required') && $(element).val().length == 0){
                            notComplete = true;
                            $(element).attr('style', 'border-color:red;');
                        }else{
                            let fieldValue = $(element).val();
                            let field = $(element).attr('id').replace('Input', '');
                            addressFields[field] = fieldValue;
                            field.indexOf('province') >= 0 ? addressFields['stateOrProvinceCode'] = fieldValue : '';
                            field.indexOf('province') >= 0 ? addressFields['stateOrProvince'] = fieldValue : '';
                        }
                    })

                    if(notComplete == false){
                        $(`.notice-popup`).find('#notice-btn-1').addClass('is-loading');
                        
                        SelectedSplitShippingMethods = SelectedSplitShippingMethods.filter((item) => item?.lineItem !== thisLineID);
                        SelectedSplitShippingMethods.push({ lineItem: thisLineID, method: 'shipping', deliveryAddress: addressFields});

                        localStorage.setItem('SelectedSplitShippingMethods', JSON.stringify(SelectedSplitShippingMethods));

                        let bodyAddress = [
                            {
                                "address": addressFields,
                                "lineItems": [{
                                    itemId: thisLineID, 
                                    quantity: cartMetaData.cart.lineItems.physicalItems.find(product=>product.id == thisLineID).quantity
                                }]
                            }
                        ];


                        console.log($(`.cnc__cartpage-smethod[data-line-id="${thisLineID}"] .loadingOverlay`));
                        $(`.cnc__cartpage-smethod[data-line-id="${thisLineID}"] .loadingOverlay`).show();

                        changeAddressPopup.close();
                        changeAddressPopup.remove();
                        
                        cartMetaData.checkout = await storefrontServices.createConsignment(cartMetaData.checkout.id, bodyAddress);

                        bopis = null;
                        
                        getBOPISData().then(async () => {
                            await setCartMetaData();
                            await setAvailableStoresCheckout();
                            renderDrawerStores();
                            renderCartItemAvailability();
                            $(`.cnc__cartpage-smethod[data-line-id="${thisLineID}"] .loadingOverlay`).hide();
                        })
                        
                    }
                },
                onButton2Click: () => {
                    changeAddressPopup.close();
                    changeAddressPopup.remove();
                }
            });

            changeAddressPopup.open();
        }

    });

    $("body").on("change", "[data-test='countryCodeInput-select']", async (e) => {
        let countryCode = $(e.currentTarget).val();
        let thisShippingCountry = cartMetaData.shippingCountries.find(country=>country.code == countryCode);
        if(!thisShippingCountry) return;

        let parentForm = $(e.currentTarget).parents('.checkout-address');

        $(parentForm).find('div.dynamic-form-field--provinceCode').attr('required-state', thisShippingCountry.requiresState);
        
        if(thisShippingCountry.subdivisions.length == 0){
            $(parentForm).find('div[for="provinceCodeSelect"]').hide();
            $(parentForm).find('div[for="provinceCodeText"]').show();
            $(parentForm).find('div[for="provinceCodeSelect"] select').removeAttr('required');
            
        }else{
            $(parentForm).find('div[for="provinceCodeSelect"] select').attr('required', 'required');
            $(parentForm).find('[data-test="provinceCodeInput-select"] option[value!=""]').remove();
            thisShippingCountry.subdivisions.forEach(function(subd){
                $(parentForm).find('[data-test="provinceCodeInput-select"]').append($('<option>').val(subd.code).text(subd.name));
            });
            $(parentForm).find('div[for="provinceCodeSelect"]').show();
            $(parentForm).find('div[for="provinceCodeText"]').hide();
        }

        $(parentForm).find('#checkout-shipping-options .loadingOverlay-container').hide();
        $(parentForm).find('#checkout-shipping-options .loading-skeleton, #checkout-shipping-options .shippingOptions-panel.optimizedCheckout-overlay').show();
        $(parentForm).find('[data-test="provinceInput-text"]').val('');
    });

    $("body").on("change", "[data-test='provinceCodeInput-select']", async (e) => {
        let provinceCode = $(e.currentTarget).val();
        let provinceText = $(e.currentTarget).find('option:selected').text();
        let parentForm = $(e.currentTarget).parents('.checkout-form, .checkout-address');
        $(parentForm).find('div[for="provinceCodeText"] input').val(provinceCode);
    });

    $('body').on('click', '.change-store-cart-page', async function () {
        $(this).parents('.cnc__cartpage-smethod').find('.loadingOverlay').show();
        await setCartMetaData();
        let thisSKU = $(this).parents('.cnc__cartpage-smethod').attr('data-sku');
        let thisLineID = $(this).parents('.cnc__cartpage-smethod').attr('data-line-id');
        changeStorePopUp.prepare({
            headerText: "Select Pickup Store",
            contentHTML: `
            <div class="cnc__pref-checkout-select-store" data-sku="${thisSKU}">
                <div class="cnc__pref-checkout-store-search-bar">
                    <div class="cnc__pref-checkout-search-by-location-group">
                        <div class="cnc__pref-checkout-search-input-wrapper">
                            ${ICONS.search}
                            <input placeholder="Enter postcode or suburb" type="text" id="cnc__pref-checkout-store-search-input-closest" data-thisLineID="${thisLineID}"data-sku="${thisSKU}">
                            <div class="cnc__pref-checkout-ripple-loader lds-ripple" style="display: none;">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                        <button id="cnc__pref-checkout-use-current-location" style="display:none;">
                            Use my current location
                        </button>
                    </div>
                </div>
                <ul class="stores">
                    ${renderStoreList(thisLineID, thisSKU, bopis?.allStores)}
                </ul>
            </div>
            `,
            button1Text: "OKAY",
            onButton1Click: () => {
                changeStorePopUp.close();
            },
            customClass: 'find-store'
        });

        $(this).parents('.cnc__cartpage-smethod').find('.loadingOverlay').hide();
        changeStorePopUp.open();

    });

    $('body').on('click', '.cnc__pref-checkout-set-store-btn', async function () {
        let thisLineItem = $(this).attr('this-lineitem');
        let thisStore = $(this).attr('store-id');
        SelectedSplitShippingMethods = JSON.parse(localStorage.getItem('SelectedSplitShippingMethods')) || [];
        let thisSelectedMethodInCart = SelectedSplitShippingMethods.find((item) => item?.lineItem === thisLineItem);
        if (thisSelectedMethodInCart == undefined) {
            thisSelectedMethodInCart = { lineItem: thisLineItem, method: 'pickup', storeid: thisStore };
        }
        thisSelectedMethodInCart.method = 'pickup';
        thisSelectedMethodInCart.storeid = thisStore;
        thisSelectedMethodInCart.deliveryAddress = null;
        SelectedSplitShippingMethods = SelectedSplitShippingMethods.filter((item) => item?.lineItem !== thisLineItem);
        localStorage.setItem('SelectedSplitShippingMethods', JSON.stringify(SelectedSplitShippingMethods.concat([thisSelectedMethodInCart])));

        changeStorePopUp.close();

        $(`.cnc__cartpage-smethod[data-line-id="${thisLineItem}"] .loadingOverlay`).show();

        /** Delete Consignment */
        if(cartMetaData.checkout.consignments.length > 0 && cartMetaData.checkout.consignments.find(con=>con.lineItemIds.indexOf(thisLineItem) >=0)){
            cartMetaData.checkout = await storefrontServices.createConsignment(cartMetaData.checkout.id, [{
                "address":{
                    "firstName":"",
                    "lastName":"",
                    "countryCode":cartMetaData.shippingCountries.length > 0 ? cartMetaData.shippingCountries[0].code : "US"
                },
                "lineItems":[
                    { 
                        "itemId": thisLineItem, 
                        "quantity": cartMetaData.cart.lineItems.physicalItems.find(prod=>prod.id == thisLineItem).quantity 
                    }
                ]
            }]);

            let newItems = cartMetaData.checkout.consignments.filter(con=>con.shippingAddress.firstName == "" && con.shippingAddress.lastName == "" && con.shippingAddress.address1 == "" && con.shippingAddress.address2 == "" && con.shippingAddress.city == "" && con.shippingAddress.stateOrProvince == "" && con.shippingAddress.phone == "");

            if(newItems.length > 0){
                for(let item of newItems){
                    cartMetaData.checkout = await storefrontServices.deleteConsignment(cartMetaData.checkout.id, item.id);
                }
            }
        }
        /** Delete Consignment */
        
        bopis = null;
        
        getBOPISData().then(async () => {
            await setCartMetaData();
            renderCartItemAvailability();
            $(`.cnc__cartpage-smethod[data-line-id="${thisLineItem}"] .loadingOverlay`).hide();
        })
    });
}

export const isLiveSite = window.location.hostname?.includes("sandbox") ? false : true;

export const applicationId = isLiveSite ? "cab7d733-0de1-4b47-a701-d84cf7b35e1d" : "45fbae2d-7d7b-454f-94c5-da35ce00da5c";

export const api_baseurl = isLiveSite ? 'https://api.randemretail.online/public/api/' : 'https://uat-api.randemretail.online/public/api/';

export const sdd_api_baseurl = isLiveSite ? 'https://api.randemretail.online/public/api/distance/calculate' : 'https://uat-api.randemretail.online/public/api/distance/calculate';

export const googleMapAPIKey = "AIzaSyC3pgFDJCjPmZdRu15G58Cx-dlJvkOlf_M";

export const BIGCOMMERCE_PLACEHOLDERS = {
    headerDesktop: "header .navUser-section",
    headerMobile: "header",
    productATC: "section.productView-details.product-options .productView-options",
    productPageCNCStores: "#tab-cc .tab-mobile-content"
}

export const GENERATED_PLACEHOLDERS = {
    headerDesktop: "#click-and-collect-header-component",
    headerMobile: "#cnc__header-placeholder-mobile",
    productPageDeliveryType: "#cnc__product-delivery-type",
    productPageSDDDeliveryType: "#cnc__product-sdd-delivery-type",
    productPageCNCStores: "#cnc__product-store-list"
};

export const staticCustomizations = {
    PDPNoStoreSelected: "Please select store to view availability",
    PickupButtontext: "Set your Store",
    PickupButtonSecondarytext: "Find your local store",
    NoSelectedVariants: "Please select variant to view product availability",
    OnlineAvailabilityLabel: "Available Online",
    OnlineNotAvailabilityLabel: "Unavailable for Home Delivery",
    PISAvailabilityLabel: "Order for Collection from",
    PISNotAvailabilityLabel: "Out of Stock in",
    PDPTitle: "PRODUCT AVAILABILITY",
    CheckoutShippingStepHeaderText: "Select Preferred Checkout",
    CartOOSInPIS: "Not Available for Collection from ",
    CartAvailInPIS: "Available For Pickup",
    CartAvailInDel: "Available For Home Delivery",
    CartOOSInDel: "Not Available For Home Delivery",
};
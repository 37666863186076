import { setCartMetaData } from "../cart/cartData";
import { initAddress, isCheckoutPage, observeSections } from "../common/utils";
import { renderDrawerStores } from "../globalPage/globalPageRenderer";
import { setAvailableStoresCheckout, setStore } from "../globalPage/globalpageData";
import { setCheckoutMetaData } from "./checkoutData";
import { bindCheckoutEvents, bindCheckoutRender, checkoutAfterUpdaterListenerInit, checkoutUpdaterListenerInit, loadersInCheckout } from "./checkoutListeners";
import { renderCheckoutCnC } from "./checkoutRenderer";
import { staticCustomizations } from "../common/globalConstants";
import { CheckoutObserver } from "../common/observer";

export const initCheckout = {
    preCheckout:async function () {
        if (!isCheckoutPage()) return;
        let checkoutObserver = new CheckoutObserver();

        setInterval(() => {
            $(".checkout-step--shipping .stepHeader-title").text(staticCustomizations.CheckoutShippingStepHeaderText).css('flex', '12');
        }, 50);

        await setCheckoutMetaData();
        observeSections('.checkout-form form #checkoutShippingAddress').then(async function (elm) {
            loadersInCheckout();
            
            setInterval(() => {
                checkoutUpdaterListenerInit();
            }, 50);

            await renderCheckoutCnC();

            setInterval(()=>{
                if(checkoutObserver.isShippingAddressOpened() && $(`body #addressLine1Input`).length && $('#customAutoComplete-addressLine1Input').length == 0){
                    $(`body #addressLine1Input`).parent().append('<input aria-labelledby="customAutoComplete-addressLine1Input-label customAutoComplete-addressLine1Input-field-error-message" autocomplete="none" id="customAutoComplete-addressLine1Input" type="text" class="form-input optimizedCheckout-form-input" data-test="customAutoComplete-addressLine1Input-text" value="">')
                    $(`body #addressLine1Input`).hide();
                    
                    setTimeout(() => {
                        initAddress('customAutoComplete-addressLine1Input', 'checkout');
                    }, 3000);
                }
            }, 300)
        });
        
        observeSections('.checkout-form form #checkoutBillingAddress').then(async function (elm) {
            setInterval(()=>{
                if(checkoutObserver.isBillingAddressOpened() && $(`body #addressLine1Input`).length && $('#customAutoComplete-addressLine1Input-forBilling').length == 0){
                    $(`body #addressLine1Input`).parent().append('<input aria-labelledby="customAutoComplete-addressLine1Input-forBilling-label customAutoComplete-addressLine1Input-forBilling-field-error-message" autocomplete="none" id="customAutoComplete-addressLine1Input-forBilling" type="text" class="form-input optimizedCheckout-form-input" data-test="customAutoComplete-addressLine1Input-text" value="'+$(`body #addressLine1Input`).val()+'">')
                    $(`body #addressLine1Input`).hide();
                    
                    setTimeout(() => {
                        initAddress('customAutoComplete-addressLine1Input-forBilling', 'checkout');
                    }, 3000);
                }
            }, 300)
        });
    },
    loadCheckout: async function () {
        if (!isCheckoutPage()) return;

        if (selectedStoreId) {
            await setStore(selectedStoreId);
        }

        await setAvailableStoresCheckout();
        bindCheckoutEvents();
        await renderCheckoutCnC();
        renderDrawerStores();
        
        setInterval(() => {
            checkoutAfterUpdaterListenerInit();
        }, 50);
    }
}
const { initCart } = require("./cart/cart");
const { initCheckout } = require("./checkout/checkout");
const { initWindowObj } = require("./common/initWindowObj");
const { getCookie, isOrderConfirmationPage } = require("./common/utils");
const { initGlobal } = require("./globalPage/globalPage");
const { initPDP } = require("./productDetailsPage/productDetailsPage");
const { initPLP } = require("./productListingPage/productListingPage");
const { getBOPISData } = require("./services/randemRetailServices");

let devMode = getCookie("devMode") || null;
devMode = false;

if (devMode === "true") {
    return;
} else {
    cncDefer();
    var addedCustomJquery = false;
    function cncDefer() {
        if (window.jQuery) {
            console.log(jQuery().jquery, "jQuery().jquery");
            if(jQuery().jquery == '1.11.1'){
                if(addedCustomJquery == false){
                    $('head').append('<script type="text/javascript" src="//ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js" defer></script>');
                    addedCustomJquery = true;
                }
                setTimeout(function () { cncDefer() }, 100);
            }else{
                init();
            }
        } else {
            setTimeout(function () { cncDefer() }, 100);
        }
    }
}

async function init() {
    if (isOrderConfirmationPage()) {
        sessionStorage.removeItem("orderComments");
    }

    window.RRStock = null;

    initWindowObj();
    initGlobal.preHeader();
    initPDP.prepPDP();
    initCart.preCart();
    initCheckout.preCheckout();
    initPLP.prePLP();

    await getBOPISData();

    initPDP.loadPDP();
    initCart.loadCart();
    initCheckout.loadCheckout();
    initPLP.loadPLP();
    await initGlobal.loadHeader();
    $('.cs-address-loader-hldr').hide();
    window.isInitialBOPISLoad = false;
}
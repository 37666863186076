import { isBrandPage, isCategoryPage, isSearchPage } from "../common/utils";
import { setPLPMetaData } from "./productListingPageData";
import { bindPLPListeners } from "./productListingPageListener";
import { renderProductAvailability, renderProductAvailabilityFilters } from "./productListingPageRenderer";

export const initPLP = {
    prePLP: async function () {
        if (!isCategoryPage() && !isBrandPage() && !isSearchPage())
            return;

        bindPLPListeners();
    },
    loadPLP: async function () {
        if (!isCategoryPage() && !isBrandPage() && !isSearchPage())
            return;

        await renderProductAvailability();
        await renderProductAvailabilityFilters();

        setPLPMetaData();
    }
}


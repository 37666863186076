import { setCartMetaData } from "../cart/cartData";
import { googleMapAPIKey } from "../common/globalConstants";
import { ICONS } from "../common/icons";
import { getCookie, removeComma, groupThisArrayBy, getItemStock, initAddress } from "../common/utils";
import { setAvailableStoresCheckout, setStoreHours } from "../globalPage/globalpageData";
import { emptyShippingAddress, setShippingMethod } from "./checkoutData";
import { loadersInCheckout } from "./checkoutListeners";

export const renderCheckoutSelectedStoreInfo = async () => {
    console.log(filteredStoresByAvailability, "filteredStoresByAvailability");
    const isAvailbleSelectedStoreIdInfo = filteredStoresByAvailability.find(store => store?.locationId == selectedStoreId);
    const selectedStoreIdInfo = bopis?.allStores?.find(store => store?.locationId == selectedStoreId);
    const orderComments = sessionStorage.getItem("orderComments") || null;

    let selectedStoreInfoHTML = ``;
    if (selectedStoreIdInfo) {
        var combinedStoreHours = setStoreHours(selectedStoreIdInfo.operatingHours, selectedStoreIdInfo.locationId);
        selectedStoreInfoHTML = `
        
        <div class="selected-store-info">
            <div class="store-text-info">
                ${splitShippingMethod ? ``:`
                <p class="checkout-availability ${!isAvailbleSelectedStoreIdInfo ? 'cnc__color-unavailable' : 'cnc__color-available'}">
                    ${!isAvailbleSelectedStoreIdInfo ? 'Not Available for Pickup' : 'Available for Pickup'}
                </p>
                `}
                <p class="store-name">
                    ${selectedStoreIdInfo.storeName}
                </p>
                <p class="" store-address>
                    ${selectedStoreIdInfo.street?.length ? removeComma(selectedStoreIdInfo.street) + ", " : ""}${selectedStoreIdInfo.city}, ${selectedStoreIdInfo.state},
                    ${selectedStoreIdInfo.country}, ${selectedStoreIdInfo.zipCode}
                </p>
                <div class="operating-hours-section">
                    ${selectedStoreIdInfo.operatingHours && combinedStoreHours ?
                `<h5>Store Hours:</h5>
                            ${combinedStoreHours?.map(day => {
                    return `<div class="store-days">
                                    <div>
                                        <span>${day.day}:<span>
                                    </div>
                                    <div>
                                        ${day?.operatingHours?.length > 0 ?
                            day?.operatingHours?.map(hour => {
                                var fromTime = new Date('1970-01-01T' + hour.fromTime).toLocaleTimeString();
                                var toTime = new Date('1970-01-01T' + hour.toTime)?.toLocaleTimeString();

                                return `<p>${fromTime.split(fromTime.slice(-6, -2)).join('')} - ${toTime.split(toTime.slice(-6, -2)).join('')}</p>`
                            }).join("")
                            : `<p>Closed</p>`
                        }
                                    </div>
                                </div>`
                }).join("")}
                            `
                : ``
            }
                </div>
                ${selectedStoreIdInfo.phone ? `
                    <div class="store-phone-section">
                        <h5>Telephone number:</h5>
                        <div class="store-phone">
                            <div>
                                <a href="tel:${selectedStoreIdInfo.phone}">${selectedStoreIdInfo.phone}</a>
                            </div>
                        </div>
                    </div>`
                : ``}
                ${selectedStoreIdInfo.emailAddress ? `
                    <div class="store-phone-section">
                        <h5>Email</h5>
                        <div class="store-phone">
                            <div>
                                <a href="mailto:${selectedStoreIdInfo.emailAddress}">${selectedStoreIdInfo.emailAddress}</a>
                            </div>
                        </div>
                    </div>`
                : ``}
            </div>
            <div class="store-map">
                <iframe
                    width="100%"
                    height="250"
                    frameborder="0"
                    style="border:0"
                    referrerpolicy="no-referrer-when-downgrade"
                    src="https://www.google.com/maps/embed/v1/place/?key=${googleMapAPIKey}
                            &q=${encodeURIComponent(selectedStoreIdInfo.storeName)}
                            +${selectedStoreIdInfo.street}
                            +${selectedStoreIdInfo.city}
                            +${selectedStoreIdInfo.state}
                            +${selectedStoreIdInfo.country}
                            &center=${selectedStoreIdInfo.latitude},${selectedStoreIdInfo.longitude}&zoom=18"
                    allowfullscreen>
                </iframe>
            </div>
        </div>`
    }
    let preferredCheckoutMethod = getCookie("checkoutMethod") || "shipping";

    let HTMLToRender = `
        <div class="checkout-form-pickup ${!preferredCheckoutMethod || preferredCheckoutMethod === "shipping" || preferredCheckoutMethod === "sdd" ? "hide" : ""}">
            <div class="cnc__select-store-dropdown">
                <a class="change-selected-store">
                    <div class="icon">
                        ${ICONS.pinOutlineBlack}
                    </div>
                    <label>${selectedStoreIdInfo ? 'Change Pickup Store' : 'Select Pickup Store'}</label>
                </a>
                ${selectedStoreIdInfo ? selectedStoreInfoHTML : ``}

                ${selectedStoreIdInfo && isDatePickerEnabled == true ?
            `
                <div id="datePickerSectionWrapper">
                    <p style="margin-top: 15px; font-weight: bold; margin-bottom: 0;">Choose your pick-up slot</p>
                    <div id="datePickerSection">
                        <div id="datepicker-container">
                            <label class="form-label" for="allTimes">Date</label>
                            <input type="text" id="allDates" placeholder="Date to pick-up" class="form-input datePicker">
                        </div> <div id="timepicker-container" style="display: none;"> <label class="form-label" for="allTimes">Time </label>
                            <select name="allTimes" id="allTimes" class="form-select" >
                            </select>
                        </div>
                    </div>
                </div>
                `: ``}

                <fieldset class="form-fieldset" data-test="checkout-shipping-comments">
                    <legend class="form-legend optimizedCheckout-headingSecondary">Order Comments</legend>
                    <div class="form-body">
                        <div class="form-field"><label for="orderComment" class="form-label is-srOnly optimizedCheckout-form-label">Order Comments</label>
                            <input autocomplete="off" maxlength="2000" type="text" class="form-input optimizedCheckout-form-input" name="orderComment" value="${orderComments?.length ? orderComments : ""}">
                            </div>
                        </div>
                </fieldset>

                ${selectedStoreIdInfo ? `
                <div class="form-actions">
                    <div class="cs-address-loader-hldr" style="display: none;"> <div class="cs-address-loader"></div> </div>
                    <button
                        id="cnc__fake-checkout-shipping-continue"
                        class="button button--primary optimizedCheckout-buttonPrimary"
                    >Continue</button>
                </div>`: ``}
            </div>
        </div >
        
    `;

    if (!$(".checkout-form-pickup").length) {
        $(".checkout-step--shipping .cnc__shipping-types").after(HTMLToRender);
        emptyShippingAddress();
    } else {
        $(".checkout-form-pickup").html(HTMLToRender);
    }


    if (isDatePickerEnabled == true) {
        var $datePicker = $('#allDates');
        $datePicker.datepicker({
            format: 'yyyy-mm-dd',
            autoHide: true,
            startDate: new Date()
        });
    }
}

export const renderCheckoutCnC = async () => {
    return new Promise(async resolve => {
        if(splitShippingMethod == true) {resolve();}
        if(splitShippingMethod == true) {renderCheckoutCnCSplitShipping(); return;}
        let preferredCheckoutMethod = getCookie("checkoutMethod") || "shipping";

        if (!$(`.cnc__shipping-types`).length)
            $(".checkout-step--shipping .checkout-view-content").prepend(`
            <div class="cnc__shipping-types">
                ${isDeliveryMethodEnabled == true ? `
                <button 
                    id="cnc__shipping-delivery"
                    class="cnc__shipping-type-button button button button--secondary 
                    ${preferredCheckoutMethod === "shipping" ? "active" : ""}">
                        DELIVERY / SHIPPING
                </button >
                `: ``}

                ${isPISMethodEnabled == true ? `
                <button
                    id="cnc__shipping-pickup"
                    class="cnc__shipping-type-button button button button--secondary 
                    ${preferredCheckoutMethod === "pickup" ? "active" : ""}">
                        PICKUP IN STORE
                </button >
                `: ``}

                ${isSDDMethodEnabled == true ? `
                <button
                    id="cnc__same-day-delivery"
                    class="cnc__shipping-type-button button button button--secondary 
                    ${preferredCheckoutMethod === "sdd" ? "active" : ""}">
                        SAME DAY DELIVERY
                </button >
                `: ``}

            </div >
            `);

        await setShippingMethod();
        $('div#replica-loading-skeleton').remove();
        renderCheckoutSelectedStoreInfo();

        resolve()
    })
}

export const renderCheckoutCnCSplitShipping = async () => {
    if(splitShippingMethod == false) return;
    let htmlToRender = `<div class="cnc__split-shipping">`;
    await setCartMetaData();
    let orderComments = cartMetaData.checkout.customerMessage;
    htmlToRender = htmlToRender + `
        ${groupThisArrayBy( {Group: cartMetaData.cartProducts, By: ['consignmentID', 'method', 'deliveryAddress']}).map(shippingItem => {
            let isOrderDelivery = shippingItem[0].method == 'pickup' ? false: true;
            let thisConsignment = cartMetaData.checkout?.consignments?.find(consignment => consignment.id == shippingItem[0].consignmentID);
            let thisSelectedStoreID  = shippingItem[0].method == 'pickup' ? shippingItem[0].storeid: null;
            return `<div aria-busy="false" class="${shippingItem.length > 1 ? `multiple-cart-item`:``} checkout-view-content split-shipping-row" checkout-type="${shippingItem[0].method}" consignment-id="${shippingItem[0].consignmentID || shippingItem[0].lineitemId}">
            <div class="loadingOverlay" style="display: none;"></div>
                        ${shippingItem.length > 1 ? `<a class="edit-cart" href="/cart.php">Edit Cart</a>`:``}
                        <section class="cart-section optimizedCheckout-orderSummary-cartSection">
                            <ul aria-live="polite" class="productList">
                                ${shippingItem.map(item=>{
                                    return `
                                    <li class="productList-item is-visible" line-sku="${item.sku}" line-item="${item.lineitemId}" line-qty="${item.quantity}">
                                        <div class="product" data-test="cart-item">
                                            <figure class="product-column product-figure"><img
                                                    alt="${item.name}" data-test="cart-item-image"
                                                    src="${item.thumbnail}">
                                            </figure>
                                            <div class="product-column product-body">
                                            ${item.isPickupOnly == true ? `<p class="cnc__product-restrict">Click & Collect Only</p>`:``}
                                                <h4 class="product-title optimizedCheckout-contentPrimary"
                                                    data-test="cart-item-product-title">${item.quantity} x ${item.name}
                                                </h4>
                                                <ul class="product-options optimizedCheckout-contentSecondary"
                                                    data-test="cart-item-product-options">
                                                    <li class="product-option" data-test="cart-item-product-option">${item.sku}</li>
                                                </ul>
                                                <div class="product-price optimizedCheckout-contentPrimary" data-test="cart-item-product-price">
                                                    $${item.price.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </div>
                                            </div>
                                        </div>
                                    </li>`
                                }).join("")}
                            </ul>
                        </section>

                        ${bopis.allStores == 0 ? renderLoadingReplica():`
                        <div class="cnc__split-shipping-types">
                            ${isDeliveryMethodEnabled == true ? `
                            <button 
                                id="cnc__split-shipping-delivery"
                                value="shipping"
                                class="cnc__split-shipping-type-button button button button--secondary ${shippingItem[0].method == 'shipping' ? `active`:``}">
                                    DELIVERY
                            </button >
                            `: ``}

                            ${isPISMethodEnabled == true ? `
                            <button
                                id="cnc__split-shipping-pickup"
                                value="pickup"
                                class="cnc__split-shipping-type-button button button button--secondary ${shippingItem[0].method == 'pickup' ? `active`:``}">
                                    CLICK & COLLECT
                            </button >
                            `: ``}
                            
                        </div>

                        <div class="cnc__split-shipping-delivery ${shippingItem[0].method == 'shipping' ? ``:`hide`}">
                            ${renderShippingForm(thisConsignment?.address || shippingItem[0].deliveryAddress, thisConsignment, shippingItem[0].consignmentID, shippingItem)}
                        </div>
                        <div class="cnc__split-shipping-pis ${shippingItem[0].method == 'pickup' ? ``:`hide`}">
                            ${renderPISForm(thisSelectedStoreID, shippingItem[0].consignmentID || shippingItem[0].lineitemId, shippingItem.map(item=>item.lineitemId))}
                        </div>
                        `}
                        
                    </div>`
            }).join("")
        }
    `;

    
    
    htmlToRender = htmlToRender + `
        <fieldset class="form-fieldset" data-test="checkout-shipping-comments">
            <legend class="form-legend optimizedCheckout-headingSecondary">Order Comments</legend>
            <div class="form-body">
                <div class="form-field"><label for="orderComment" class="form-label is-srOnly optimizedCheckout-form-label">Order Comments</label>
                    <input autocomplete="off" maxlength="2000" type="text" class="form-input optimizedCheckout-form-input" name="orderComment" value="${orderComments?.length ? orderComments : ""}">
                    </div>
                </div>
        </fieldset>
        <div class="form-actions">
            <button id="cnc__fake-checkout-split-shipping-continue" class="button button--primary optimizedCheckout-buttonPrimary">Continue</button>
        </div>
    </div>`;

    $('div#replica-loading-skeleton').remove();
    if( $('.split-shipping-hldr').length > 0){
        $('.split-shipping-hldr').html(htmlToRender);
    }else{
        $(".checkout-step--shipping .checkout-view-content").prepend('<div class="split-shipping-hldr">'+htmlToRender+'</div>');
    }
}

function renderShippingForm(thisShippingAddress = {}, thisConsignment = null, consignmentID, shippingItem){
    if(thisShippingAddress?.lastName == 'Store') {thisShippingAddress = {}; thisConsignment = null}
    let availableShippingOptions = thisConsignment?.availableShippingOptions?.filter(opt => opt.description.toLowerCase().indexOf('pickup') < 0 && opt.description.toLowerCase().indexOf('pick up') < 0);
    let selectedShippingOption = thisConsignment?.selectedShippingOption;
    let notAvailableforSFS = shippingItem.map(function (item){
        let thisProduct = cartMetaData.cartProducts.find(product=>product.lineitemId == item.lineitemId);
        let pickUpOnlyProduct = cartMetaData.cartProducts.find(product=>product.productId == item.productId).isPickupOnly;
        if((thisProduct.stockCountInWarehouse >= thisProduct.quantity) == false || pickUpOnlyProduct == true) return item;
    }).filter(function( element ) {
        return element !== undefined;
    });
    return notAvailableforSFS.length > 0 ? `
    <div class="sfs-not-available-hldr">
        <p>These items are not Available for Delivery:</p>
        <ul>
            ${notAvailableforSFS.map(item=>`<li>${item.name}</li>`).join("")}
        </ul>
    </div>
    `:`
    <div class="checkout-form sfs-form">
        <div class="form-legend-container">
            <legend class="form-legend optimizedCheckout-headingSecondary" data-test="shipping-address-heading">
                Shipping Address
            </legend>
        </div>
        <div>
            <form autocomplete="on" novalidate="">
                <fieldset class="form-fieldset">
                    <div class="form-body">
                        <fieldset id="checkoutShippingAddress" class="form-fieldset">
                            <div class="form-body">
                                <div>
                                    <fieldset id="shippingAddresses" class="form-fieldset custom__address-book">
                                        <div class="form-body">
                                            <div class="loadingOverlay-container">
                                                <div class="form-field">
                                                    <div class="dropdown--select">
                                                        <div class="dropdownTrigger">
                                                            <a aria-controls="addressDropdown" aria-description="Enter or select a different address" aria-expanded="false" class="button dropdown-button dropdown-toggle--select" href="#" id="addressToggle">
                                                                Enter a new address
                                                            </a>
                                                        </div>
                                                        <div class="dropdownMenu hide" style="position: absolute; transform: translate3d(0px, 45px, 0px); top: 0px; left: 0px; will-change: transform; width: 100%; z-index: 1;">
                                                            <ul class="dropdown-menu instrumentSelect-dropdownMenu" id="addressDropdown">
                                                                <li class="dropdown-menu-item dropdown-menu-item--select">
                                                                    <a data-test="add-new-address" href="#">Enter a new address</a>
                                                                </li>
                                                                <li class="dropdown-menu-item dropdown-menu-item--select hide">
                                                                    <a href="#">
                                                                        <div class="vcard checkout-address--static">
                                                                            <p class="fn address-entry">
                                                                                <span class="first-name">Diane </span>
                                                                                <span class="family-name">Ocampo</span>
                                                                            </p>
                                                                            <p class="address-entry">
                                                                                <span class="company-name">UK Address </span>
                                                                                <span class="tel">1234567890</span>
                                                                            </p>
                                                                            <div class="adr">
                                                                                <p class="street-address address-entry">
                                                                                    <span class="address-line-1">170 Barlow Moor Road </span>
                                                                                    <span class="address-line-2">Address LIne 2</span>
                                                                                </p>
                                                                                <p class="address-entry">
                                                                                    <span class="locality">Manchester, </span>
                                                                                    <span class="region">England, </span>
                                                                                    <span class="postal-code">M20 2AF</span>
                                                                                    <span class="country-name">United Kingdom </span>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                ${(cartMetaData.checkout.consignments.map(con=>con.shippingAddress)).concat(cartMetaData.customerAddresses).filter(con=>con.lastName !== 'Store').map(con=>{
                                                                    return `
                                                                    <li class="dropdown-menu-item dropdown-menu-item--select newly-added-address">
                                                                        <a href="#">
                                                                            <div class="vcard checkout-address--static">
                                                                                <p class="fn address-entry">
                                                                                    <span class="firstName">${con.firstName || ''}</span>
                                                                                    <span class="lastName">${con.lastName || ''}</span>
                                                                                </p>
                                                                                <p class="address-entry">
                                                                                    <span class="company">${con.company || ''}</span>
                                                                                    <span class="phone">${con.phone || ''}</span>
                                                                                </p>
                                                                                <div class="adr">
                                                                                    <p class="street-address address-entry">
                                                                                        <span class="address1">${con.address1 || ''}</span>
                                                                                        <span class="address2">${con.address2 || ''}</span>
                                                                                    </p>
                                                                                    <p class="address-entry">
                                                                                        <span class="city">${con.city || ''}</span>
                                                                                        <span>${con.stateOrProvince || ''}</span>
                                                                                        <span class="postalCode">${con.postalCode || ''}</span>
                                                                                        <span class="countryCode">${con.country || ''}</span>
                                                                                        <span class="stateOrProvince stateOrProvinceCode hide">${con.stateOrProvince || ''}</span>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </li>
                                                                    `
                                                                }).join("")}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                    <fieldset class="form-fieldset">
                                        <div class="form-body">
                                            <div class="checkout-address">
                                                <div
                                                    class="dynamic-form-field floating-form-field dynamic-form-field--firstName">
                                                    <div class="form-field"><input
                                                            required=""
                                                            aria-labelledby="firstNameInput-label firstNameInput-field-error-message"
                                                            autocomplete="given-name" id="firstNameInput" type="text"
                                                            class="form-input optimizedCheckout-form-input floating-input"
                                                            name="firstName" data-test="firstNameInput-text"
                                                            placeholder="" value="${thisShippingAddress?.firstName || ''}"><label for="firstNameInput"
                                                            id="firstNameInput-label"
                                                            class="floating-label form-label optimizedCheckout-form-label">First
                                                            Name</label></div>
                                                </div>
                                                <div
                                                    class="dynamic-form-field floating-form-field dynamic-form-field--lastName">
                                                    <div class="form-field"><input
                                                            required=""
                                                            aria-labelledby="lastNameInput-label lastNameInput-field-error-message"
                                                            autocomplete="family-name" id="lastNameInput" type="text"
                                                            class="form-input optimizedCheckout-form-input floating-input"
                                                            name="lastName" data-test="lastNameInput-text"
                                                            placeholder="" value="${thisShippingAddress?.lastName || ''}"><label for="lastNameInput"
                                                            id="lastNameInput-label"
                                                            class="floating-label form-label optimizedCheckout-form-label">Last
                                                            Name</label></div>
                                                </div>
                                                <div
                                                    class="dynamic-form-field floating-form-field dynamic-form-field--company">
                                                    <div class="form-field"><input
                                                            aria-labelledby="companyInput-label companyInput-field-error-message"
                                                            autocomplete="organization" id="companyInput" type="text"
                                                            class="form-input optimizedCheckout-form-input floating-input"
                                                            name="company" data-test="companyInput-text"
                                                            placeholder="" value="${thisShippingAddress?.company || ''}"><label for="companyInput"
                                                            id="companyInput-label"
                                                            class="floating-label form-label optimizedCheckout-form-label">Company
                                                            Name <small
                                                                class="optimizedCheckout-contentSecondary">(Optional)</small></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="dynamic-form-field floating-form-field dynamic-form-field--phone">
                                                    <div class="form-field"><input
                                                            required=""
                                                            aria-labelledby="phoneInput-label phoneInput-field-error-message"
                                                            autocomplete="tel" id="phoneInput" type="tel"
                                                            class="form-input optimizedCheckout-form-input floating-input"
                                                            name="phone" data-test="phoneInput-text"
                                                            placeholder="" value="${thisShippingAddress?.phone || ''}"><label for="phoneInput"
                                                            id="phoneInput-label"
                                                            class="floating-label form-label optimizedCheckout-form-label">Phone
                                                            Number </label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="dynamic-form-field floating-form-field dynamic-form-field--addressLine1">
                                                    <div class="form-field"><input
                                                            required=""
                                                            aria-labelledby="addressLine1Input-label addressLine1Input-field-error-message"
                                                            autocomplete="address-line1" id="addressLine1Input" type="text"
                                                            class="form-input optimizedCheckout-form-input floating-input"
                                                            name="address1"
                                                            data-test="addressLine1Input-text" placeholder="" value="${thisShippingAddress?.address1 || ''}">
                                                            <label for="addressLine1Input" id="addressLine1Input-label"
                                                            class="floating-label form-label optimizedCheckout-form-label">Address</label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="dynamic-form-field floating-form-field dynamic-form-field--addressLine2">
                                                    <div class="form-field"><input
                                                            aria-labelledby="addressLine2Input-label addressLine2Input-field-error-message"
                                                            autocomplete="address-line2" id="addressLine2Input" type="text"
                                                            class="form-input optimizedCheckout-form-input floating-input"
                                                            name="address2"
                                                            data-test="addressLine2Input-text" placeholder="" value="${thisShippingAddress?.address2 || ''}">
                                                            <label for="addressLine2Input"
                                                            id="addressLine2Input-label"
                                                            class="floating-label form-label optimizedCheckout-form-label">Apartment/Suite/Building
                                                            <small
                                                                class="optimizedCheckout-contentSecondary">(Optional)</small></label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="dynamic-form-field floating-form-field dynamic-form-field--city">
                                                    <div class="form-field"><input
                                                            required=""
                                                            aria-labelledby="cityInput-label cityInput-field-error-message"
                                                            autocomplete="address-level2" id="cityInput" type="text"
                                                            class="form-input optimizedCheckout-form-input floating-input"
                                                            name="city" data-test="cityInput-text"
                                                            placeholder="" value="${thisShippingAddress?.city || ''}"><label for="cityInput"
                                                            id="cityInput-label"
                                                            class="floating-label form-label optimizedCheckout-form-label">City</label>
                                                    </div>
                                                </div>
                                                <div
                                                    class="dynamic-form-field floating-form-field dynamic-form-field--countryCode">
                                                    <div class="form-field">
                                                        <select
                                                            required
                                                            aria-labelledby="countryCodeInput-label countryCodeInput-field-error-message"
                                                            autocomplete="country"
                                                            class="floating-select form-select optimizedCheckout-form-select"
                                                            data-test="countryCodeInput-select" id="countryCodeInput"
                                                            name="countryCode">
                                                            <option value="">Select a country</option>
                                                            ${cartMetaData.shippingCountries?.length > 0 ? 
                                                                cartMetaData.shippingCountries.map(function(country){ 
                                                                    return `<option value="${country.code}" ${country.code == thisShippingAddress?.countryCode ? `selected=""`:``}>${country.name}</option>`
                                                                }).join("")
                                                                :`<option value="AU">Australia</option>`}
                                                        </select><label for="countryCodeInput" id="countryCodeInput-label"
                                                            class="floating-label form-label optimizedCheckout-form-label">Country</label>
                                                    </div>
                                                </div>
                                                <div class="dynamic-form-field floating-form-field dynamic-form-field--provinceCode" required-state="${cartMetaData.shippingCountries.find(country=>country.code == thisShippingAddress?.countryCode)?.requiresState}">
                                                    <div class="form-field" for="provinceCodeSelect" ${thisShippingAddress?.countryCode == undefined || cartMetaData.shippingCountries.find(country=>country.code == thisShippingAddress?.countryCode)?.subdivisions.length == 0 ? `style="display:none"`:``}>
                                                        <select ${cartMetaData.shippingCountries.find(country=>country.code == thisShippingAddress?.countryCode)?.requiresState ? `required=""`:``}  aria-labelledby="provinceCodeInput-label provinceCodeInput-field-error-message"
                                                        autocomplete="address-level1" class="floating-select form-select optimizedCheckout-form-select"
                                                        data-test="provinceCodeInput-select" id="provinceCodeInput" name="stateOrProvinceCode">
                                                            <option value="">Select a state</option>
                                                            ${cartMetaData.shippingCountries.find(country=>country.code == thisShippingAddress?.countryCode)?.subdivisions.map(function(province){
                                                                return `<option value="${province.code}" ${province.code == thisShippingAddress?.stateOrProvinceCode ? `selected=""`:``}>${province.name}</option>`
                                                            }).join("")}
                                                        </select>
                                                        <label for="provinceCodeSelect" class="floating-label form-label optimizedCheckout-form-label">State/Province</label>
                                                    </div>
                                                    <div class="form-field" for="provinceCodeText" ${cartMetaData.shippingCountries.find(country=>country.code == thisShippingAddress?.countryCode)?.subdivisions.length > 0 ? `style="display:none"`:``}>
                                                        <input ${cartMetaData.shippingCountries.find(country=>country.code == thisShippingAddress?.countryCode)?.requiresState ? `required=""`:``} aria-labelledby="provinceInput-label provinceInput-field-error-message" autocomplete="address-level1" id="provinceInput" type="text" class="form-input optimizedCheckout-form-input floating-input" name="stateOrProvince" data-test="provinceInput-text" placeholder="" value="${thisShippingAddress?.stateOrProvinceCode || thisShippingAddress?.stateOrProvince || thisShippingAddress?.province || ''}">
                                                            <label for="provinceCodeText" class="floating-label form-label optimizedCheckout-form-label">State/Province</label>
                                                    </div>    
                                                </div>
                                                <div
                                                    class="dynamic-form-field floating-form-field dynamic-form-field--postCode">
                                                    <div class="form-field"><input
                                                            required=""
                                                            aria-labelledby="postCodeInput-label postCodeInput-field-error-message"
                                                            autocomplete="postal-code" id="postCodeInput" type="text"
                                                            class="form-input optimizedCheckout-form-input floating-input"
                                                            name="postalCode" data-test="postCodeInput-text"
                                                            placeholder="" value="${thisShippingAddress?.postalCode || ''}"><label for="postCodeInput"
                                                            id="postCodeInput-label"
                                                            class="floating-label form-label optimizedCheckout-form-label">Postal
                                                            Code</label></div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </fieldset>
                        <div class="form-field">
                            <input id="shouldSaveAddress-${consignmentID}" type="checkbox" class="shouldSaveAddress form-checkbox optimizedCheckout-form-checkbox" name="shouldSaveAddress-${consignmentID}" value="true" checked="">
                            <label for="shouldSaveAddress-${consignmentID}" class="form-label optimizedCheckout-form-label">Save this address in my address book.</label>
                        </div>
                        <div class="form-body" style="display:none;">
                            <div class="form-field"><input id="sameAsBilling" type="checkbox"
                                    class="form-checkbox optimizedCheckout-form-checkbox" name="billingSameAsShipping"
                                    value="true" checked=""><label for="sameAsBilling"
                                    class="form-label optimizedCheckout-form-label">My billing address is the same as my
                                    shipping address.</label></div>
                        </div>
                    </div>
                </fieldset>
                <fieldset id="checkout-shipping-options" class="form-fieldset">
                    <legend class="form-legend optimizedCheckout-headingSecondary">Shipping Method</legend>
                    <div class="form-body">
                        <div class="shippingOptions-container form-fieldset">
                            <div class="loadingOverlay-container" ${thisShippingAddress?.firstName?.length > 0 && availableShippingOptions ? `` : `style="display: none;"`}>
                                <div class="loadingOverlay optimizedCheckout-overlay" style="display:none;"></div>
                                <ul class="form-checklist optimizedCheckout-form-checklist" style="">
                                    ${availableShippingOptions?.length > 0 ?
                                        `
                                        ${availableShippingOptions?.map(function(opt, idx) {
                                            return `
                                            <li class="form-checklist-item optimizedCheckout-form-checklist-item ${(selectedShippingOption == null && idx == 0) || selectedShippingOption?.id == opt.id ? 'optimizedCheckout-form-checklist-item--selected' : ''}">
                                                <div class="form-checklist-header${(selectedShippingOption == null && idx == 0) || selectedShippingOption?.id == opt.id ? '--selected' : ''}">
                                                    <div class="form-field">
                                                        <label class="form-label optimizedCheckout-form-label">
                                                            <div class="shippingOptionLabel">
                                                                <input type="radio" class="form-checklist-checkbox optimizedCheckout-form-checklist-checkbox" name="shippingOptionIds" value="${opt.id}" ${(selectedShippingOption == null && idx == 0) || selectedShippingOption?.id == opt.id ? 'checked' : ''}>
                                                                <div class="shippingOption shippingOption--alt">
                                                                    <span class="shippingOption-desc">${opt.description}</span>
                                                                    <span class="shippingOption-price">$${opt.cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                                                </div>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>`}).join("")
                                        }
                                    `: ``}
                                </ul>
                            </div>
                            <div class="loading-skeleton" ${thisShippingAddress?.firstName?.length > 0 && availableShippingOptions?.length > 0 ? `style="display: none;"` : ``}>
                                <div class="shippingOptions-panel optimizedCheckout-overlay">
                                    <p aria-live="polite" class="shippingOptions-panel-message optimizedCheckout-primaryContent"
                                        role="alert">Please enter a shipping address in order to see shipping quotes</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
    `;
}

export function renderPISForm(thisStoreID = null, elementID, lineItems = []){
    let selectedStoreIdInfo = thisStoreID ? bopis.allStores.find(store=>store.locationId == thisStoreID) : null;
    let OOSinPIS = false;
    let combinedStoreHours = [];
    if (thisStoreID && selectedStoreIdInfo) {
        combinedStoreHours = setStoreHours(selectedStoreIdInfo.operatingHours, selectedStoreIdInfo.locationId);
        OOSinPIS = lineItems.map(function(item){
            let productSKU = cartMetaData.cartProducts.find(product=>product.lineitemId == item).sku
            let productQTY = cartMetaData.cartProducts.find(product=>product.lineitemId == item).quantity
            let { stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup } = getItemStock(productSKU, thisStoreID)
            return stockCountInSelectedStore >= productQTY;
        }).indexOf(false) >= 0;
    }
    return `
        <div class="checkout-form-pickup" data-storeid="${thisStoreID || ''}">
            <div class="cnc__select-store-dropdown">
                <a class="change-selected-store" data-element-id="${elementID}">
                    <div class="icon">
                        ${ICONS.pinOutlinePink}
                    </div>
                    <label>${selectedStoreIdInfo ? 'Change Pickup Store' : 'Select Pickup Store'}</label>
                </a>
                ${selectedStoreIdInfo ? `
                <div class="selected-store-info">
                    <div class="store-text-info">
                        <p class="checkout-availability ${OOSinPIS ? `not-available`:`available`}">${OOSinPIS ? `Not Available`:`Available`}</p>
                        <p class="store-name">
                            ${selectedStoreIdInfo.storeName}
                        </p>
                        <p class="" store-address>
                            ${selectedStoreIdInfo.street?.length ? removeComma(selectedStoreIdInfo.street) + ", " : ""}${selectedStoreIdInfo.city}, ${selectedStoreIdInfo.state},
                            ${selectedStoreIdInfo.country}, ${selectedStoreIdInfo.zipCode}
                        </p>
                        <div class="operating-hours-section">
                            ${selectedStoreIdInfo.operatingHours && combinedStoreHours ?
                                `<h5>Store Hours:</h5>
                                ${combinedStoreHours?.map(day => {
                                    return `
                                        <div class="store-days">
                                            <div>
                                                <span>${day.day}:<span>
                                            </div>
                                        <div>
                                        ${day?.operatingHours?.length > 0 ?
                                            day?.operatingHours?.map(hour => {
                                                var fromTime = new Date('1970-01-01T' + hour.fromTime).toLocaleTimeString();
                                                var toTime = new Date('1970-01-01T' + hour.toTime)?.toLocaleTimeString();
                                                return `<p>${fromTime.split(fromTime.slice(-6, -2)).join('')} - ${toTime.split(toTime.slice(-6, -2)).join('')}</p>`
                                            }).join("")
                                        : `<p>Closed</p>`}
                                        </div>
                                    </div>`
                                }).join("")}` 
                            : ``}
                        </div>
                        ${selectedStoreIdInfo.phone ? `
                            <div class="store-phone-section">
                                <h5>Telephone number:</h5>
                                <div class="store-phone">
                                    <div>
                                        <a href="tel:${selectedStoreIdInfo.phone}">${selectedStoreIdInfo.phone}</a>
                                    </div>
                                </div>
                            </div>`
                    : ``}
                        ${selectedStoreIdInfo.emailAddress ? `
                            <div class="store-phone-section">
                                <h5>Email</h5>
                                <div class="store-phone">
                                    <div>
                                        <a href="mailto:${selectedStoreIdInfo.emailAddress}">${selectedStoreIdInfo.emailAddress}</a>
                                    </div>
                                </div>
                            </div>`
                    : ``}
                    </div>
                    <div class="store-map">
                        <iframe
                            width="100%"
                            height="250"
                            frameborder="0"
                            style="border:0"
                            referrerpolicy="no-referrer-when-downgrade"
                            src="https://www.google.com/maps/embed/v1/place/?key=${googleMapAPIKey}
                                    &q=${encodeURIComponent(selectedStoreIdInfo.storeName)}
                                    +${selectedStoreIdInfo.street}
                                    +${selectedStoreIdInfo.city}
                                    +${selectedStoreIdInfo.state}
                                    +${selectedStoreIdInfo.country}
                                    &center=${selectedStoreIdInfo.latitude},${selectedStoreIdInfo.longitude}&zoom=18"
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>`: ``}
            </div>
        </div>
        `;
}

export function renderLoadingReplica(){
    return `
    <div class="checkout-form-method" id="replica-loading-skeleton">
        <div class="address-form-skeleton">
            <div class="name-fields-skeleton">
                <div class="skeleton-container">
                    <div class="input-skeleton"></div>
                </div>
                <div class="skeleton-container">

                    <div class="input-skeleton"></div>
                </div>
            </div>
            <div class="skeleton-container">
                <div class="input-skeleton"></div>
            </div>
        </div>
    </div>`;
}

export function showLoadingNotification() {
    let loadingHTML = `
        <div class="loadingNotification custom-notification">
            <div class="loadingNotification-label optimizedCheckout-loadingToaster">
            <div class="spinner">
            </div>
            <span aria-live="assertive" class="label" role="alert">Loading</span>
            </div>
        </div>
    `;

    if (!$(".loadingNotification").length)
    $(".checkout-steps").before(loadingHTML);
    $('.split-shipping-row > .loadingOverlay').show();
}

export function hideLoadingNotification() {
    $(".loadingNotification.custom-notification").remove();
    $('.split-shipping-row > .loadingOverlay').hide();
}

export function showLoadingNotificationInShippingMethods(thisSplitShippingRow){
    if(splitShippingMethod == false) return;
    thisSplitShippingRow.find('#checkout-shipping-options .shippingOptions-container .shippingOptions-panel').hide();
    thisSplitShippingRow.find('#checkout-shipping-options .shippingOptions-container .loadingOverlay-container').show();
    thisSplitShippingRow.find('#checkout-shipping-options .shippingOptions-container .loadingOverlay-container .loadingOverlay.optimizedCheckout-overlay').show();
}

export function hideLoadingNotificationInShippingMethods(thisSplitShippingRow){
    if(splitShippingMethod == false) return;
    thisSplitShippingRow.find('#checkout-shipping-options .shippingOptions-container .shippingOptions-panel').show();
    thisSplitShippingRow.find('#checkout-shipping-options .shippingOptions-container .loadingOverlay-container').hide();
    thisSplitShippingRow.find('#checkout-shipping-options .loading-skeleton, #checkout-shipping-options .shippingOptions-panel.optimizedCheckout-overlay').show();
}

export function renderShippingMethods(availableShippingOptions, selectedShippingOption, thisSplitShippingRow){
    let ulShippingOPTs = thisSplitShippingRow.find('#checkout-shipping-options .shippingOptions-container .optimizedCheckout-form-checklist');
    ulShippingOPTs.find('li').remove();
    availableShippingOptions.forEach(function (shippingOpt, idx) {
        ulShippingOPTs.append(`
            <li class="form-checklist-item optimizedCheckout-form-checklist-item ${(selectedShippingOption == null && idx == 0) || selectedShippingOption?.id == shippingOpt.id ? `optimizedCheckout-form-checklist-item--selected`:``}">
                <div class="form-checklist-header ${(selectedShippingOption == null && idx == 0) || selectedShippingOption?.id == shippingOpt.id ? `form-checklist-header--selected`:``}">
                    <div class="form-field">
                        <label class="form-label optimizedCheckout-form-label">
                            <div class="shippingOptionLabel">
                                <input type="radio" class="form-checklist-checkbox optimizedCheckout-form-checklist-checkbox" name="shippingOptionIds" value="${shippingOpt.id}" ${(selectedShippingOption == null && idx == 0) || selectedShippingOption?.id == shippingOpt.id ? `checked=""`:``}>
                                <div class="shippingOption shippingOption--alt">
                                    <span class="shippingOption-desc">${shippingOpt.description}</span>
                                    <span class="shippingOption-price">$${shippingOpt.cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                                </div>
                            </div>
                        </label>
                    </div>
                </div>
            </li>
        `);
    });
    thisSplitShippingRow.find('#checkout-shipping-options .shippingOptions-container .loadingOverlay-container .loadingOverlay.optimizedCheckout-overlay').hide();
}

export function renderNoShippingMethods(thisSplitShippingRow){
    thisSplitShippingRow.find('#checkout-shipping-options .shippingOptions-container .shippingOptions-panel p').html('No shipping methods available in selected address.');
    thisSplitShippingRow.find('#checkout-shipping-options .shippingOptions-container .shippingOptions-panel').show();
    thisSplitShippingRow.find('#checkout-shipping-options .shippingOptions-container .loadingOverlay-container').hide();
}

export function insertAddressBook(){
    return new Promise(async function(resolve) {
        $('.custom__address-book .newly-added-address').remove();
        $('.custom__address-book').each(function(idx,elm){
            groupThisArrayBy( {Group: cartMetaData.checkout.consignments.filter(con=>con.shippingAddress.lastName !== 'Store'), By: ['shippingAddress']}).map(address => address[0].shippingAddress).forEach(consignment => {
                console.log(consignment, "consignment");
                $(elm).find('.dropdownMenu ul').append(`
                    <li class="dropdown-menu-item dropdown-menu-item--select newly-added-address">
                        <a href="#">
                            <div class="vcard checkout-address--static">
                                <p class="fn address-entry">
                                    <span class="firstName">${consignment.firstName}</span>
                                    <span class="lastName">${consignment.lastName}</span>
                                </p>
                                <p class="address-entry">
                                    <span class="company">${consignment.company}</span>
                                    <span class="phone">${consignment.phone}</span>
                                </p>
                                <div class="adr">
                                    <p class="street-address address-entry">
                                        <span class="address1">${consignment.address1}</span>
                                        <span class="address2">${consignment.address2}</span>
                                    </p>
                                    <p class="address-entry">
                                        <span class="city">${consignment.city}</span>
                                        <span>${consignment.stateOrProvince || consignment.stateOrProvinceCode}</span>
                                        <span class="postalCode">${consignment.postalCode}</span>
                                        <span class="countryCode">${consignment.country}</span>
                                        <span class="stateOrProvince stateOrProvinceCode hide">${consignment.stateOrProvince || consignment.stateOrProvinceCode}</span>
                                    </p>
                                </div>
                            </div>
                        </a>
                    </li>
                `)
            });
        });

        resolve();
    });
}
import { render } from "./utils";

export class Popup {
    settings;
    popupUniqueId;

    constructor() {
        this.popupUniqueId = this.generateUniqueId();
        $("body").append(`<div id="${this.popupUniqueId}" class="notice-popup"></div>`);
    }

    prepare(settings = {
        headerText: "",
        contentHTML: "",
        button1Text: "",
        button2Text: "",
        onButton1Click: null,
        onButton2Click: null,
        customClass: null,
    }) {
        this.settings = settings;

        let HtmlToRender = `
        <div class="notice-popup-underlay">
            <div class="popup-notice">
                ${settings.headerText.length > 0 ?
                `<div class="popup-title">
                        <h4>${settings.headerText}</h4>
                    </div>`: ``
            }
                

                <div class="popup-content">
                    ${settings.contentHTML}

                    ${settings.button1Text || settings.button2Text ? `
                        <div class="popup-buttons">
                            ${settings.button1Text ? `<button type="button" class="notice-btn" id="notice-btn-1">${settings.button1Text}</button>` : ""}
                        
                            ${settings.button2Text ? `<button type="button" class="notice-btn" id="notice-btn-2">${settings.button2Text}</button>` : ""}
                        </div>
                        ` : ""}
                </div>
            </div>
        </div>
        `;

        if (settings.onButton1Click) {
            $("body").on("click", `#${this.popupUniqueId} #notice-btn-1`, () => {
                settings.onButton1Click();
            });
        }

        if (settings.onButton2Click) {
            $("body").on("click", `#${this.popupUniqueId} #notice-btn-2`, () => {
                settings.onButton2Click();
            });
        }

        render("#" + this.popupUniqueId, HtmlToRender);
        if(settings.customClass) $(`#${this.popupUniqueId}`).addClass(settings.customClass);
    }

    open() {
        $(`#${this.popupUniqueId}`).fadeIn(250);
        $("body").css("overflow", "hidden");
        $("body").addClass("overflow-hidden");
    }
    
    close() {
        $(`#${this.popupUniqueId}`).fadeOut(250);
        $("body").css("overflow", "auto");
        $("body").removeClass("overflow-hidden");
    }

    remove() {
        $("body").css("overflow", "auto");
        $("body").removeClass("overflow-hidden");
        $(`#${this.popupUniqueId}`).fadeOut(250);
        $(`#${this.popupUniqueId}`).remove();
    }

    generateUniqueId() {
        return Math.random().toString(36).substring(2) +
            (new Date()).getTime().toString(36);
    }
}
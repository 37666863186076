
import { api_baseurl, sdd_api_baseurl, applicationId, staticCustomizations } from "../common/globalConstants";
import { isBrandPage, isCartPage, isCategoryPage, isCheckoutPage, isPDPPage, isSearchPage, transformObject } from "../common/utils";
import { storefrontServices } from "./bigCommerceServices";
import { graphQLServices } from "./graphQLServices";


export const getBOPISData = async () => {
    // bopisServiceLoaded = false;
    let BOPISReqData;
    window.bopis_response = null;

    BOPISReqData = {
        SKUs: [],
        selectedStoreId: selectedStoreId || null
    };

    if (isPDPPage()) {
        let graphQLResResult_ = await graphQLServices.getProductDataByIds([$('[name=product_id]').val()]);
        let graphQLResResult_min = await graphQLServices.getProductDataByIds([$('[name=product_id]').val()]);
        graphQLResResult_min = graphQLServices.graphQLToJavascript(graphQLResResult_min);

        PDPMetaData.selectedProduct.customFields = graphQLResResult_min[0].customFields;

        PDPMetaData.selectedProduct.pickupOnly = PDPMetaData.selectedProduct.customFields.find(field=>field.name.toLowerCase().indexOf(strictPISlabelName) >= 0 && field.value.toLowerCase() == strictPISlabelValue) ? true: false;

        if (PDPMetaData.productHasVariants == true) {

            PDPMetaData.productVariants = transformObject(graphQLResResult_.data.site.products.edges[0].node.variants.edges);

            BOPISReqData.SKUs = graphQLResResult_min[0].variants.map(graph => {
                return { SKU: graph.sku, quantity: 1 }
            });

        } else {
            BOPISReqData.SKUs.push({
                SKU: $("[data-product-sku]").text(),
                quantity: $('.form-input--incrementTotal').val()
            });
        }
    }

    if (isCategoryPage() || isBrandPage() || isSearchPage()) {
        const $allCardElements = $(`[data-test] [data-sku]`);

        if($allCardElements.length == 0){
            BOPISReqData = {
                SKUs: [],
                selectedStoreId: selectedStoreId || null
            };
        }else{
            let newSKU = [];
            let newID = [];
            $allCardElements.each(function(elem, idx){
                newSKU.push($(idx).attr('data-sku'))
                newID.push($(idx).attr('product-id'));
            });
            let differenceSKU = window.productSKUS.filter(x => !newSKU.includes(x)).concat(newSKU.filter(x => !window.productSKUS.includes(x)));
            let differenceID = window.productIDS.filter(x => !newID.includes(x)).concat(newID.filter(x => !window.productIDS.includes(x)));
    
            if(differenceSKU.length == 0) return; //not call multiple location API

            let newProductsInfo = await graphQLServices.getProductDataByIds(differenceID);

            newProductsInfo = graphQLServices.graphQLToJavascript(newProductsInfo);

            PLPMetaData.products.push(...newProductsInfo);
    
            BOPISReqData.SKUs = differenceSKU.length > 0 ?  differenceSKU.map(function(sku){
                return {
                    SKU: sku, 
                    quantity: 1
                }
            }) : window.productSKUS.map(function(sku){
                return {
                    SKU: sku, 
                    quantity: 1
                }
            })
    
            window.productSKUS = newSKU;
        }


    }

    if (isCartPage() || isCheckoutPage()) {
        let cartItemDetails = await storefrontServices.getCart();

        if (cartItemDetails.length == 0) {
            BOPISReqData.SKUs = [];
        } else {
            BOPISReqData.SKUs = cartItemDetails[0]?.lineItems.physicalItems?.map(item => {
                return { SKU: item.sku, quantity: item.quantity.toString() }
            });
        }
    }

    return new Promise((resolve, reject) => {
        $.post({
            url: `${api_baseurl}location`,
            contentType: "application/json; charset=utf-8",
            data: JSON.stringify(BOPISReqData),
            headers: {
                "x-randem-application-id": applicationId
            }
        }).then((bopisRes) => {
            bopis = bopisRes;
            if(bopis.perSKU && bopisRes.perSKU.length > 0){
                bopis.perSKU.forEach(function(sku){
                    sku.quantityNoThreshold = (sku.quantity) < 0 ? 0 : sku.quantity;
                    sku.quantity = (sku.quantity - productThreshold) < 0 ? 0 : sku.quantity - productThreshold;
                });
            }
            bopis.Customizations = staticCustomizations;
            // bopisServiceLoaded = true;
            resolve(bopis);
        });
    });
}

export const checkSDDZone = async (address) => {
    let Skus = '';
    if(isPDPPage()){
        Skus = `&Skus=${PDPMetaData.selectedProduct.SKU}`;
    }
    return new Promise((resolve, reject) => {
        $.post({
            url: sdd_api_baseurl + `?destination=${address}&IsSameDayDelivery=true&radius=${SDDRadius}&measure=mi${Skus}`,
            contentType: "application/json; charset=utf-8",
            headers: {
                "x-randem-application-id": applicationId
            }
        }).then((bopisRes) => {
            bopis.SDD = bopisRes;
            // bopisServiceLoaded = true;
            resolve(bopis);
        });
    });
}
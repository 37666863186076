import { setStore } from "../globalPage/globalpageData";
import { setPDPMetaData } from "./productDetailsPageData";

const { isPDPPage } = require("../common/utils");
const { bindPDPEvents, handlePDPAddToCart, PDPsetVariants, handlePDPDataForSchema } = require("./productDetailsPageListeners");
const { renderPDPClickAndCollect } = require("./productDetailsPageRenderer");

export const initPDP = {
    prepPDP: function () {
        if (!isPDPPage())
            return;
        $('.productView-info-value').first().addClass('product-sku');
        handlePDPAddToCart();
        renderPDPClickAndCollect();
        bindPDPEvents();
        setPDPMetaData();
    },
    loadPDP: async function () {
        if (!isPDPPage())
            return;

        if (selectedStoreId) {
            await setStore(selectedStoreId, true, true);
        }

        handlePDPAddToCart();
        renderPDPClickAndCollect();
        handlePDPDataForSchema();
        // PDPsetVariants();
    }
}


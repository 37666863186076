import { staticCustomizations } from "../common/globalConstants";
import { ICONS } from "../common/icons";
import { getItemAvailability, isCartPage, getItemStock, getCookie } from "../common/utils";

export const renderCartItemAvailability = () => {
    if (!isCartPage()) return;
    let preferredCheckoutMethod = getCookie("checkoutMethod");
    $(".cnc__cart-item-availability-wrapper").remove();
    if($(".cartContent__div-right .cnc__checkout-method").length == 0){
        $(".cartContent__div-right").prepend(`
            <div class="cnc__checkout-method">
                <div class="shipping-option ${preferredCheckoutMethod && preferredCheckoutMethod == 'pickup' ? ``:`selected`}">
                    <label>
                        <input type="radio" class="cnc__cart-page-checkout-selection" name="pickup-shipping-option" value="shipping" ${preferredCheckoutMethod && preferredCheckoutMethod == 'pickup' ? ``:`checked="checked"`}>
                        Home Delivery
                    </label>
                </div>
                <div class="shipping-option ${preferredCheckoutMethod && preferredCheckoutMethod == 'pickup' ? `selected`:``}">
                    <label>
                        <input type="radio" class="cnc__cart-page-checkout-selection" name="pickup-shipping-option" value="pickup" ${preferredCheckoutMethod && preferredCheckoutMethod == 'pickup' ? `checked="checked"`:``}>
                        Click &amp; Collect
                    </label>
                </div>
            </div>
        `);
    }

    let HtmlToRender = ``;

    if (!cartMetaData.cartProducts.length) {
        $('.cart-actions .button--primary').addClass('disabled');
        HtmlToRender = ` 
        <div class="placeholder_content cnc__cart-item-availability-wrapper">
            <div class="placeholder_item"></div>
        </div>`;

        $('.cart-actions').prepend(`
            <div class="cs-address-loader-hldr"> 
                <div class="cs-address-loader"></div> 
            </div>
        `);
    } else {
        $('.cart-actions .cs-address-loader-hldr').remove();
        $(".cnc__cart-item-availability-wrapper").remove();
        const selectedStoreIdInfo = bopis?.allStores?.find(store => store?.locationId == selectedStoreId);
        cartMetaData.isNotAvailableForDelivery = false;
        cartMetaData.isNotAvailableForPIS = false;
        cartMetaData.cartProducts.forEach(product => {
            let { availableForShipping, availableForPickup, availableInWarehouse} = getItemAvailability(product.sku, product.quantity, selectedStoreId, product.price)
            availableInWarehouse = product.isPickupOnly == true ? false : availableInWarehouse;
            if(availableInWarehouse == false){
                cartMetaData.isNotAvailableForDelivery = true;
            }
            if(selectedStoreId && availableForPickup == false){
                cartMetaData.isNotAvailableForPIS = true;
            }
            let htmlForShipping = availableInWarehouse ? `<p class="cnc__shipping-availability cnc__color-available">${staticCustomizations.CartAvailInDel}</p>` : `<p class="cnc__shipping-availability cnc__color-unavailable">${staticCustomizations.CartOOSInDel}</p>`;
            HtmlToRender = `
                <div class="cnc__cart-item-availability-wrapper">
                ${selectedStoreId ? availableForPickup
                    ? htmlForShipping + `<p class="cnc__pickup-availability cnc__color-available">${staticCustomizations.CartAvailInPIS} in ${selectedStoreIdInfo?.storeName} Store</p>`
                    : htmlForShipping + `<p class="cnc__pickup-availability cnc__color-unavailable">${staticCustomizations.CartOOSInPIS} ${selectedStoreIdInfo?.storeName} Store</p>`
                    : htmlForShipping}
                </div>
            `;
            $(`[data-cart-itemid="${product.lineitemId}"]`).parents('tr').find('.cnc__cart-item-availability-wrapper').remove();
            $(`[data-cart-itemid="${product.lineitemId}"]`).parents('tr').find('.cart-item-name').before(HtmlToRender);
            if(product.isPickupOnly == true){
                $(`[data-cart-itemid="${product.lineitemId}"]`).parents('tr').find('.cnc__product-restrict').remove();
                $(`[data-cart-itemid="${product.lineitemId}"]`).parents('tr').find('.cart-item-name').append(`
                    <p class="cnc__product-restrict">Click & Collect Only</p>`);
            }

            if(splitShippingMethod){
                SelectedSplitShippingMethods = JSON.parse(localStorage.getItem('SelectedSplitShippingMethods')) || [];
                let thisSelectedShippingMethod = SelectedSplitShippingMethods.find((item) => item?.lineItem === product.lineitemId);
                let thisSelectedMethodInCart = thisSelectedShippingMethod ? thisSelectedShippingMethod.method : null;
                let thisDeliveryAddress = thisSelectedShippingMethod?.deliveryAddress || null;
                let pickupStore = {};
                let pickupStoreID = '';
                let availableInStoreFromCookies = availableForPickup;
                let { stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup } = getItemStock(product.sku);
                if(thisSelectedMethodInCart == 'pickup'){
                    pickupStoreID = thisSelectedShippingMethod?.storeid || null;
                    if(pickupStoreID && pickupStoreID.length > 0){
                        pickupStore = bopis?.allStores?.find(store => store?.locationId == pickupStoreID);
                        let { availableForShipping, availableForPickup, availableInWarehouse} = getItemAvailability(product.sku, product.quantity, pickupStoreID, product.price);
                        availableInStoreFromCookies = availableForPickup;
                    }
                }

                $(`[data-cart-itemid="${product.lineitemId}"]`).parents('tr').find('.cnc__cartpage-smethod').remove();
                $(`[data-cart-itemid="${product.lineitemId}"]`).parents('tr').find('.cart-item-name').after(`
                    <div class="cnc__cartpage-smethod" data-itemid="${product.lineitemId}" data-sku="${product.sku}" data-line-id="${product.lineitemId}" data-line-qty="${product.quantity}">
                        <div class="loadingOverlay" style="display: none;"></div>
                        <p>Checkout Method:</p>
                        <div class="shipping-option ${thisSelectedMethodInCart == 'shipping' ? `selected`:``} ${availableInWarehouse == false ? `disabled`:``}">
                            <label>
                                <input type="radio" class="cnc__cart-page-checkout-selection" name="pickup-shipping-option-${product.lineitemId}" value="shipping" ${thisSelectedMethodInCart == 'shipping' ? `checked=""`:``}>
                                Home Delivery
                            </label>
                            <div class="item-availability cnc__color-available">
                                ${(thisSelectedMethodInCart && thisSelectedMethodInCart !== 'pickup') && availableInWarehouse == true && thisDeliveryAddress ? `
                                <p class="delivery-address">
                                    <b>${thisDeliveryAddress.firstName} ${thisDeliveryAddress.lastName}</b>
                                    ${thisDeliveryAddress.address1} ${thisDeliveryAddress.address2}
                                    ${thisDeliveryAddress.city}, ${thisDeliveryAddress.stateOrProvince || thisDeliveryAddress.stateOrProvinceCode} ${thisDeliveryAddress.countryCode}, ${thisDeliveryAddress.postalCode}
                                </p>
                                `:``}
                                <p class="set-address-cart-page" data-address='${(thisSelectedMethodInCart && thisSelectedMethodInCart !== 'pickup') ? JSON.stringify(thisDeliveryAddress) : null}'>${availableInWarehouse == false ? `Not Available for Shipping`:`(Click here to ${(thisSelectedMethodInCart && thisSelectedMethodInCart !== 'pickup') && thisDeliveryAddress ? `change`:`set`} Delivery Address)`}</p>
                            </div>
                        </div>
                        <div class="shipping-option ${thisSelectedMethodInCart == 'pickup' ? `selected`:``} ${stockForPickup >=product.quantity ? ``:`disabled`}">
                            <label>
                                <input type="radio" class="cnc__cart-page-checkout-selection" name="pickup-shipping-option-${product.lineitemId}" value="pickup" ${thisSelectedMethodInCart == 'pickup' ? `checked=""`:``}>
                                Collect In Store <p class="hide free-label">FREE! ${stockForPickup}</p>
                            </label>
                            
                                <div class="item-availability cnc__color-available">
                                    <p class="selected-store-info">
                                    ${stockForPickup >=product.quantity && pickupStoreID ? 
                                        availableInStoreFromCookies == true ? 
                                             `Available for Pickup in <b>${pickupStore?.storeName}</b> Store`
                                            :  `Not Available for Pickup in <b>${pickupStore?.storeName}</b> Store`
                                        : ``
                                    }
                                    </p>
                                    
                                    <p class="change-store-cart-page">${stockForPickup >=product.quantity ? `(Click here to ${pickupStoreID ? `change` : `select`} a store)`:`No Available Pickup Store`}</p>
                                <div>
                        </div>
                    </div>
                `)
            }

        });

        $('.cart-actions .cnc__cart-availability').remove();
        let myStore = selectedStoreId ? bopis.allStores.find(store=> store.locationId == selectedStoreId) :null;
        
        $('.cart-actions .button--primary').removeClass('disabled');
        if(preferredCheckoutMethod == 'pickup' && (cartMetaData.isNotAvailableForPIS == true || !myStore)){
            $('.cart-actions .button--primary').addClass('disabled');
        }

        if(preferredCheckoutMethod !== 'pickup' && cartMetaData.isNotAvailableForDelivery == true){
            $('.cart-actions .button--primary').addClass('disabled');
        }

        $('.cart-actions').prepend(`
            ${preferredCheckoutMethod == 'pickup' ? 
            `
                <div class="cnc__cart-availability ${cartMetaData.isNotAvailableForPIS == true ? `not-available`:`${!myStore?`not-available`:`available`}`}">
                    <div class="icon-availability ${cartMetaData.isNotAvailableForPIS == true ? `not-available`:`${!myStore?`not-available`:`available`}`}">
                        ${!myStore || cartMetaData.isNotAvailableForPIS == true ? 
                            ICONS.warning
                            :ICONS.available
                        }
                        ${cartMetaData.isNotAvailableForPIS == true ? `<p>Not Available Items</p>`:`${!myStore?`<p>No Store Selected</p>`:``}`}
                    </div> 
                    <p class="${!myStore ? `no-store-selected`:``}">
                    ${!myStore || cartMetaData.isNotAvailableForPIS == true ?
                        !myStore ? `Select Store`: cartMetaData.isNotAvailableForPIS == true ? `Some items are Not Available for Pickup in <b>${myStore.storeName}</b>. <a class="change-selected-store">Change Store</a>`:``
                    :`Collecting from <b>${myStore.storeName}</b> <a class="change-selected-store">Change Store</a>`}
                    
                    </p>
                </div>
            `
            :
            `
                <div class="cnc__cart-availability ${cartMetaData.isNotAvailableForDelivery == true ? `not-available`:`available-sfs`}">
                    <div class="icon-availability ${cartMetaData.isNotAvailableForDelivery == true ? `not-available`:``}">
                        ${cartMetaData.isNotAvailableForDelivery == true ? 
                            ICONS.warning
                            :ICONS.available
                        }
                        ${cartMetaData.isNotAvailableForDelivery == true ? `<p>Not Available Items</p>`:``}
                    </div> 
                    <p>${cartMetaData.isNotAvailableForDelivery == true ? 
                        `Please remove the items that are <b>Not Available for Home Delivery</b> before you check out`
                        :`Available for Home Delivery`
                    }</p>
                </div>
            `}
        `);

        if(preferredCheckoutMethod == 'pickup'){
            renderShippingCostForPIS();
        }
    }
}

export const renderShippingCostForPIS = (checkoutInfo = cartMetaData.checkout) => {
    if($('li.cart-total:nth-child(3) .pis-cost').length == 0) $('li.cart-total:nth-child(3) .cart-total-value').append(`<span class="pis-cost">${cartMetaData.cart.currency.symbol}0</span>`);
    else $('li.cart-total:nth-child(3) .pis-cost').show();
    $('li.cart-total:nth-child(3)').find(`[data-collapsible="add-shipping"],.subtotal.shipping-estimate-show`).hide();
    if(checkoutInfo.shippingCostTotal > 0){
        $('.cart-total-grandTotal span').hide();
        if($('.cart-total-grandTotal .remove-shippingCost').length == 0) $(`<span class="remove-shippingCost">${cartMetaData.cart.currency.symbol}${(checkoutInfo.grandTotal - checkoutInfo.shippingCostTotal).toFixed(2)}</span>`).insertAfter('.cart-total-grandTotal span');
        else $('.cart-total-grandTotal .remove-shippingCost').show();
    }
}

export const renderStoreList = (thisLineID, thisSKU, stores = bopis?.allStores, targetElement = null) => {
    if(thisLineID.length == 0){
        return stores?.filter(store=>store.pickupAllowed)?.map((store, indexStore) => {
            let inStock = (cartMetaData.cartProducts.filter(product=>product.lineitemId == targetElement || product.consignmentID == targetElement).map(function(sku){ return bopis.perSKU.find(perSKU=>perSKU.sku == sku.sku && perSKU.quantity >= sku.quantity && perSKU.storeId == store.locationId) !== undefined}).indexOf(false) >=0) == false;
            return `
            <li class="store">
                <p class="checkout-availability ${inStock ? `available`:`not-available`}">${inStock ? `Available`:`Not Available`} </p>
                <h5>${store.storeName}</h5>
                <div class="cnc__pref-checkout-store-actions">
                    <p class="store-address">
                        ${store?.street}
                        ${store?.city}, ${store?.state}, ${store?.country}, ${store?.zipCode}
                        <span>Contact: <a href="tel:${store?.phone}">${store?.phone}</a></span>
                    </p>
                    ${inStock ? `<button class="cnc__pref-checkout-set-store-btn" this-lineitem="${targetElement}" this-sku="${thisSKU}" store-id="${store.locationId}">Set As Pickup Store</button>`:`<button class="cnc__pref-checkout-set-store-btn disable">Not Available</button>`}
                </div>
            </li>`;
        }).join("") || `<li class="store"><p>No Store Found</p></li>`;
    }
    
    let qtyPurchase = cartMetaData.cartProducts.find(sku=>sku.sku == thisSKU).quantity;
    return stores?.filter(store=>store.pickupAllowed)?.map((store, indexStore) => {
        let localStock = bopis?.perSKU?.find(per => per.storeId == store?.locationId && per.sku === thisSKU && per.productAllowPickup && per.locationAllowPickup)?.quantity || 0;
        return `
        <li class="store">
            <p>${localStock} Available</p>
            <h5>${store.storeName}</h5>
            <div class="cnc__pref-checkout-store-actions">
                <p class="store-address">
                    ${store?.street}
                    ${store?.city}, ${store?.state}, ${store?.country}, ${store?.zipCode}
                    <span>Contact: <a href="tel:${store?.phone}">${store?.phone}</a></span>
                </p>
                ${localStock == 0 || qtyPurchase > localStock ? `<button class="cnc__pref-checkout-set-store-btn disable">Not Available</button>`:`<button class="cnc__pref-checkout-set-store-btn" this-lineitem="${thisLineID}" this-sku="${thisSKU}" store-id="${store.locationId}">Set As Pickup Store</button>`}
                
            </div>
        </li>`;
        // localStock == 0 || qtyPurchase > localStock
    }).join("") || `<li class="store"><p>No Store Found</p></li>`;
}

export const renderStoreListCheckout = (lineItemsArray, skuArray, stores = bopis?.allStores, targetElement) => {
    return stores?.filter(store=>store.pickupAllowed)?.map((store, indexStore) => {
        let inStock = (skuArray.map(function(sku){return bopis?.perSKU?.filter(perSKU => perSKU.sku == sku.sku && perSKU.storeId == store.locationId && perSKU.quantity >= sku.quantity).length > 0}).indexOf(false) >= 0) == false;

        return `
        <li class="store">
            <p class="checkout-availability ${inStock ? `available`:`not-available`}">${inStock ? `Available`:`Not Available`} </p>
            <h5>${store.storeName}</h5>
            <div class="cnc__pref-checkout-store-actions">
                <p class="store-address">
                    ${store?.street}
                    ${store?.city}, ${store?.state}, ${store?.country}, ${store?.zipCode}
                    <span>Contact: <a href="tel:${store?.phone}">${store?.phone}</a></span>
                </p>
                ${inStock ? `<button class="cnc__pref-checkout-set-store-btn" this-lineitem='${JSON.stringify(lineItemsArray)}' store-id="${store.locationId}">Set As Pickup Store</button>`:`<button class="cnc__pref-checkout-set-store-btn disable">Not Available</button>`}
            </div>
        </li>`;
    }).join("") || `<li class="store"><p>No Store Found</p></li>`;
}

export const renderCheckoutAddress = (thisDeliveryAddress) => {
    return `
    <div class="checkout-address">
        <div class="dynamic-form-field floating-form-field dynamic-form-field--firstName">
            <div class="form-field">
                <label for="firstNameInput" id="firstNameInput-label" class="floating-label form-label optimizedCheckout-form-label">First Name</label>
                <input required="" aria-labelledby="firstNameInput-label firstNameInput-field-error-message" autocomplete="given-name" id="firstNameInput" type="text" class="form-input optimizedCheckout-form-input floating-input" name="shippingAddress.firstName" data-test="firstNameInput-text" placeholder="" value="${thisDeliveryAddress?.firstName || ''}">
            </div>
        </div>
        <div class="dynamic-form-field floating-form-field dynamic-form-field--lastName">
            <div class="form-field">
                <label for="lastNameInput" id="lastNameInput-label" class="floating-label form-label optimizedCheckout-form-label">Last Name</label>
                <input required="" aria-labelledby="lastNameInput-label lastNameInput-field-error-message" autocomplete="family-name" id="lastNameInput" type="text" class="form-input optimizedCheckout-form-input floating-input" name="shippingAddress.lastName" data-test="lastNameInput-text" placeholder="" value="${thisDeliveryAddress?.lastName || ''}">
            </div>
        </div>
        <div class="dynamic-form-field floating-form-field dynamic-form-field--company">
            <div class="form-field">
                <label for="companyInput" id="companyInput-label" class="floating-label form-label optimizedCheckout-form-label">Company Name <small class="optimizedCheckout-contentSecondary">(Optional)</small></label>
                <input aria-labelledby="companyInput-label companyInput-field-error-message" autocomplete="organization" id="companyInput" type="text" class="form-input optimizedCheckout-form-input floating-input" name="shippingAddress.company" data-test="companyInput-text" placeholder="" value="${thisDeliveryAddress?.company || ''}">
            </div>
        </div>
        <div class="dynamic-form-field floating-form-field dynamic-form-field--phone">
            <div class="form-field">
                <label for="phoneInput" id="phoneInput-label" class="floating-label form-label optimizedCheckout-form-label">Phone Number </label>
                <input required="" aria-labelledby="phoneInput-label phoneInput-field-error-message" autocomplete="tel" id="phoneInput" type="tel" class="form-input optimizedCheckout-form-input floating-input" name="shippingAddress.phone" data-test="phoneInput-text" placeholder="" value="${thisDeliveryAddress?.phone || ''}">
            </div>
        </div>
        <div class="dynamic-form-field floating-form-field dynamic-form-field--addressLine1">
            <div class="form-field">
                <label for="addressLine1Input" id="addressLine1Input-label" class="floating-label form-label optimizedCheckout-form-label">Address</label>
                <input required="" aria-labelledby="addressLine1Input-label addressLine1Input-field-error-message" autocomplete="address-line1" id="addressLine1Input" type="text" class="form-input optimizedCheckout-form-input floating-input" name="shippingAddress.address1" data-test="addressLine1Input-text" placeholder="" value="${thisDeliveryAddress?.address1 || ''}">
            </div>
        </div>
        <div class="dynamic-form-field floating-form-field dynamic-form-field--addressLine2">
            <div class="form-field">
                <label for="addressLine2Input" id="addressLine2Input-label" class="floating-label form-label optimizedCheckout-form-label">Apartment/Suite/Building
                <small class="optimizedCheckout-contentSecondary">(Optional)</small></label>
                <input aria-labelledby="addressLine2Input-label addressLine2Input-field-error-message" autocomplete="address-line2" id="addressLine2Input" type="text" class="form-input optimizedCheckout-form-input floating-input" name="shippingAddress.address2" data-test="addressLine2Input-text" placeholder="" value="${thisDeliveryAddress?.address2 || ''}">
            </div>
        </div>
        <div class="dynamic-form-field floating-form-field dynamic-form-field--city">
            <div class="form-field">
                <label for="cityInput" id="cityInput-label" class="floating-label form-label optimizedCheckout-form-label">City</label>
                <input required="" aria-labelledby="cityInput-label cityInput-field-error-message" autocomplete="address-level2" id="cityInput" type="text" class="form-input optimizedCheckout-form-input floating-input" name="shippingAddress.city" data-test="cityInput-text" placeholder="" value="${thisDeliveryAddress?.city || ''}">
            </div>
        </div>
        <div class="dynamic-form-field floating-form-field dynamic-form-field--countryCode">
            <div class="form-field">
                <label for="countryCodeInput" id="countryCodeInput-label" class="floating-label form-label optimizedCheckout-form-label">Country</label>
                <select required="" aria-labelledby="countryCodeInput-label countryCodeInput-field-error-message" autocomplete="country" class="floating-select form-select optimizedCheckout-form-select" data-test="countryCodeInput-select" id="countryCodeInput" name="shippingAddress.countryCode">
                    <option value="">Select a country</option>
                    ${cartMetaData.shippingCountries?.length > 0 ? 
                        cartMetaData.shippingCountries.map(function(country){ 
                            return `<option value="${country.code}" ${country.code == thisDeliveryAddress?.countryCode ? `selected=""`:``}>${country.name}</option>`
                        }).join("")
                        :`<option value="AU">Australia</option>`}
                </select>
            </div>
        </div>
        <div class="dynamic-form-field floating-form-field dynamic-form-field--provinceCode" required-state="${cartMetaData.shippingCountries.find(country=>country.code == thisDeliveryAddress?.countryCode)?.requiresState}">
                <div class="form-field" for="provinceCodeSelect" ${thisDeliveryAddress?.countryCode == undefined || cartMetaData.shippingCountries.find(country=>country.code == thisDeliveryAddress?.countryCode)?.subdivisions.length == 0 ? `style="display:none"`:``}>
                    <label for="provinceCodeSelect" class="floating-label form-label optimizedCheckout-form-label">State/Province</label>
                    <select ${cartMetaData.shippingCountries.find(country=>country.code == thisDeliveryAddress?.countryCode)?.requiresState ? `required=""`:``} aria-labelledby="provinceCodeInput-label provinceCodeInput-field-error-message"
                    autocomplete="address-level1" class="floating-select form-select optimizedCheckout-form-select"
                    data-test="provinceCodeInput-select" id="provinceCodeInput" name="shippingAddress.stateOrProvinceCode">
                        <option value="">Select a state</option>
                        ${cartMetaData.shippingCountries.find(country=>country.code == thisDeliveryAddress?.countryCode)?.subdivisions.map(function(province){
                            return `<option value="${province.code}" ${province.code == thisDeliveryAddress?.stateOrProvinceCode ? `selected=""`:``}>${province.name}</option>`
                        }).join("")}
                    </select>
                </div>
                <div class="form-field" for="provinceCodeText" ${cartMetaData.shippingCountries.find(country=>country.code == thisDeliveryAddress?.countryCode)?.subdivisions.length > 0 ? `style="display:none"`:``}>
                    <label for="provinceCodeText" class="floating-label form-label optimizedCheckout-form-label">State/Province</label>
                    <input aria-labelledby="provinceInput-label provinceInput-field-error-message" autocomplete="address-level1" id="provinceInput" type="text" class="form-input optimizedCheckout-form-input floating-input" name="shippingAddress.stateOrProvince" data-test="provinceInput-text" placeholder="" value="${thisDeliveryAddress?.stateOrProvinceCode || thisDeliveryAddress?.stateOrProvince || ''}">
                </div>    
            </div>
        <div class="dynamic-form-field floating-form-field dynamic-form-field--postCode">
            <div class="form-field">
                <label for="postCodeInput" id="postCodeInput-label" class="floating-label form-label optimizedCheckout-form-label">Postal Code</label>
                <input required="" aria-labelledby="postCodeInput-label postCodeInput-field-error-message" autocomplete="postal-code" id="postCodeInput" type="text" class="form-input optimizedCheckout-form-input floating-input" name="shippingAddress.postalCode" data-test="postCodeInput-text" placeholder="" value="${thisDeliveryAddress?.postalCode || ''}">
            </div>
        </div>
    </div>`;
}
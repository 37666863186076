import { getCookie, redirectToCartPage } from "../common/utils";
import { storefrontServices } from "../services/bigCommerceServices";

export async function setCheckoutMetaData() {
    var cartItemDetails = await storefrontServices.getCart();
    cartItemDetails = cartItemDetails[0];
    checkoutMetaData.unavailablePISItems = [];
    checkoutMetaData.cart = cartItemDetails;
    checkoutMetaData.products = cartItemDetails?.lineItems.physicalItems?.map(item => {
        return { sku: item.sku, quantity: item.quantity, name: item.name }
    });

    checkoutMetaData.productsWithItemID = cartItemDetails?.lineItems.physicalItems?.map(item => {
        return { itemId: item.id, quantity: item.quantity }
    });

    let checkoutInfo = await storefrontServices.getCheckOutInformation(cartItemDetails.id);
    checkoutMetaData.channelID = checkoutInfo.channelId;
}

export async function setShippingMethod() {
    return new Promise(async resolve => {
        let preferredCheckoutMethod = getCookie("checkoutMethod") || "shipping";

        if (preferredCheckoutMethod?.length > 0) {
            if ($('.cs-address-loader-hldr').length == 0) {
                $(`<div class="cs-address-loader-hldr"> <div class="cs-address-loader"></div> </div>`).insertAfter($('.checkout-step--shipping .checkout-view-content .cnc__shipping-types'))
            }
            $('.cs-address-loader-hldr').show();
            $('.checkout-step--shipping .checkout-form').addClass('hide');
            $('.checkout-form-pickup').addClass('hide');

        }

        // await storefrontServices.removeConsignment();

        if (isDeliveryMethodEnabled == true && preferredCheckoutMethod == 'shipping') {
            $('.checkout-step--shipping .checkout-form').removeClass('hide');
            $('.checkout-step--shipping .checkout-form').show();
            $('.checkout-form-pickup').addClass('hide');
            $('.checkout-form-sdd').addClass('hide');
            $('.cs-address-loader-hldr').hide();
            let pickupOnlyProducts = cartMetaData.cartProducts.filter(product=>product.isPickupOnly == true || product.stockCountInWarehouse < product.quantity);
            if(pickupOnlyProducts.length > 0){
                checkoutUnavailabilityPopup.prepare({
                    headerText: "Not Available",
                    contentHTML: `
                    <p>Some items Not Available for Home Delivery: </p>
                    <div class="not-eligible-hldr">
                        <div class="not-eligible">
                            <ul>${pickupOnlyProducts.map(product=>`<li>${product.name}</li>`).join("")}</ul>
                        </div>
                    </div>`,
                    button1Text: "Select Pickup In Store",
                    button2Text: "Go to Cart",
                    onButton1Click: () => {
                        $('#cnc__shipping-pickup').trigger('click');
                        checkoutUnavailabilityPopup.close();
                    },
                    onButton2Click: () => {
                        redirectToCartPage();
                    },
                });
        
                checkoutUnavailabilityPopup.open();
                await storefrontServices.removeConsignment();
            }
        }

        if (isPISMethodEnabled == true && preferredCheckoutMethod == 'pickup') {
            $('.checkout-form-pickup').removeClass('hide');
            $('.checkout-form-pickup').show();
            $('.checkout-form-pickup').fadeIn();
            $('.checkout-step--shipping .checkout-form').addClass('hide');
            $('.checkout-form-sdd').addClass('hide');
            $('.cs-address-loader-hldr').hide();
        }

        resolve();
    })
}

export function emptyShippingAddress() {
    const $shippingFormSelects = $(".checkout-step--shipping form select");
    const $shippingFormInputs = $(".checkout-step--shipping form input");

    if($(".checkout-step--shipping form input#lastNameInput").val() == 'Store'){
        $shippingFormSelects.each((selectIndex, select) => {
            $(select).val("");
            checkoutObserver.changeSelectValue(select, "")
        });
    
        $shippingFormInputs.each((inputIndex, input) => {
            $(input).val("");
            checkoutObserver.changeValue($(input).get(0), '')
        });
    }

}

export async function setStoreDetailsAsShippingDetails(selectedPickupStore) {
    return new Promise(async resolve => {
        $('#cnc__fake-checkout-shipping-continue').addClass('is-loading').attr('style', 'pointer-events:none;');
        let cartItemDetails = checkoutMetaData.cart;
        const orderCommentsFromSession = sessionStorage.getItem("orderComments") || null;
        var products = [];

        products = cartItemDetails?.lineItems.physicalItems?.map(item => {
            return { itemId: item.id, quantity: item.quantity }
        });
        selectedPickupStore = bopis.allStores?.find(store => store?.locationId === selectedStoreId);
        let checkoutDetails = await storefrontServices.getCheckOutInformation(cartItemDetails.id);

        let orderComments = $(`[name="orderComment"]`).val();

        if (orderComments?.length) {
            await storefrontServices.updateCustomerMessage(cartItemDetails.id, {
                "customerMessage": orderComments
            })
        } else {
            if (orderCommentsFromSession?.length) {
                sessionStorage.removeItem("orderComments");
                await storefrontServices.updateCustomerMessage(cartItemDetails.id, {
                    "customerMessage": ""
                })
            }
        }

        var data = {
            "address": {
                "address1": selectedPickupStore.street?.replace(",", "")?.trim() || "",
                "address2": selectedPickupStore.storeName + " Store",
                "city": selectedPickupStore.city,
                "company": "",
                "country": selectedPickupStore.country,
                "countryCode": $('#countryCodeInput').find('option').filter(function () { return $(this).html().toLowerCase() == selectedPickupStore.country.toLowerCase(); }).val(),
                "firstName": selectedPickupStore.storeName,
                "lastName": 'Store',
                "phone": selectedPickupStore.phone,
                "postalCode": selectedPickupStore.zipCode,
                "shouldSaveAddress": false,
                "stateOrProvince": selectedPickupStore.state
            },
            "lineItems": products
        }

        if (pickupStoreFieldID.length > 0) {
            data.address.customFields ? data.address.customFields.push({
                "fieldId": pickupStoreFieldID,
                "fieldValue": selectedStoreId
            }) : data.address.customFields = [{
                "fieldId": pickupStoreFieldID,
                "fieldValue": selectedStoreId
            }];
        }

        if (allowSplitShippingFieldID.length > 0) {
            data.address.customFields ? data.address.customFields.push({
                "fieldId": allowSplitShippingFieldID,
                "fieldValue": 'Yes'
            }) : data.address.customFields = [{
                "fieldId": allowSplitShippingFieldID,
                "fieldValue": 'Yes'
            }];
        }

        if (isDatePickerEnabled == true) {
            data.address.customFields ? data.address.customFields.push({
                "fieldId": pickupTimeslotFieldID,
                "fieldValue": $('#allDates').val() + " " + $('#allTimes').val()
            }) : data.address.customFields = [{
                "fieldId": pickupTimeslotFieldID,
                "fieldValue": $('#allDates').val() + " " + $('#allTimes').val()
            }];
        }


        var thisConsignment;
        if (checkoutDetails.consignments.length > 0) {
            let checkoutConsignment = await storefrontServices.updateConsignment(cartItemDetails.id, checkoutDetails.consignments[0].id, data);
            thisConsignment = checkoutConsignment.consignments[0];
        } else {
            let checkoutConsignment = await storefrontServices.updateShippingAddress(cartItemDetails.id, [data]);
            thisConsignment = checkoutConsignment.consignments[0];
        }
        if (thisConsignment.availableShippingOptions.length == 0) {
            $('#cnc__fake-checkout-shipping-continue').removeClass('is-loading').removeAttr('style');
        } else {
            var thisPickupMethod = thisConsignment.availableShippingOptions.filter(function (con) {
                return con.description.toLowerCase().indexOf('pickup') >= 0 || con.description.toLowerCase().indexOf('pick up') >= 0;
            });
            if (thisPickupMethod.length > 0) {
                thisPickupMethod = thisPickupMethod[0];
                var shippingDetailsUpdates = await storefrontServices.updateConsignment(cartItemDetails.id, thisConsignment.id, { "shippingOptionId": thisPickupMethod.id });
                location.reload();
            } else {
                console.log('no pickup method');
                $('#cnc__fake-checkout-shipping-continue').removeClass('is-loading').removeAttr('style');
            }
        }

        resolve();
    })
}

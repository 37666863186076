
import { setCartMetaData } from "../cart/cartData";
import { CheckoutObserver } from "../common/observer";
import { Popup } from "../common/popUp";
import { getCookie, observeSections, redirectToCartPage, setCookie, groupThisArrayBy, showReplicaLoadingSkeleton, hideReplicaLoadingSkeleton, closeDrawer } from "../common/utils";
import { renderDrawerStores } from "../globalPage/globalPageRenderer";
import { setStore } from "../globalPage/globalpageData";
import { storefrontServices } from "../services/bigCommerceServices";
import { getBOPISData } from "../services/randemRetailServices";
import { emptyShippingAddress, setShippingMethod, setStoreDetailsAsShippingDetails } from "./checkoutData";
import { renderCheckoutCnC, renderCheckoutSelectedStoreInfo, renderLoadingReplica, renderPISForm, showLoadingNotification, hideLoadingNotification, showLoadingNotificationInShippingMethods, hideLoadingNotificationInShippingMethods, renderShippingMethods, renderNoShippingMethods, insertAddressBook } from "./checkoutRenderer";
import { staticCustomizations } from "../common/globalConstants";

let checkoutObserver = new CheckoutObserver();

export function bindCheckoutEvents() {
    bindOnShippingOptionClick();
    bindOnPickupOptionClick();
    bindOnCnCSelectChange();
    bindOnFakeCheckoutShippingContinue();
    bindOnSplitShippingChange();
    bindOnContinueSplitShipping();
    bindAddress1OnChange();
}

export function bindAddress1OnChange(){
    $("body").on("blur", "#customAutoComplete-addressLine1Input", (e) => {
        checkoutObserver.changeValue($('#addressLine1Input').get(0), $(e.currentTarget).val());
    });
    $("body").on("blur", "#customAutoComplete-addressLine1Input-forBilling", (e) => {
        checkoutObserver.changeValue($('#addressLine1Input').get(0), $(e.currentTarget).val());
    });
}

export function checkoutAfterUpdaterListenerInit() {
    if (checkoutObserver.isShippingAddressOpened()) {
        if ($('.cnc__shipping-types').length == 0) {
            renderCheckoutCnC();
        }
    }
    if (checkoutObserver.whenCheckoutShippingLoads()) {
        let preferredCheckoutMethod = getCookie("checkoutMethod") || "shipping";;

        if (preferredCheckoutMethod == 'shipping') {
            $('#checkout-shipping-options .pickup-in-store').hide();
            $('.shippingOptions-container ul li[class*="pickup"]').hide();
            $('.shippingOptions-container ul li[class*="same"]').hide();
        }

        let isPISselectedShippingOption = $('#checkout-shipping-options input[type=radio]:checked').next('label').text().toLowerCase().indexOf('pickup') >= 0 || $('#checkout-shipping-options input[type=radio]:checked').length == 0;
        if (preferredCheckoutMethod == 'shipping' && isPISselectedShippingOption == true) {
            $('.shippingOptions-container ul li:not([class*="pickup"]):not([class*="same"]):first input[type=radio]').trigger('click');
            $('.shippingOptions-container ul li:not([class*="pickup"]):not([class*="same"]):first div').trigger('click');
        }
    }
}

export function loadersInCheckout(preferred_checkout = false) {
    if(preferred_checkout == false){
        $('.checkout-step--shipping .checkout-form').addClass('hide');
        if($('#replica-loading-skeleton').length == 0){
            $('.checkout-step--shipping .checkout-view-content').prepend(renderLoadingReplica());
        }
    }else{
        $(renderLoadingReplica()).insertAfter($('.checkout-step--shipping .checkout-view-content .cnc__shipping-types'));

        if(cartMetaData.thisSplitShippingRow) $(renderLoadingReplica()).insertAfter($(cartMetaData.thisSplitShippingRow).find('.cnc__split-shipping-types'));
    }
}

export function checkoutUpdaterListenerInit() {
    if (checkoutObserver.isShippingAddressOpened()) {
        const $checklistItems = $(".shippingOptions-container .form-checklist-item");

        $checklistItems.each((index, item) => {
            const shippingOptionTextToClass = $(item).find(".shippingOption-desc").text().trim().toLowerCase().replaceAll(" ", "-");
            $(item).addClass(shippingOptionTextToClass);
        });
    }
}

function bindOnShippingOptionClick() {
    $("body").on("click", "#cnc__shipping-delivery", async () => {
        $("#cnc__shipping-delivery").addClass("active");
        $("#cnc__shipping-pickup").removeClass("active");
        $("#cnc__same-day-delivery").removeClass("active");

        setCookie("checkoutMethod", "shipping", 7);


        // Go back to single shipping mode if the split shipping was already clicked
        if ($(`[data-test="shipping-mode-toggle"]`).text() === "Ship to a single address") {
            document.querySelector(`[data-test="shipping-mode-toggle"]`)?.click();
        }

        $('.checkout-step--shipping .checkout-form').fadeOut();
        $('.checkout-form-pickup').fadeOut();


        checkoutObserver.checkBillingSameAsShipping();
        emptyShippingAddress();
        await setShippingMethod();
    });
}

function bindOnPickupOptionClick() {
    $("body").on("click", "#cnc__shipping-pickup", async () => {
        $("#cnc__shipping-pickup").addClass("active");
        $("#cnc__shipping-delivery").removeClass("active");
        $("#cnc__same-day-delivery").removeClass("active");

        setCookie("checkoutMethod", "pickup", 7);

        renderCheckoutSelectedStoreInfo();

        $('.checkout-step--shipping .checkout-form').fadeOut();
        $('.checkout-form-pickup').fadeOut();

        checkoutObserver.unCheckBillingSameAsShipping();
        checkoutObserver.unCheckSaveShippingAsAddress();
        await setShippingMethod();
    });
}

function bindOnCnCSelectChange() {
    $("body").on("click", "[data-location-id], .cnc__set-store-btn", async (e) => {
        let locationId = $(e.target)?.attr("store-id");
        if (locationId) {
            closeDrawer();
            $(".checkout-form-pickup").hide();
            showLoadingNotification();
            showReplicaLoadingSkeleton("style='margin-top:2rem;'");

            getBOPISData().then(async () => {
                setCookie('storeSelectedIDV3', locationId, 365);
                await setStore(locationId, true, true);
                hideLoadingNotification();
                hideReplicaLoadingSkeleton();
                emptyShippingAddress();
                await renderCheckoutSelectedStoreInfo();
                checkoutObserver.unCheckBillingSameAsShipping();
                checkoutObserver.unCheckSaveShippingAsAddress();
                $(".checkout-form-pickup").show();
            });
        }
    });
}

function bindOnFakeCheckoutShippingContinue() {
    $("body").on("click", "#cnc__fake-checkout-shipping-continue", async function () {
        let cartItemDetails = await storefrontServices.getCart();
        cartItemDetails = cartItemDetails[0];

        var notAllowedPickup = checkoutMetaData.products.filter(function(prd){
            let isNotAllowed = bopis.perSKU == null || bopis.perSKU.find(sku=> sku.sku == prd.sku && sku.quantity >= prd.quantity && sku.storeId == selectedStoreId) == undefined;
            if(isNotAllowed == true && selectedStoreId == subStoreWarehouseID){ 
                isNotAllowed = bopis.perSKU == null || bopis.perSKU.find(sku=> sku.sku == prd.sku && sku.quantity >= prd.quantity && sku.storeId == storeWarehouseID) == undefined;
            }
            return isNotAllowed;
        });
        
        if (notAllowedPickup.length > 0) {
            storefrontServices.removeConsignment();
            let headerText = "Unavailable items";
            let contentHTMLul = '';
            notAllowedPickup.forEach(function (el, ind) {
                contentHTMLul = contentHTMLul + '<li>' + el.name + '</li>';
            });
            let contentHTML = `
            <p>Some items are unavailable for pickup in selected store</p>
            <ul>
                ${contentHTMLul}
            </ul>`;
            checkoutUnavailabilityPopup.prepare({
                headerText: headerText,
                contentHTML: contentHTML,
                button1Text: "Change Pickup Store",
                button2Text: "Go To Cart",
                button3Text: "Change Store",
                onButton1Click: () => {
                    checkoutUnavailabilityPopup.close();
                    $('.change-selected-store').trigger('click');
                },
                onButton2Click: () => {
                    redirectToCartPage();
                    checkoutUnavailabilityPopup.close();
                },
            });

            checkoutUnavailabilityPopup.open();
        } else {
            if (isDatePickerEnabled == true && ($('#allDates').val().length == 0)) {
                let timeSlotPopUp = new Popup();
                let contentHTML = "Please select your preferred pickup time";
                timeSlotPopUp.prepare({
                    headerText: "No Timeslot Selected",
                    contentHTML: contentHTML,
                    button1Text: "OKAY",
                    onButton1Click: () => {
                        timeSlotPopUp.close();
                    }
                });

                timeSlotPopUp.open();
                return;
            }
            await setStoreDetailsAsShippingDetails(selectedPickupStore);
        }
    });

    $("body").on("click", "#checkout-shipping-continue", (e) => {
        let preferredCheckoutMethod = getCookie("checkoutMethod") || "shipping";
        let isPISselectedShippingOption = $('#checkout-shipping-options input[type=radio]:checked').next('label').text().toLowerCase().indexOf('pickup') >= 0;

        if (preferredCheckoutMethod == 'shipping' && isPISselectedShippingOption == true) {
            let contentHTML = "Please select your preferred Shipping Method";
            if ($('#checkout-shipping-options li').length >= 1) {
                contentHTML = "Please try other Shipping Address";
            }
            checkoutUnavailabilityPopup.prepare({
                headerText: "No Shipping Method Selected",
                contentHTML: contentHTML,
                button1Text: "OKAY",
                onButton1Click: () => {
                    checkoutUnavailabilityPopup.close();
                }
            });

            checkoutUnavailabilityPopup.open();

            storefrontServices.removeConsignment();
            e.preventDefault();
        }
    });
}

function closeAllPopUp(){
    $('.notice-popup.find-store').fadeOut(250);
    $("body").css("overflow", "auto");
    $("body").removeClass("overflow-hidden");
}

function bindOnSplitShippingChange(){
    if(splitShippingMethod == false) return;
    $("body").on("change", "[data-test='countryCodeInput-select']", async (e) => {
        let countryCode = $(e.currentTarget).val();
        let thisShippingCountry = cartMetaData.shippingCountries.find(country=>country.code == countryCode);
        if(!thisShippingCountry) return;

        let parentForm = $(e.currentTarget).parents('.checkout-address');

        $(parentForm).find('div.dynamic-form-field--provinceCode').attr('required-state', thisShippingCountry.requiresState);
        
        if(thisShippingCountry.subdivisions.length == 0){
            $(parentForm).find('div[for="provinceCodeSelect"]').hide();
            $(parentForm).find('div[for="provinceCodeText"]').show();
            $(parentForm).find('div[for="provinceCodeSelect"] select').removeAttr('required');
            
        }else{
            $(parentForm).find('div[for="provinceCodeSelect"] select').attr('required', 'required');
            $(parentForm).find('[data-test="provinceCodeInput-select"] option[value!=""]').remove();
            thisShippingCountry.subdivisions.forEach(function(subd){
                $(parentForm).find('[data-test="provinceCodeInput-select"]').append($('<option>').val(subd.code).text(subd.name));
            });
            $(parentForm).find('div[for="provinceCodeSelect"]').show();
            $(parentForm).find('div[for="provinceCodeText"]').hide();
        }

        $(parentForm).find('#checkout-shipping-options .loadingOverlay-container').hide();
        $(parentForm).find('#checkout-shipping-options .loading-skeleton, #checkout-shipping-options .shippingOptions-panel.optimizedCheckout-overlay').show();
        $(parentForm).find('[data-test="provinceInput-text"]').val('');
    });

    $("body").on("change", "[data-test='provinceCodeInput-select']", async (e) => {
        let provinceCode = $(e.currentTarget).val();
        let provinceText = $(e.currentTarget).find('option:selected').text();
        let parentForm = $(e.currentTarget).parents('.checkout-form, .checkout-address');
        $(parentForm).find('div[for="provinceCodeText"] input').val(provinceCode);
    });

    $('body').on('click', '#cnc__split-shipping-pickup, #cnc__split-shipping-delivery', async function(e){
        e.preventDefault();
        $(this).parents('.split-shipping-row').find('input[required]').removeAttr('style');
        $(this).parents('.cnc__split-shipping-types').find('button').removeClass('active').removeAttr('disabled');
        $(this).addClass('active');
        $(this).attr('disabled', 'disabled');
        $(this).parents('.split-shipping-row').find('.cnc__split-shipping-delivery, .cnc__split-shipping-pis').addClass('hide');
        if($(this).val() == 'shipping'){
            $(this).parents('.split-shipping-row').find('.cnc__split-shipping-delivery').removeClass('hide');
        }else{
            $(this).parents('.split-shipping-row').find('.cnc__split-shipping-pis').removeClass('hide');
        }
        $(this).parents('.split-shipping-row').attr('checkout-type', $(this).val());

        const $shippingFormSelects = $(this).parents('.split-shipping-row').find('#checkoutShippingAddress').find('select');
        const $shippingFormInputs = $(this).parents('.split-shipping-row').find('#checkoutShippingAddress').find('input');

        $shippingFormSelects.each((selectIndex, select) => {
            $(select).val("");
            checkoutObserver.changeSelectValue(select, "")
        });

        $shippingFormInputs.each((inputIndex, input) => {
            $(input).val("");
            if (document.getElementById($(input).attr('id'))) {
                checkoutObserver.changeValue($(input).get(0), '')
            }
        });

    });

    $('body').on('click', '.cnc__pref-checkout-set-store-btn', async function () {
        let thisLineItem = $(this).attr('this-lineitem');
        let thisStoreID = $(this).attr('store-id');
        let thisLineItems = JSON.parse(thisLineItem);
        $('.split-shipping-row ul.productList > li[line-item="'+thisLineItems[0].itemId+'"]').parents('.split-shipping-row').find('.cnc__split-shipping-pis').html(renderPISForm(thisStoreID, thisLineItem));
        closeAllPopUp();

        /** set in local storage **/
        SelectedSplitShippingMethods = JSON.parse(localStorage.getItem('SelectedSplitShippingMethods')) || [];
        thisLineItems.forEach(function(lineItem){
            SelectedSplitShippingMethods = SelectedSplitShippingMethods.filter((item) => item?.lineItem !== lineItem.itemId);
            let thisNewSelectedMethod = { lineItem: lineItem.itemId, method: 'pickup', storeid: thisStoreID}
            localStorage.setItem('SelectedSplitShippingMethods', JSON.stringify(SelectedSplitShippingMethods.concat([thisNewSelectedMethod])));
        })
        /** set in local storage **/

        cartMetaData.checkout = await storefrontServices.createConsignment(cartMetaData.checkout.id, [{
            "address":{
                "firstName":"",
                "lastName":"",
                "countryCode":cartMetaData.shippingCountries.length > 0 ? cartMetaData.shippingCountries[0].code : "US"
            },
            "lineItems": thisLineItems
        }]);
        
    });
}

function bindStepsCTAListeners() {
    document.getElementById("checkout-page-container").addEventListener("click", async function (e) {
        // e.target was the clicked element
        if (e.target && (e.target.matches(`.checkout-step--shipping .stepHeader-actions .button--tertiary`)
            || e.target.matches(`#checkout-customer-continue`))) {
            await observeSections('.checkout-form form #checkoutShippingAddress');
            await setCartMetaData();
            renderDrawerStores();
            await renderCheckoutCnC();
            renderCheckoutSelectedStoreInfo();
        }
    });
}

export function bindCheckoutRender(){
    var observerCheckoutContent = new MutationObserver(function(mutations) {
        mutations.forEach(async function(mutation) {
            if (!mutation.addedNodes) return
            for (var i = 0; i < mutation.addedNodes.length; i++) {
                // do things to your newly added nodes here
                var node = mutation.addedNodes[i]
                if ($(node).hasClass("checkout-view-content")) {
                    $(".checkout-step--shipping .stepHeader-title").text('Select Preferred Checkout').css('flex', '12');
                    if($(node).parents('li').attr('class').indexOf('shipping') >= 0 && $(node).parents('li').find('.checkout-form').length > 0){
                        let hideBCShippingFields = setInterval(()=>{
                            $(node).parents('li').find('.loading-skeletion').hide();
                            $(node).parents('li').find('.checkout-form').hide();
                        });
                        await renderCheckoutCnC();
                        clearInterval(hideBCShippingFields);
                    }
                    if($(node).parents('li').attr('class').indexOf('billing') >= 0 || $(node).parents('li').attr('class').indexOf('payment') >= 0){
                        await getBOPISData();
                        let removeAutoCompleteForm = setInterval(()=>{
                            if($('fieldset#checkoutBillingAddress input').length > 0){
                                $('input').attr('autocomplete', 'none');
                                // clearInterval(removeAutoCompleteForm);
                            }
                        });

                        await setCartMetaData();
                        
                        
                        if(cartMetaData.checkout.consignments.filter(con=>con.shippingAddress.firstName == "").length > 0){
                            for (let cons of cartMetaData.checkout.consignments.filter(con=>con.shippingAddress.firstName == "")){
                                await storefrontServices.deleteConsignment(cartMetaData.checkout.id, cons.id);
                            }
                            let cannotCheckoutPopup = new Popup();
                            cannotCheckoutPopup.prepare({
                                    headerText: 'Missing Checkout Details',
                                    contentHTML: `Please fill out required checkout fields to continue.`,
                                    button1Text: "OKAY",
                                    onButton1Click: () => {
                                        cannotCheckoutPopup.close();
                                        location.reload();
                                    },
                                });
                            cannotCheckoutPopup.open();
                            return;
                        }

                        let OOS_OrdersChecker = cartMetaData.cart.lineItems.physicalItems.map(function(itemID){
                            let productInfo = cartMetaData.cartProducts.find(product=>product.lineitemId == itemID.id);
                            let isStock = productInfo.stockCountInWarehouse >= productInfo.quantity;
                            if(productInfo.method == 'pickup'){
                                isStock = productInfo.stockCountInSelectedStore >= productInfo.quantity;
                            }
                            return isStock == false ? productInfo.name:null;
                        }).filter(n=>n);
                        console.log('hello 124', $(node).parents('li').attr('class'),OOS_OrdersChecker, cartMetaData);
                        if(OOS_OrdersChecker.length > 0){
                            checkoutUnavailabilityPopup.prepare({
                                headerText: "Not Available",
                                contentHTML: `
                                <p>Some items are not available for Selected Checkout Methods: </p>
                                <div class="not-eligible-hldr">
                                    <div class="not-eligible">
                                        <ul>${OOS_OrdersChecker.map(itemName=>`<li>${itemName}</li>`).join("")}</ul>
                                    </div>
                                </div>`,
                                button1Text: "Go to Cart",
                                onButton1Click: () => {
                                    redirectToCartPage();
                                },
                            });
                    
                            checkoutUnavailabilityPopup.open();
                        }
                    }
                }
            }

            
        })
    })

    observerCheckoutContent.observe(document.body, {
        childList: true,
        subtree: true,
        attributes: false,
        characterData: false
    })
}

function bindOnContinueSplitShipping(){
    if(splitShippingMethod == false) return;
    $('body').on('click', '.cnc__split-shipping #addressToggle', function(e){
        e.preventDefault();
        $(this).parents('.dropdown--select').find('.dropdownMenu').toggleClass('hide');
    });

    $('body').on('click', '.cnc__split-shipping ul#addressDropdown li', function(e){
        e.preventDefault();
        let $this = $(this);
        let thisAnchor = $this.find('a');
        let dataTest = thisAnchor.attr('data-test');
        if(dataTest == 'add-new-address'){
            const $shippingFormSelects = $this.parents('#checkoutShippingAddress').find('select');
            const $shippingFormInputs = $this.parents('#checkoutShippingAddress').find('input');

            $shippingFormSelects.each((selectIndex, select) => {
                $(select).val("");
                checkoutObserver.changeSelectValue(select, "")
            });

            $shippingFormInputs.each((inputIndex, input) => {
                $(input).val("");
                if (document.getElementById($(input).attr('id'))) {
                    checkoutObserver.changeValue($(input).get(0), '')
                }
            });
        }else{
            thisAnchor.find('span').each(function(idx,elem){
                $(elem).attr('class') && $(elem).attr('class').split(" ").forEach(function (i,el){
                    if($this.parents('.checkout-form').find('[name="'+i+'"]').attr('type') == 'text' || $this.parents('.checkout-form').find('[name="'+i+'"]').attr('type') == 'tel'){
                        checkoutObserver.changeValue($this.parents('.checkout-form').find('[name="'+i+'"]').get(0), $(elem).text());
                    }else{
                        if(i.indexOf('country') >= 0){
                            checkoutObserver.changeSelectValue($this.parents('.checkout-form').find('[name="'+i+'"]').get(0),cartMetaData.shippingCountries.find(country=>country.name.toLowerCase() == $(elem).text().toLowerCase())?.code);
                        }else{
                            let isExistProvince = cartMetaData.shippingCountries.find(country=>country.subdivisions.find(sub=>sub.code == $(elem).text() || sub.name == $(elem).text()));
                            if(isExistProvince){
                                checkoutObserver.changeSelectValue($this.parents('.checkout-form').find('[name="'+i+'"]').get(0),isExistProvince.subdivisions.find(sub=>sub.code == $(elem).text() || sub.name == $(elem).text())?.code);
                            }
                        }
                    }
                });
            });

        }

        $this.parents('.dropdownMenu').toggleClass('hide');

    });
    
    $('body').on('change', 'input[type="radio"][name="shippingOptionIds"]', async function (e) {
        let $thisRadio = $(e.target);
        let thisSelectedShippingOPT = $thisRadio.val();
        let $thisLoading = $thisRadio.parents('.shippingOptions-container').find('.loadingOverlay-container .loadingOverlay');
        let $thisElement = $(this);
        let $thisSplitShippingRow = $thisElement.parents('.split-shipping-row');

        $thisSplitShippingRow.find('.optimizedCheckout-form-checklist-item--selected').removeClass('optimizedCheckout-form-checklist-item--selected').addClass('optimizedCheckout-form-checklist-item');
        $thisSplitShippingRow.find('.form-checklist-header--selected').removeClass('form-checklist-header--selected').addClass('form-checklist-header');
        $thisLoading.show();

        await storefrontServices.updateConsignment(cartMetaData.checkout.id, $thisSplitShippingRow.attr('consignment-id'), { "shippingOptionId": thisSelectedShippingOPT });

        SelectedSplitShippingMethods = JSON.parse(localStorage.getItem('SelectedSplitShippingMethods')) || [];
        
        /** set in local storage **/
        let thisConsignment = cartMetaData.checkout.consignments.find(con=>con.id == $thisSplitShippingRow.attr('consignment-id'));
        if(thisConsignment){
            thisConsignment.lineItemIds.forEach(function(lineItemId){
                SelectedSplitShippingMethods = SelectedSplitShippingMethods.filter((item) => item?.lineItem !== lineItemId);
                let thisNewSelectedMethod = { lineItem: lineItemId, method: 'shipping', deliveryAddress: thisConsignment.shippingAddress}
                localStorage.setItem('SelectedSplitShippingMethods', JSON.stringify(SelectedSplitShippingMethods.concat([thisNewSelectedMethod])));
            })
        }
        /** set in local storage **/

        
        $thisLoading.hide();
        $thisRadio.parents('.optimizedCheckout-form-checklist-item').addClass('optimizedCheckout-form-checklist-item--selected');
        $thisRadio.parents('.form-checklist-header').addClass('form-checklist-header--selected');

    });

    var changingTimer;
    $("body").on("change", ".cnc__split-shipping #checkoutShippingAddress input[type='text'], .cnc__split-shipping input.shouldSaveAddress[type='checkbox'], .cnc__split-shipping #checkoutShippingAddress input[type='tel'], .cnc__split-shipping #checkoutShippingAddress select", async (e) => {
        let completeRequiredFields = true;
        let addressFields = {};
        let $thisSplitShippingRow = $(e.target).parents('.split-shipping-row');
        let consignmentID = $thisSplitShippingRow.attr('consignment-id');
        clearTimeout(changingTimer);
        changingTimer = setTimeout(async function () {
            let requiredFields = $(e.target).parents('.checkout-form').find('input[type="text"][required],input[type="tel"][required],select[required]');
            // requiredFields.removeClass('error-field');
            requiredFields.each(function(indx,field){
                let fieldValue = $(field).val();
                let fieldName = $(field).attr('name');
                addressFields[fieldName] = fieldValue;

                if(fieldValue.length == 0){
                    completeRequiredFields = false;
                }
            });

            addressFields['company'] = $(e.target).parents('.checkout-form').find('input#companyInput').val();
            addressFields['address2'] = $(e.target).parents('.checkout-form').find('input#addressLine2Input').val();
            addressFields['phone'] = $(e.target).parents('.checkout-form').find('input#phoneInput').val();
            addressFields['phoneNumber'] = $(e.target).parents('.checkout-form').find('input#phoneInput').val();
            addressFields['stateOrProvince'] = $(e.target).parents('.checkout-form').find('input#provinceInput').val();
            addressFields['shouldSaveAddress'] = $(e.target).parents('.checkout-form').find('input.shouldSaveAddress:checked').val() || false;
            
            
            if(completeRequiredFields){
                showLoadingNotificationInShippingMethods($thisSplitShippingRow);
                let lineItems = [];
                $thisSplitShippingRow.find('ul.productList li.productList-item').each(function (li, el) {
                    lineItems.push({ 'itemId': $(el).attr('line-item'), 'quantity': parseInt($(el).attr('line-qty')) })
                });

                cartMetaData.checkout = await storefrontServices.getCheckOutInformation(cartMetaData.checkout.id);

                let lastSelectedShippingOPT = cartMetaData.checkout.consignments.length > 0 && cartMetaData.checkout.consignments.find(con=> con.id == consignmentID)?.selectedShippingOption || null;

                cartMetaData.checkout = await storefrontServices.createConsignment(
                    cartMetaData.checkout.id, 
                    [
                        {
                            "address": addressFields,
                            "lineItems": lineItems
                        }
                    ]
                );

                
                let thisConsignment = cartMetaData.checkout.consignments.find(consignment => arraysContainSame(consignment.lineItemIds,lineItems.map(item => item.itemId)) == true);
                
                if(lastSelectedShippingOPT && thisConsignment && thisConsignment.availableShippingOptions.find(shipping=>shipping.id == lastSelectedShippingOPT.id)){
                    cartMetaData.checkout = await storefrontServices.updateConsignment(
                        cartMetaData.checkout.id, 
                        thisConsignment.id, 
                        { "shippingOptionId": lastSelectedShippingOPT.id }
                    );
                }
                
                /** set in local storage **/
                if(thisConsignment){
                    $thisSplitShippingRow.attr('consignment-id', thisConsignment.id);
                    $thisSplitShippingRow.find('.change-selected-store').attr('data-element-id', thisConsignment.id);
                    thisConsignment.lineItemIds.forEach(function(lineItemId){
                        SelectedSplitShippingMethods = JSON.parse(localStorage.getItem('SelectedSplitShippingMethods')) || [];
                        SelectedSplitShippingMethods = SelectedSplitShippingMethods.filter((item) => item?.lineItem !== lineItemId);
                        let thisNewSelectedMethod = { lineItem: lineItemId, method: 'shipping', deliveryAddress: thisConsignment.shippingAddress}
                        localStorage.setItem('SelectedSplitShippingMethods', JSON.stringify(SelectedSplitShippingMethods.concat([thisNewSelectedMethod])));
                    })
                }
                /** set in local storage **/

                let availableShippingMethods = thisConsignment && thisConsignment.availableShippingOptions.filter(method=> method.description.toLowerCase().indexOf('pickup') < 0);
                
                if(availableShippingMethods.length > 0){
                    if(lastSelectedShippingOPT == null){
                        cartMetaData.checkout = await storefrontServices.updateConsignment(
                            cartMetaData.checkout.id, 
                            thisConsignment.id, 
                            { "shippingOptionId": availableShippingMethods[0].id }
                        );
                    }
                    renderShippingMethods(availableShippingMethods, lastSelectedShippingOPT, $thisSplitShippingRow);
                }else{
                    renderNoShippingMethods($thisSplitShippingRow);
                }

                await insertAddressBook();

                await setCartMetaData();

            }else{
                hideLoadingNotificationInShippingMethods($thisSplitShippingRow);
            }
        }, 500);
    });

    $("body").on("click", "#cnc__fake-checkout-split-shipping-continue", async (e) => {
        e.preventDefault();
        $("#cnc__fake-checkout-split-shipping-continue").addClass('is-loading').attr('style', 'pointer-events:none;');
        showLoadingNotification();

        let splitShippingOrders =$('.split-shipping-row');
        let OOS_Orders = [];
        cartMetaData.PIS_bodyAddress = [];
        let removeThisConsignments = [];
        let incompleteFields = false;
        for (let thisOrderRow of splitShippingOrders) {
            let checkoutType = $(thisOrderRow).attr('checkout-type');
            let checkoutConsignmentID = $(thisOrderRow).attr('consignment-id');
            let lineItems = [];
            let lineSKUs = [];
            $(thisOrderRow).find('ul.productList li.productList-item').each(function (li, el) {
                lineItems.push({ 'itemId': $(el).attr('line-item'), 'quantity': parseInt($(el).attr('line-qty')) });
                lineSKUs.push($(el).attr('line-sku'));
            });
            switch (checkoutType) {
                case 'pickup':
                    let storeId = $(thisOrderRow).find('[data-storeid]').attr('data-storeid');
                    if(!storeId) {
                        incompleteFields = true;
                        break;
                    }
                    let thisSelectedStore = bopis?.allStores?.find(str => str.locationId == storeId);
                    let oosPIS = lineSKUs.filter(lineSKU => {
                        return bopis.perSKU.find(sku=>sku.storeId == storeId && sku.sku == lineSKU && sku.quantity >= checkoutMetaData.products.find(prod=>prod.sku == lineSKU).quantity) == undefined;
                    });

                    let pis_address = {
                        "address1": thisSelectedStore.street?.replace(",", "")?.trim() || "",
                        "address2": thisSelectedStore.storeName + " Store",
                        "city": thisSelectedStore.city,
                        "company": "",
                        "country": thisSelectedStore.country,
                        "countryCode": cartMetaData.shippingCountries.find(country=>country.name.toLowerCase() == thisSelectedStore.country.toLowerCase())?.code,
                        "firstName": thisSelectedStore.storeName,
                        "lastName": 'Store',
                        "phone": thisSelectedStore.phone,
                        "postalCode": thisSelectedStore.zipCode,
                        "shouldSaveAddress": false,
                        "stateOrProvince": thisSelectedStore.state,
                        "customFields": [
                            {
                                "fieldId": pickupStoreFieldID,
                                "fieldValue": thisSelectedStore.locationId,
                            },
                            {
                                "fieldId": allowSplitShippingFieldID,
                                "fieldValue": 'Yes'
                            }
                        ]
                    };

                    if(oosPIS.length > 0){
                        OOS_Orders.push({'method': "Pickup In Store", 'items': oosPIS.map(sku => checkoutMetaData.products.find(cProduct=>cProduct.sku == sku).name), 'address': pis_address});
                        removeThisConsignments.push(checkoutConsignmentID);
                    }else{
                        cartMetaData.PIS_bodyAddress.push(
                            {
                                "address": pis_address,
                                "lineItems": lineItems
                            }
                        );
                    }
                     
                    break;
                default:

                    let SFSaddressLine1 = $(thisOrderRow).find('.checkout-form.sfs-form [data-test="addressLine1Input-text"]').val();
                    let SFSaddressCity = $(thisOrderRow).find('.checkout-form.sfs-form #cityInput').val();
                    let SFSaddressStateName = $(thisOrderRow).find('.checkout-form.sfs-form [data-test="provinceInput-text"]').val();
                    let SFSaddressFirstName = $(thisOrderRow).find('.checkout-form.sfs-form #firstNameInput').val();
                    let SFSaddressLastName = $(thisOrderRow).find('.checkout-form.sfs-form #lastNameInput').val();
                    let SFSaddressPhone = $(thisOrderRow).find('.checkout-form.sfs-form #phoneInput').val();
                    let SFSaddressPostalCode = $(thisOrderRow).find('.checkout-form.sfs-form #postCodeInput').val();
                    let SFSaddressCountryName = $(thisOrderRow).find('.checkout-form.sfs-form #countryCodeInput option:selected').text();
                    if(SFSaddressLine1 == undefined || SFSaddressLine1.length == 0 || SFSaddressCity.length == 0 || (SFSaddressStateName.length == 0 && $(thisOrderRow).find('.checkout-form.sfs-form .dynamic-form-field--provinceCode').attr('required-state') == 'true') || SFSaddressFirstName.length == 0 || SFSaddressLastName.length == 0 || SFSaddressPhone.length == 0 || SFSaddressPostalCode.length == 0 || SFSaddressCountryName.length == 0){
                        removeThisConsignments.push(checkoutConsignmentID);
                        incompleteFields = true;
                        break;
                    }

                    break;
            }
        }

        if(incompleteFields == true){
            incompleteShippingDetails();
            return;
        }
        console.log(OOS_Orders, "OOS_Orders");
        if(OOS_Orders.length > 0){
            reEnableContinueButton();
            bopis.OOS_Orders = OOS_Orders;
            let contentHTML = `
            ${OOS_Orders.length > 1 ? `<p>Some items are not available for Selected Checkout Methods</p>`:``}
            <div class="not-eligible-hldr">${OOS_Orders.map(function(order) {
                let eligiblility_label = '';
                if(order.method == 'Pickup In Store') eligiblility_label = `These items are Not Available for <b>Pickup</b> in <b>${order.address.firstName}</b>:`;
                return `<div class="not-eligible">
                            ${eligiblility_label}
                            <ul>${order.items.map(itemName=>`<li>${itemName}</li>`).join("")}</ul>
                        </div>`
            }).join("")}
            </div>`;
            checkoutUnavailabilityPopup.prepare({
                headerText: "Not Available",
                contentHTML: contentHTML,
                button1Text: "OKAY",
                onButton1Click: () => {
                    checkoutUnavailabilityPopup.close();
                },
            });
    
            checkoutUnavailabilityPopup.open();
        }else{

            if(cartMetaData.PIS_bodyAddress.length > 0) {
                console.log('start-pis')
                checkoutMetaData.checkout = await storefrontServices.createConsignment(cartMetaData.checkout.id, cartMetaData.PIS_bodyAddress);
                let availableShippingOptionsWOPIS = checkoutMetaData.checkout.consignments.filter(con => con.address.lastName == 'Store' && con.selectedShippingOption == null);
    
                if (availableShippingOptionsWOPIS.length > 0) {
                    for (let con of availableShippingOptionsWOPIS) {
                        let thisPISMethod = con.availableShippingOptions.find(opt => opt.description.toLowerCase().indexOf('pickup') >= 0 || opt.description.toLowerCase().indexOf('pick up') >= 0);
                        if (thisPISMethod) {
                            await storefrontServices.updateConsignment(cartMetaData.checkout.id, con.id, { "shippingOptionId": thisPISMethod.id });
                        }
                    }
                }
                console.log('done-pis')
            }

            cartMetaData.checkout = await storefrontServices.getCheckOutInformation(cartMetaData.cart.id);
            let noShippingDetails = cartMetaData.cartProducts.filter(product => cartMetaData.checkout.consignments.find(con => con.lineItemIds.indexOf(product.lineitemId) >= 0) == undefined);
            if(cartMetaData.checkout.consignments.length == 0 || noShippingDetails.length > 0 || cartMetaData.checkout.consignments.find(con=>con.selectedShippingOption == null)) {
                incompleteShippingDetails(); 
                reEnableContinueButton();
                return;
            }

            SelectedSplitShippingMethods = cartMetaData.checkout.consignments.map(con=>{
                let method = 'shipping';
                let storeid = {};
                if(con.selectedShippingOption.description.toLowerCase().indexOf('pickup') >=0){
                    method = 'pickup';
                    storeid = {'storeid': con.shippingAddress.customFields.find(field => field.fieldId == pickupStoreFieldID)?.fieldValue};
                }
                return con.lineItemIds.map(function (lineItem){ 
                    return {
                        'sku': cartMetaData.cart.lineItems.physicalItems.find(item=>item.id == lineItem)?.sku, 
                        'method':method, 
                        ...storeid,
                        'lineItem': lineItem,
                        'deliveryAddress': con.shippingAddress
                    }
                });
            }).reduce((a, b) => a.concat(b), []);

            localStorage.setItem('SelectedSplitShippingMethods', JSON.stringify(SelectedSplitShippingMethods));

            await consolidateShippingAddresses();
            await storefrontServices.updateCustomerMessage(cartMetaData.checkout.id, {
                "customerMessage": $('.split-shipping-hldr input[name="orderComment"]').val()
            })
            location.reload();
        }

    });
}

async function consolidateShippingAddresses(){
    return new Promise(async function(resolve) {
        if(cartMetaData.checkout.consignments.length < 1) resolve();
        let consolidatedShipping = groupThisArrayBy( {Group: cartMetaData.checkout.consignments, By: ['selectedShippingOption', 'shippingAddress']} );
        console.log(consolidatedShipping, "consolidatedShipping");
        consolidatedShipping = consolidatedShipping.filter(consolidate=>consolidate.length > 1);
        if(consolidatedShipping.length == 0) {resolve(); return;}
        console.log('consolidate here');
        for (let consolidateOrder of consolidatedShipping) {
            let addressFields = {};
            let selectedShippingOption = {};
            let lineItems = [];
            

            consolidateOrder.forEach(function(con){ 
                con.lineItemIds.forEach(item => {
                    lineItems.push({ itemId: item, quantity: cartMetaData.cartProducts.find(product=>product.lineitemId == item).quantity })
                });
                addressFields = con.shippingAddress;
                selectedShippingOption = con.selectedShippingOption;
            });

            let bodyAddress = [
                {
                    "address": addressFields,
                    "lineItems": lineItems 
                }
            ];

            let thiscreatecon = await storefrontServices.createConsignment(cartMetaData.checkout.id, bodyAddress);
            let isExistingConsignment = thiscreatecon.consignments.find(consignment => arraysContainSame(consignment.lineItemIds,lineItems.map(item => item.itemId)) == true);
            if(isExistingConsignment) await storefrontServices.updateConsignment(cartMetaData.checkout.id, isExistingConsignment.id, { "shippingOptionId": selectedShippingOption?.id })
        }        

        resolve();
    });
}

export function arraysContainSame(a, b) {
    a = Array.isArray(a) ? a : [];
    b = Array.isArray(b) ? b : [];
    return a.length === b.length && a.every(el => b.includes(el));
  }

function incompleteShippingDetails() {
    let cannotCheckoutPopup = new Popup();
    $('.split-shipping-hldr input[required]').each(function(idx,el){
        if($(el).val().length == 0){
            $(el).attr('style', 'border: 1px solid red;');
        }
    });
    reEnableContinueButton();
    cannotCheckoutPopup.prepare({
        headerText: 'Missing Checkout Details',
        contentHTML: `Please fill out required checkout fields to continue.`,
        button1Text: "OKAY",
        onButton1Click: () => {
            cannotCheckoutPopup.close();
        },
    });
    cannotCheckoutPopup.open();
}

function reEnableContinueButton(){
    $("#cnc__fake-checkout-split-shipping-continue").removeClass('is-loading').removeAttr('style');
    hideLoadingNotification();
}
import { arraysContainSame } from "../checkout/checkoutListeners";
import { getItemStock, isCartPage, isCheckoutPage } from "../common/utils";
import { renderDrawerStores, renderHeader, renderStoreDrawer } from "../globalPage/globalPageRenderer";
import { storefrontServices } from "../services/bigCommerceServices";
import { graphQLServices } from "../services/graphQLServices";
import { getBOPISData } from "../services/randemRetailServices";
import { renderCartItemAvailability } from "./cartRenderer";

export const setCartMetaData = async () => {
    return new Promise(async resolve => {
        console.log('set cart meta data')
        if(cartMetaData.shippingCountries.length == 0) cartMetaData.shippingCountries = await storefrontServices.getShippingCountries();
        SelectedSplitShippingMethods = JSON.parse(localStorage.getItem('SelectedSplitShippingMethods')) || [];
        cartMetaData.cartProducts = [];

        cartMetaData.cart = await storefrontServices.getCart();

        cartMetaData.cart = cartMetaData.cart[0];

        cartMetaData.checkout = await storefrontServices.getCheckOutInformationWO(cartMetaData.cart?.id);

        cartMetaData.customerAddresses = cartMetaData.checkout.customer && cartMetaData.checkout.customer.addresses.length > 0 ? cartMetaData.checkout.customer.addresses : [];

        if (cartMetaData.cart?.length == 0) return;
        cartMetaData.cartItemsProdIDs = cartMetaData.cart ? cartMetaData.cart.lineItems.physicalItems.map(item => {
            return item.productId
        }) : [];

        let graphQLResResult_minCartData = await graphQLServices.getProductDataByIds();

        graphQLResResult_minCartData = graphQLServices.graphQLToJavascript(graphQLResResult_minCartData);

        if(splitShippingMethod && (isCheckoutPage() || isCartPage())){
            if(physicalItemsIDS.length > 0 && JSON.stringify(physicalItemsIDS)  !== JSON.stringify(cartMetaData.cart.lineItems.physicalItems.map(item=>item.id))){
                let separateConsignment = cartMetaData.cart.lineItems.physicalItems.map(item=>item.id).filter(x => !physicalItemsIDS.includes(x));
                if(separateConsignment.length > 0){
                    cartMetaData.checkout = await storefrontServices.createConsignment(cartMetaData.checkout.id, [{
                        "address":{
                            "firstName":"",
                            "lastName":"",
                            "countryCode":cartMetaData.shippingCountries.length > 0 ? cartMetaData.shippingCountries[0].code : "US"
                        },
                        "lineItems":separateConsignment.map(function(cons){
        
                            return { 
                                "itemId": cons, 
                                "quantity": cartMetaData.cart.lineItems.physicalItems.find(prod=>prod.id == cons).quantity 
                            }
                        })
                    }]);
        
                    let newItems = cartMetaData.checkout.consignments.filter(con=>con.shippingAddress.firstName == "" && con.shippingAddress.lastName == "" && con.shippingAddress.address1 == "" && con.shippingAddress.address2 == "" && con.shippingAddress.city == "" && con.shippingAddress.stateOrProvince == "" && con.shippingAddress.phone == "");
        
                    if(newItems.length > 0 && isCartPage()){
                        for(let item of newItems){
                            cartMetaData.checkout = await storefrontServices.getCheckOutInformation(cartMetaData.cart?.id);
                            if(cartMetaData.checkout.consignments.length > 0 && cartMetaData.checkout.consignments.find(con=>con.id == item.id)){
                                cartMetaData.checkout = await storefrontServices.deleteConsignment(cartMetaData.checkout.id, item.id);
                            }
                        }
                    }
                }
            }
        }


        localStorage.setItem('physicalItemsIDS', JSON.stringify(cartMetaData.cart.lineItems.physicalItems.map(item=>item.id)));
        let method;
        for(let idx in cartMetaData.cart.lineItems.physicalItems){
            let item = cartMetaData.cart.lineItems.physicalItems[idx];
            if(splitShippingMethod) method = SelectedSplitShippingMethods.find(select => select?.lineItem == item.id) || { 'method': null, 'deliveryAddress':null };
            let thisConsignment = {'consignmentID': item.id, 'existingConsignment': null};
            if(cartMetaData.checkout.consignments.length > 0 && cartMetaData.checkout.consignments.find(con=>con.lineItemIds.indexOf(item.id)>=0)){
                thisConsignment = cartMetaData.checkout.consignments.find(con=>con.lineItemIds.indexOf(item.id)>=0);
                thisConsignment = {'consignmentID': thisConsignment.id, 'existingConsignment': thisConsignment};
            }
            if(bopis == null) await getBOPISData();
            let productAvailability = (bopis.perSKU && bopis.perSKU.filter(sku => sku.sku == item.sku)) || [];
            let productAvailabilityInWarehouse = productAvailability.filter(sku=>sku.storeId == storeWarehouseID);
            let productAvailabilityInPIS = productAvailability.filter(sku=>sku.locationAllowPickup);
            let { stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup } = getItemStock(item.sku, selectedStoreId);
            let isPickupOnly = graphQLResResult_minCartData.find(gqRes => gqRes.entityId == item.productId && gqRes.customFields.length > 0 && gqRes.customFields.find(field=>field.name.toLowerCase().indexOf(strictPISlabelName) >=0 && field.value.toLowerCase() == strictPISlabelValue)) ? true: false;

            cartMetaData.cartProducts.push( {
                lineitemId: item.id,
                sku: item.sku,
                productId: item.productId,
                quantity: item.quantity,
                price: item.salePrice,
                thumbnail: item.imageUrl,
                name: item.name,
                ...thisConsignment,
                productAvailability,
                productAvailabilityInWarehouse,
                productAvailabilityInPIS,
                stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup,
                isPickupOnly
            });
        }

        let skus = cartMetaData.cartProducts.map(({ sku }) => sku);
        cartMetaData.cartProducts = cartMetaData.cartProducts.filter(({ sku }, index) => !skus.includes(sku, index + 1));

        resolve();
    })
}

export function cartOnSetStore() {
    if (!isCartPage()) return;
}
const { setCartMetaData } = require("../cart/cartData");
const { setStore } = require("./globalpageData");
const { closeDrawer, openDrawer, setCookie, isCheckoutPage } = require("../common/utils");
const { renderDrawerStores } = require("./globalPageRenderer");
const { Popup } = require("../common/popUp");
const { ICONS } = require("../common/icons");
const { renderStoreList, renderStoreListCheckout } = require("../cart/cartRenderer");

export function bindHeaderAndDrawerEventListeners() {
    bindCloseDrawerOnUnderlayClick();
    bindCloseDrawerOnDrawerXClick();
    bindDrawerStoreSearchByInfo();
    bindDrawerSetStore();
    bindToggleStoreMoreInfo();
    bindDrawerStoreSearchByLocation();
    bindOnSearchXClick();
    bindDrawerViewMoreInfo();
    bindOnSelectStoreClick();
    // TODO remove this later
    bindDrawerDeselectStore();
}

function bindCloseDrawerOnUnderlayClick() {
    $("body").on("click", ".cnc__drawer-underlay", () => {
        closeDrawer();
    });
}

function bindCloseDrawerOnDrawerXClick() {
    $("body").on("click", "#cnc__drawer-close-btn", () => {
        closeDrawer();
    });
}

function showDynamicSearchboxIcon($input, isLoading) {
    if (isLoading) {
        $(".cnc__search-input-wrapper .cnc__close-icon").hide();
        $(".cnc__search-input-wrapper .cnc__search-icon").hide();
        $(".cnc__search-input-wrapper .cnc__ripple-loader").show();
        return;
    }

    if ($input.val()) {
        $(".cnc__search-input-wrapper .cnc__close-icon").show();
        $(".cnc__search-input-wrapper .cnc__search-icon").hide();
        $(".cnc__search-input-wrapper .cnc__ripple-loader").hide();

        $input.parent().find(".close-icon-hldr").show();
        $input.parent().find(".search-icon-hldr").hide();
    } else {
        $(".cnc__search-input-wrapper .cnc__close-icon").hide();
        $(".cnc__search-input-wrapper .cnc__search-icon").show();
        $(".cnc__search-input-wrapper .cnc__ripple-loader").hide();

        $input.parent().find(".close-icon-hldr").hide();
        $input.parent().find(".search-icon-hldr").show();
    }
}

function bindDrawerStoreSearchByInfo() {
    $("body").on("keyup", "#cnc__store-search-input-from-list, #cnc__store-search-input-closest", function (e) {
        let searchInputVal = $(e.target).val();
        let filteredStores = [];
        if (!searchInputVal) {
            renderDrawerStores();
            showDynamicSearchboxIcon($(this));

        } else {
            searchInputVal = searchInputVal.toLowerCase();
            filteredStores = bopis?.allStores.filter(store =>
                store.storeName.toLowerCase().includes(searchInputVal) ||
                store.street.toLowerCase().includes(searchInputVal) ||
                store.city.toLowerCase().includes(searchInputVal) ||
                store.state.toLowerCase().includes(searchInputVal) ||
                store.country.toLowerCase().includes(searchInputVal) ||
                store.zipCode.toLowerCase().includes(searchInputVal)
            );
            renderDrawerStores(filteredStores);
            showDynamicSearchboxIcon($(this));
        }
    });
}

function bindDrawerSetStore() {
    $("body").on("click", ".cnc__set-store-btn", function (e) {
        e.preventDefault();
        if(!isCheckoutPage()){
            const storeId = $(this).attr("store-id");
            setStore(storeId);
        }
    });
}

function bindToggleStoreMoreInfo() {
    $("body").on("click", ".cnc__view-store-details-btn", function () {
        $(this).closest(".store").find(".cnc__store-more-info").slideToggle(200);
    });
}

function bindDrawerStoreSearchByLocation() {

    $("body").on("click", ".cnc__use-location a, .cnc__use-location button", function (e) {
        e.preventDefault();
        getCurrentLocation();

    });

}

export function getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(function (position) {
        var myLat = position.coords.latitude;
        var myLon = position.coords.longitude;
        $('body').attr('mylat', myLat);
        $('body').attr('mylon', myLon);
        setCookie('myLat', myLat, 7);
        setCookie('myLon', myLon, 7);

        setSelectedStoreByDistance(myLat, myLon);

    },
        function (error) {
        });
}

function sortStoresByDistance() {
    bopis?.allStores.sort(function (a, b) {
        return parseFloat(a.distance) - parseFloat(b.distance);
    });
}

function setSelectedStoreByDistance(myLat, myLon) {
    var allDistances = [];
    var allIDs = [];
    var allIDNames = [];
    var nearestStoreID;
    var nearestStoreName;

    bopis?.allStores.forEach(function (el, index) {
        el.distance = distance(myLat, myLon, el.latitude, el.longitude);
        var D = parseInt(el.distance);
        if (allDistances.indexOf(D) < 0) {
            allDistances.push(D);
            allIDNames.push(el.storeName);
            allIDs.push(el.locationId);
        }

    });

    sortStoresByDistance();

    if (allDistances.length > 0) {
        let nearestDistance = allDistances[0];
        for (var i = 0; i < allDistances.length; ++i) {
            if (allDistances[i] < nearestDistance) {
                nearestDistance = allDistances[i];
            }
        }

        for (var i = 0; i < allDistances.length; i++) {
            if (allDistances[i] == nearestDistance) {
                nearestStoreID = allIDs[i];
                nearestStoreName = allIDNames[i];
            }
        }
        if(nearestStoreID.length){
            setStore(nearestStoreID)
        }

    }

    renderDrawerStores();
}

function distance(lat1, lon1, lat2, lon2) {
    var R = 6371; // km (convert this constant to get miles)
    var dLat = (lat2 - lat1) * Math.PI / 180;
    var dLon = (lon2 - lon1) * Math.PI / 180;
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return Math.round(d);
}

function bindOnSearchXClick() {
    $("body").on("click", ".cnc__search-input-wrapper .cnc__close-icon-wrapper", function () {
        let $input = $(this).siblings("input[type='text']");
        $input.val("");

        if ($input.attr("id") === "cnc__store-search-input-closest") {
            handleOnSearchByLocationChange();
        } else if ($input.attr("id") === "cnc__store-search-input-from-list") {
            $input.trigger("keyup");
        }
    });
}

function bindDrawerViewMoreInfo() {
    $("body").on("click", ".cnc__view-more-store-info", (event) => {
        $(event.currentTarget).closest(".store ").find(".cnc__store-more-info").slideDown();
        $(event.currentTarget).addClass("is-open");
        $(event.currentTarget).parent().addClass("is-open");
        $(event.currentTarget).text('Less Details');
    })

    $("body").on("click", ".cnc__view-more-store-info.is-open", (event) => {
        $(event.currentTarget).closest(".store ").find(".cnc__store-more-info").slideUp();
        $(event.currentTarget).removeClass("is-open");
        $(event.currentTarget).parent().removeClass("is-open");
        $(event.currentTarget).text('View Store Details');
    })
}

function bindDrawerDeselectStore() {
    $("body").on("click", "#deselect-store", async () => {
        await setStore("");
    });
}

function handleOnSearchByLocationChange($input = "") {
    if($input == ""){
        $input = $("#cnc__store-search-input-closest");
    }

    let searchInputVal = $input.val();

    if (!searchInputVal) {
        renderDrawerStores();
        showDynamicSearchboxIcon($input);
    } else {
        clearTimeout(timeout);

        timeout = setTimeout(() => {
            // Making a deep copy of the allStores obj
            let filteredStores = JSON.parse(JSON.stringify(bopis?.allStores));
            // 

            showDynamicSearchboxIcon($input);

            renderDrawerStores(filteredStores);
        }, 300);
    }
}

function bindOnSelectStoreClick() {
    $("body").on("click", ".cnc__header-store-selector", () => {
        openDrawer();
    })
    let changeStorePopUp = new Popup();
    $("body").on("click", ".change-selected-store, .no-store-selected", async (e) => {
        if(splitShippingMethod){
            let lineItems = [];
            let lineSKUs = [];
            if(isCheckoutPage()){
                $(e.target).parents('.split-shipping-row').find('ul.productList li.productList-item').each(function (li, el) {
                    lineItems.push({ 'itemId': $(el).attr('line-item'), 'quantity': parseInt($(el).attr('line-qty')) });
                    lineSKUs.push({ 'sku': $(el).attr('line-sku'), 'quantity': parseInt($(el).attr('line-qty')) });
                })
                console.log(lineItems, lineSKUs);
            }
            let targetElement = $(e.target).parents('.change-selected-store').attr('data-element-id');
            changeStorePopUp.prepare({
                headerText: "Select Pickup Store",
                contentHTML: `
                <div class="cnc__pref-checkout-select-store">
                    <div class="cnc__pref-checkout-store-search-bar">
                        <div class="cnc__pref-checkout-search-by-location-group">
                            <div class="cnc__pref-checkout-search-input-wrapper">
                                ${ICONS.search}
                                <input placeholder="Enter postcode or suburb" type="text" id="cnc__pref-checkout-store-search-input-closest" data-targetElement="${targetElement}">
                                <div class="cnc__pref-checkout-ripple-loader lds-ripple" style="display: none;">
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="stores">
                    ${isCheckoutPage() ? renderStoreListCheckout(lineItems,lineSKUs,bopis?.allStores,$(e.target).parents('.split-shipping-row')):renderStoreList('', '', bopis?.allStores, targetElement)}
                    </ul>
                </div>
                `,
                button1Text: "OKAY",
                onButton1Click: () => {
                    changeStorePopUp.close();
                },
                customClass: 'find-store'
            });
    
            changeStorePopUp.open();
        }else{
            openDrawer();
        }
    })

    $('body').on('keyup', '#cnc__pref-checkout-store-search-input-closest', async function () {
        let searchInputVal = $(this).val().toLowerCase();
        let targetElement = $(this).attr("data-targetElement");
        let thisLineID = $(this).attr("data-thisLineID") || "";
        let thisSKU = $(this).attr("data-sku") || "";
        let newStoreResults = renderStoreList(thisLineID, thisSKU, bopis?.allStores.filter(store =>
            store.storeName.toLowerCase().includes(searchInputVal) ||
            store.city.toLowerCase().includes(searchInputVal) ||
            store.state.toLowerCase().includes(searchInputVal) ||
            store.country.toLowerCase().includes(searchInputVal) ||
            store.zipCode.toLowerCase().includes(searchInputVal)
        ), targetElement);
        $(this).parents('.cnc__pref-checkout-select-store').find('ul.stores').html(newStoreResults);
    });

    $("body").on("click", ".popup-notice .close-icon-hldr svg", (e) => {
        $(e.target).parents('.cnc__search-input-wrapper').find('input').val("");
        $(e.target).parents('.cnc__search-input-wrapper').find('.search-icon-hldr').show();
        $(e.target).parents('.cnc__search-input-wrapper').find('.search-icon-hldr svg').show();
        $(e.target).parents('.cnc__search-input-wrapper').find('.close-icon-hldr').hide();
        renderDrawerStores();
    });
    
}
const { applicationId, isLiveSite } = require("./globalConstants");

export const initHeaders = () => {
    appendHeaderElements();
}

const appendHeaderElements = () => {
    let hashForCSS = isLiveSite ? 'd1zxl9q5chetsu':'dutuaypx542vp';

    $('head').append('<link rel="stylesheet" href="https://'+hashForCSS+'.cloudfront.net/' + applicationId + '/assets/ui.css">');

    $('head').append('<script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDGqc1GT_174MO8gcTgebnnXKvCts3MP1I&libraries=places&channel=GMPSB_addressselection_v1_cABC"</script>');
    
    if (isDatePickerEnabled == true) {
        $('head').append('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/datepicker/1.0.10/datepicker.min.css" integrity="sha512-YdYyWQf8AS4WSB0WWdc3FbQ3Ypdm0QCWD2k4hgfqbQbRCJBEgX0iAegkl2S1Evma5ImaVXLBeUkIlP6hQ1eYKQ==" crossorigin="anonymous" />');
        $('head').append('<script src="https://cdnjs.cloudflare.com/ajax/libs/datepicker/1.0.10/datepicker.min.js" integrity="sha512-RCgrAvvoLpP7KVgTkTctrUdv7C6t7Un3p1iaoPr1++3pybCyCsCZZN7QEHMZTcJTmcJ7jzexTO+eFpHk4OCFAg==" crossorigin="anonymous"></script>');
    }
}


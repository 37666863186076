const { BIGCOMMERCE_PLACEHOLDERS, GENERATED_PLACEHOLDERS, staticCustomizations } = require("../common/globalConstants");
const { getItemAvailability, render, removeComma, getItemStock } = require("../common/utils");
const { ICONS } = require("../common/icons");

export const renderPDPClickAndCollect = () => {
    let HtmlToRender;
    let currentStockInSelectedStore = 0;

    if (!bopis?.perSKU) {
        HtmlToRender = `
        <div class="placeholder_content">
            <div class="placeholder_item"></div>
        </div>
        `;
    } else {
        // $('.placeholder_content').remove();

        if (PDPMetaData.productHasVariants && !PDPMetaData.areAllVariantsOptionsPicked) {
            HtmlToRender = `<div id="cnc__product-delivery-type" class="storeselectwidget"><p><b>${staticCustomizations.PDPTitle}</b></p><p>${staticCustomizations.NoSelectedVariants}</p>`;
        } else {
            let { availableForShipping, availableForPickup, availableInWarehouse } = getItemAvailability(PDPMetaData.selectedProduct.SKU, PDPMetaData.selectedProduct.quantity, selectedStoreId, PDPMetaData.selectedProduct.price);

            let { stockCount, stockCountInSelectedStore, stockCountInWarehouse, stockForPickup } = getItemStock(PDPMetaData.selectedProduct.SKU, selectedStoreId);
            currentStockInSelectedStore = stockCountInSelectedStore;


            HtmlToRender =
                `
                <p class="hide"><b>${staticCustomizations.PDPTitle}</b></p>
                ${PDPMetaData.selectedProduct.pickupOnly == true ? `<p class="cnc__shipping-availability cnc__color-unavailable">${ICONS.unavailable} <span class="availability-label">Unavailable for Home Delivery</span></p>`:availableForShipping && stockCountInWarehouse >= parseInt(PDPMetaData.selectedProduct.quantity)
                    ? `<p class="cnc__shipping-availability cnc__color-available">${ICONS.available} <span class="availability-label">${staticCustomizations.OnlineAvailabilityLabel}</span> <span class="stock-availability in-stock">Current Stock: ${stockCountInWarehouse}</span></p>`
                    : `<p class="cnc__shipping-availability cnc__color-unavailable">${ICONS.unavailable} <span class="availability-label">${staticCustomizations.OnlineNotAvailabilityLabel}</span></p>`}
                
                ${!selectedStore?.locationId ?
                    `<p>${ PDPMetaData.selectedProduct.pickupOnly == true ? 'This Product is available in stores and for Click and Collect':staticCustomizations.PDPNoStoreSelected}</p>` :
                    availableForPickup
                        ? `<p class="cnc__shipping-availability cnc__color-available in-store">${ICONS.available} <span class="availability-label">${staticCustomizations.PISAvailabilityLabel} ${selectedStore.storeName} </span><span class="stock-availability in-stock">Current Stock: ${currentStockInSelectedStore}</span></p>`
                        : `<p class="cnc__shipping-availability cnc__color-unavailable">${ICONS.unavailable} <span class="availability-label">${staticCustomizations.PISNotAvailabilityLabel} ${selectedStore.storeName}</span><a class="cnc__select-store">Change Store</a></p>`}
                    
                ${!selectedStore?.locationId
                    ? ``
                    : `<div class="hide cnc-selected-store-info">
                        <h6>${selectedStore.storeName}</h6>
                        <p>${removeComma(selectedStore.street)}</p>
                        <p>${selectedStore.city}, ${selectedStore.zipCode}</p>
                        </div>`

                } 
                
                <a class="cnc__select-store">
                    ${selectedStore?.locationId ? "Change Store" : "Select Store"}
                </a>
                `;

            if(
                (availableForPickup == false &&  PDPMetaData.selectedProduct.pickupOnly == true) || 
                (availableForPickup == false && (stockCount > 0 && stockCount >= parseInt(PDPMetaData.selectedProduct.quantity)) && !selectedStore?.locationId && availableForShipping == false) ||
                (availableForPickup == false && (stockCount > 0 && stockCount >= parseInt(PDPMetaData.selectedProduct.quantity)) && (availableForShipping && stockCountInWarehouse >= parseInt(PDPMetaData.selectedProduct.quantity)) == false && (!selectedStore?.locationId || stockForPickup >= parseInt(PDPMetaData.selectedProduct.quantity))) ||
                (availableForPickup == true && ((stockCount > 0 && stockCount >= parseInt(PDPMetaData.selectedProduct.quantity)) == false || availableForShipping == false))
            )
            {
                $('#add-to-cart-wrapper .form-action #form-action-addToCart').hide();
                $('#add-to-cart-wrapper .form-action #form-action-selectStore').remove();
                $('#add-to-cart-wrapper .form-action').append(`<input id="form-action-selectStore" product-sku="${PDPMetaData.selectedProduct.SKU}" class="button button--primary" value="Select Store" type="button">`);
            }else{
                $('#add-to-cart-wrapper .form-action #form-action-addToCart').show();
                $('#add-to-cart-wrapper .form-action #form-action-selectStore').hide();
            }

        }

        if(isSDDMethodEnabled == true && $(`${GENERATED_PLACEHOLDERS.productPageSDDDeliveryType}`).length == 0){
            $(`
            <div id="cnc__product-sdd-delivery-type" class="delivery-section-selector">
                <div class="section-header">
                    <label for="sameDayDelivery">
                        <span><b>SAME DAY DELIVERY</b></span>
                        <span class="poweredby-uber">POWERED BY ${ICONS.uber}</span>
                    </label>
                </div>
                <div class="section-content">
                    <p class="variantAvailability" style="display:none;">Select a variant to view product availability </p>
                                        
                    <input type="text" id="sameDayDeliveryInput" placeholder="Enter your address">
                    <p class="sdd-eligible">Enter your address to see if you are eligible for Same Day Delivery</p>
                    <div class="sameDayDeliveryResult" style="display: none;">
                        <div class="custom-loader" style="display: none;"></div>
                        <span class="availability-text"><strong class="availability"></strong> for Same Day Delivery for this address</span>
                    </div>
                </div>
            </div>
            `).insertAfter($(GENERATED_PLACEHOLDERS.productPageDeliveryType));
        }

    }

    if (!$(GENERATED_PLACEHOLDERS.productPageDeliveryType).length) {
        $(`<div id='${GENERATED_PLACEHOLDERS.productPageDeliveryType.substring(1)}' ></div> `).insertAfter($(BIGCOMMERCE_PLACEHOLDERS.productATC));
    }

    render(GENERATED_PLACEHOLDERS.productPageDeliveryType, HtmlToRender);
    if (currentStockInSelectedStore == 0 || currentStockInSelectedStore == undefined || currentStockInSelectedStore < parseInt(PDPMetaData.selectedProduct.quantity)) {
        showAlternativeStores();
    }

    renderPDPCCStore();
}

export function showAlternativeStores() {
    if (bopis?.perSKU?.length == 0 || bopis?.perSKU == undefined) return;

    let otherStores = bopis?.perSKU.filter(stock => stock.quantity >= parseInt(PDPMetaData.selectedProduct.quantity) && stock.productAllowPickup && stock.locationAllowPickup).map(store => {
        return `<li><a class="cnc__set-store-btn" href="#" store-id="${store.storeId}">${store.storeName}</a></li>`
    }).join("");


    $('.alternative-stores-hldr').remove();
    if (otherStores.length > 0) $(`<div class="alternative-stores-hldr"><p>Currently in Stock at:</p><ul class="alternative-stores">${otherStores}</ul></div>`).insertAfter($('.cnc-selected-store-info'));
}

export function renderPDPCCStore() {
    if(!bopis) return;
    let HtmlToRender = `
        <div class="store-availability-table">
            <div class="store-availability-header">
                <span>Store</span>
                <span>Phone</span>
                <span>Availability</span>
            </div>
            ${bopis.allStores?.filter(store=>store.pickupAllowed)?.map(sku => {
                let quantityPerStore = bopis.perSKU.find(store=>store.storeId == sku.locationId && store.sku == PDPMetaData.selectedProduct.SKU);
                return `
                    <div class="store-availability-hldr">
                        <span>${sku.storeName}</span>
                        <span><a href="tel:${sku.phone}">${sku.phone}</a></span>
                        <span>${quantityPerStore ? quantityPerStore.quantity : 0}</span>
                    </div>
                `
            }).join("")}
        </div>
    `;



    if (!$(GENERATED_PLACEHOLDERS.productPageCNCStores).length) {
        $(BIGCOMMERCE_PLACEHOLDERS.productPageCNCStores).append($(`<div id='${GENERATED_PLACEHOLDERS.productPageCNCStores.substring(1)}' ></div> `));
    }

    render(GENERATED_PLACEHOLDERS.productPageCNCStores, HtmlToRender);
}
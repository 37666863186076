
export const setPLPMetaData = () => {
    window.PLPMetaData?.products?.map((pp, ppIndex) => {
        if (pp?.variants?.length) {
            pp?.variants?.map((pv, pvIndex) => {
                if (window?.bopis?.perSKU?.find(ps => ps.sku === pv.sku && ps.quantity)) {
                    window.PLPMetaData.products[ppIndex].variants[pvIndex].hasStock = true;
                    window.PLPMetaData.products[ppIndex].isOutOfStock = false;
                }
            });

        } else {
            if (window?.bopis?.perSKU?.find(ps => ps.sku == pp.sku && ps.quantity)) {
                window.PLPMetaData.products[ppIndex].isOutOfStock = false;
            }
        }
    })
}
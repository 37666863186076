import { renderDrawerStores, renderHeader } from "../globalPage/globalPageRenderer";
import { setStore } from "../globalPage/globalpageData";
import { storefrontServices } from "../services/bigCommerceServices";
import { getBOPISData, checkSDDZone } from "../services/randemRetailServices";
import { ICONS } from "../common/icons";
const { Popup } = require("../common/popUp");
const { renderPDPClickAndCollect } = require("./productDetailsPageRenderer");
const { areAllVariantsOptionsPicked, setPDPMetaData } = require("./productDetailsPageData");
const { getItemAvailability, isPDPPage } = require("../common/utils");

export function bindPDPEvents() {
    bindOnDOMSKUChange();
    bindPDPOnQuantityChange();
    bindAddtoCart();
    bindValidateRequiredVariant();
    bindSDDSection();
}

function bindSDDSection(){
    if(!isSDDMethodEnabled) return;
    var SDDtypingTimer;
    var SDDAddress;
    //TODO initialize google address finder
    $("body").on("keydown", "#sameDayDeliveryInput", function(){
        let $thisElement = $(this);
        clearTimeout(SDDtypingTimer);
        viewSDDLoader();
        SDDtypingTimer = setTimeout(function () {
            if(SDDAddress !== $thisElement.val() && $thisElement.val().length > 0){
                $('.sdd-eligible').removeClass('sdd-available').removeClass('sdd-unavailable');
                SDDAddress = $thisElement.val();
                checkSDDZone($thisElement.val()).then(async () => {
                    hideSDDLoader();
                    if(bopis.SDD.length > 0){
                        $('.sdd-eligible').addClass('sdd-available').html(`${ICONS.available} Available for Same Day Delivery for this address`);
                    }else{
                        $('.sdd-eligible').addClass('sdd-unavailable').html(`${ICONS.unavailable} Not available for Same Day Delivery for this address`);
                    }
                });
            }else{
                hideSDDLoader();
            }
        }, 2000);
    })
}

function viewSDDLoader(){
    hideSDDLoader();
    $('.sdd-eligible').hide();
    $(`<div class="placeholder_content sdd__item-availability-wrapper">
        <div class="placeholder_item"></div>
    </div>`).insertAfter('.sdd-eligible');
}

function hideSDDLoader(){
    $('.sdd__item-availability-wrapper').remove();
    $('.sdd-eligible').show();
}


function bindOnDOMSKUChange() {
    $("[data-product-option-change]").find("select, input").on("change", async () => {
        bopis = null;
        var productDetails = await storefrontServices.getProductSKU($('[name=product_id]').val(), $("[data-cart-item-add]").serialize());
        var updatedProductSKU;
        if (areAllVariantsOptionsPicked() == true) {
            let baseAttr = [];
            $('[data-product-option-change] [type="radio"]:checked').each(function (ind, elem) {
                let attrID = $(elem).attr('name').replace('attribute[', '').replace(']', '');
                let attrVal = $(elem).val();
                if (ind == 0) {
                    baseAttr = PDPMetaData.productVariants.filter(variant => variant.node.productOptions.find(productOption => productOption.entityId == attrID && productOption.values.find(value => value.entityId == attrVal)));
                } else {
                    baseAttr = baseAttr.filter(variant => variant.node.productOptions.find(productOption => productOption.entityId == attrID && productOption.values.find(value => value.entityId == attrVal)))
                }
            })
            updatedProductSKU = baseAttr[0].node.sku;
        }
        if (productDetails.data.sku) {
            $(".product-sku").html(productDetails.data.sku)
        }
    })

    const targetNode = document.querySelector("[data-product-sku]");
    const config = { attributes: true, childList: true, subtree: true };
    const observer = new MutationObserver((mutationList, observer) => {
        checkPDPVariantAvailabilityHandler();
    });

    observer.observe(targetNode, config);
}

export function checkPDPVariantAvailabilityHandler() {
    bopis = null;
    renderPDPClickAndCollect();
    setPDPMetaData();

    getBOPISData().then(async () => {
        handlePDPAddToCart();
        renderPDPClickAndCollect();
        renderDrawerStores();
    });
}

function bindPDPOnQuantityChange() {
    $("button[data-action]").on("click", () => {
        bopis = null;
        renderPDPClickAndCollect();

        setTimeout(() => {
            checkPDPVariantAvailabilityHandler();
        }, 10)
    })

    $(".form-input--incrementTotal").on("change", () => {
        bopis = null;
        renderPDPClickAndCollect();

        setTimeout(() => {
            checkPDPVariantAvailabilityHandler();
        }, 10)
    })
}

function bindAddtoCart() {
    $("body").on("click", "#form-action-addToCart", () => {
        if (PDPMetaData.productHasVariants) {
        }
    })

    $("body").on("click", "#form-action-selectStore, div#cnc__product-delivery-type .cnc__shipping-availability .cnc__select-store, #cnc__product-delivery-type > .cnc__select-store", () => {
        let selectStorePDP = new Popup();
        selectStorePDP.prepare({
            headerText: "",
            contentHTML: `
            <div class="cnc__close-icon-wrapper">
                ${ICONS.close}
            </div>
            <div class="cnc__search-by-location-group">
                <div class="search-title">
                    <h5>Find the nearest store for you</h5>
                </div>

                <div class="cnc__search-input-wrapper">
                    <input placeholder="Enter zipcode or state" type="text" id="cnc__store-search-input-closest"/>
                    <div class="search-icon-hldr">
                        ${ICONS.search}
                    </div>
                    <div class="close-icon-hldr" style="display:none;">
                        ${ICONS.close}
                    </div>
                </div>
                <div class="cnc__use-location">
                    <button id="cnc__use-current-location">${ICONS.pinOutlinePink} Use my current location</button>
                </div>
            </div>

            <div class="cnc__select-store-list-result-hldr">
                <span class="store-list-title">Stores</span>
                <ul class="cnc__store-list">
                    
                </ul>
            </div>

            `,
            customClass: 'select-store'
        });

        selectStorePDP.open();
        renderDrawerStores();
    });

    $('body').on('click', '.notice-popup.select-store .popup-notice .popup-content > .cnc__close-icon-wrapper', function(){
        $(this).parents('.notice-popup').fadeOut(250);
        $(this).parents('.notice-popup').remove();
        $("body").css("overflow", "auto");
        $("body").removeClass("overflow-hidden");
    });

    $('body').on('click', '.notice-popup.select-store .popup-notice .popup-content .cnc__set-store-btn', function(){
        bopis = null;
        renderHeader();
        renderPDPClickAndCollect();
        $(this).parents('.notice-popup').fadeOut(250);
        $("body").css("overflow", "auto");
        $("body").removeClass("overflow-hidden");
        setTimeout(()=>{
            $(this).parents('.notice-popup').remove();
        }, 300)
    });

}

function bindValidateRequiredVariant() {
    $("#form-action-addToCart").on("click", () => {
        if (!$(".option-select").val()?.length) {
            $(".custom-dropdown-select").addClass("dropdown-required-error");
        } else {
            $(".custom-dropdown-select").removeClass("dropdown-required-error");
        }
    })

    $("body").on("click", ".custom-dropdown-options li", () => {
        $(".custom-dropdown-select").removeClass("dropdown-required-error");
    })
};


export function handlePDPAddToCart() {
    if (!isPDPPage()) return;

    const { availableForShipping, availableForPickup, availableInWarehouse } = getItemAvailability(PDPMetaData.selectedProduct.SKU, PDPMetaData.selectedProduct.quantity);
    if (!(availableInWarehouse || (selectedStoreId && availableForPickup))) {
        disablePDPAddToCart();
    } else {
        enablePDPAddToCart();
    }
}

export function handlePDPDataForSchema(){
    if (!isPDPPage()) return;
    const { availableForShipping, availableForPickup, availableInWarehouse } = getItemAvailability(PDPMetaData.selectedProduct.SKU, PDPMetaData.selectedProduct.quantity);
    if (!(availableInWarehouse || (selectedStoreId && availableForPickup))) {
        window.RRStock = 'Out of Stock';
        $('span.badge.badge--stock').addClass('badge--out-stock');
        $('span.badge.badge--stock').html('Out of Stock');
    }else{
        window.RRStock = 'InStock';
    }
}

function disablePDPAddToCart() {
    $("#form-action-addToCart").addClass("disabled");
}

function enablePDPAddToCart() {
    $("#form-action-addToCart").removeClass("disabled");
}

export function PDPsetVariants() {
    if (!isPDPPage() || PDPMetaData.productHasVariants == false) return;
    $('.form-option').attr('disabled', 'disabled').addClass('unavailable');
    $('.form-radio').attr('disabled', 'disabled').addClass('unavailable');

    PDPMetaData.productVariants.forEach(variant => {
        variant.node.productOptions.forEach(option => {
            $('[data-product-attribute-value=' + option.values[0].entityId + ']').addClass(variant.node.sku);
            $(':input[value="' + option.values[0].entityId + '"]').addClass(variant.node.sku);
        });
    });

    setTimeout(() => {
        crossOutVariants();
    }, 3000);
}

function crossOutVariants() {
    setInterval(() => {
        $('.form-option').attr('disabled', 'disabled').addClass('unavailable');
        $('.form-radio').attr('disabled', 'disabled').addClass('unavailable');
        bopis?.perSKU.forEach(variant => {
            if (variant.quantity > 0) {
                $("." + variant.sku + "").removeAttr('disabled').removeClass('unavailable');
            }
        });
    }, 300);
}
import { isBrandPage, isCategoryPage, isSearchPage } from "../common/utils";
import { getBOPISData } from "../services/randemRetailServices";
import { setPLPMetaData } from "./productListingPageData";
import { renderProductAvailability, renderProductAvailabilityFilters, renderSoldOutItems, renderSoldOutItemsLoader } from "./productListingPageRenderer";


export const bindPLPListeners = async () => {
    addLoaderInATC();
    let productCardLength = $(".productGrid li").length;
    let changeDiv = false;
    navigation.addEventListener("navigate", e => {
        productCardLength = 0;
        if(productCardLength !== $(".productGrid li").length){
            
            productCardLength = $(".productGrid li").length;
            window.productSKUS = [];
            window.productIDS = [];
            let addLoader = setInterval(()=>{
                $(`[data-test] [data-sku]`).each(function(idx, elem){
                    $(elem).addClass('is-loading');
                });

            })
            // location.reload();
            // return
            setTimeout(async ()=>{
                clearInterval(addLoader);
                window.productSKUS = [];
                window.productIDS = [];
                await getBOPISData();
                console.log(bopis, 'bopis')
                await renderProductAvailability();
                await renderProductAvailabilityFilters();
                if(changeDiv == true){
                    onChangeDiv();
                }
                changeDiv = false;
            }, 5000)
        }
    });
    
    if(changeDiv == false){
        onChangeDiv();
    }
}

async function onChangeDiv(){
    let productCardLength = $(".productGrid li").length;
    if(productCardLength == 0) {return;}
    // Select the node that will be observed for mutations
    var targetNode = document.querySelector('.productGrid');

    // Options for the observer (which mutations to observe)
    var config = { attributes: true, childList: true };

    // Callback function to execute when mutations are observed
    let count = 0;
    var callback = function(mutationsList) {
        for(var mutation of mutationsList) {
            if (mutation.type == 'childList') {
                if(productCardLength != $(".productGrid li").length){
                    productCardLength = $(".productGrid li").length;
                    resetPLPLoader();
                }

            }
            else if (mutation.type == 'attributes') {
                if(productCardLength != $(".productGrid li").length){
                    productCardLength = $(".productGrid li").length;
                    resetPLPLoader();
                }
            }
        }
    };

    var observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
}

const resetPLPLoader = async () => {
    addLoaderInATC();

    await getBOPISData();
    await renderProductAvailability();
    await renderProductAvailabilityFilters();
}

export const addLoaderInATC = () =>{
    if (!isCategoryPage() && !isBrandPage() && !isSearchPage()) return;
    if($(`[data-test] [data-sku]`).length == 0) return;
    $(`[data-test] [data-sku]`).each(function(idx, elem){
        if(window.productSKUS.indexOf($(elem).attr('data-sku')) < 0){
            $(elem).addClass('is-loading');
        }
    });
}